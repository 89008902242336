import React, {useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";
import {Modal} from "react-bootstrap";
import ReservationService from "../../service/ReservationService";
import useToastContext from "../nativeComponents/useToastContext";

const EditPriceModal = (props) => {

    const [price, setPrice] = useState('')

    const addToast = useToastContext();


    useEffect(() => {
        // setPrice(props.data.reservationType === "CALL_RESERVATION" ? props.data.amountToPay : props.data.amountToPay / 2)
        setPrice(props.data.alreadyPaid)
    }, [props.data.alreadyPaid])

    const editPriceHandler = () => {

        let reservationId = props.data.id;

        let newPrice = props.data.reservationType === "CALL_RESERVATION" ? price : price * 2

        ReservationService.updatePayment(reservationId, price).then(r => {
            console.log(r)
            addToast({text: "Pomyślnie zmieniono cenę!", variant: true})
            props.reload()
            props.onHide()

        }).catch(e => {
            console.error(e)
            addToast({text: "Operacja zakończona niepowodzeniem!", variant: false})
            props.onHide()
        })
    }

    const changeTypingValueHandler = (e) => {
        const value = e.target.value;
        setPrice(value)
    }


    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Edycja zadatku dla ID {props.data.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/*<div>Wpłacono: <strong>{props.data.reservationType === "CALL_RESERVATION" ? props.data.amountToPay : props.data.amountToPay / 2} zł</strong></div>*/}
                    <div>Wpłacono: <strong>{props.data.alreadyPaid} zł</strong></div>
                    <br/>

                    <div className="form-group col-md-6">
                        <label htmlFor="number">Nowa cena:</label>
                        <input type="number"
                               id="amountToPay"
                               placeholder="Wprowadź cenę"
                               className="form-control"
                               value={price}
                               name="amountToPay"
                               onChange={changeTypingValueHandler}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Anuluj</Button>
                    <Button onClick={editPriceHandler}>Zatwierdź</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditPriceModal;