import React, {useEffect, useState} from 'react';
import {Badge, Table} from "react-bootstrap";
import ReservationService from "../../../service/ReservationService";
import {NavLink} from "react-router-dom";

const LatestReservationsWidget = ({date, refresh}) => {

    const [today, setToday] = useState([]);

    useEffect(() => {
        ReservationService.getTodayReservations({providedDate: date}).then(response => {
            let data = response.data.reservations;

            setToday(data.reverse());
            // console.log(response.data.reservations)
        }).catch(e => console.log(e))
    }, [date, refresh]);


    return (
        <div className="latest-reservations">
            <Table responsive>
                <thead>
                <tr>
                    <th scope="col">Imię i nazwisko</th>
                    <th scope="col">Ilość osób</th>
                    <th scope="col">Przyjazd</th>
                    <th scope="col">Wyjazd</th>
                    <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                {today.length > 0 ?
                    today.slice(0, 4).map((reservation, key) => (

                        <tr key={key}>
                            <td className="d-flex align-items-center text-nowrap">
                                <div className="list-avatar me-3">{reservation.firstLetterOfSurname}</div>
                                {reservation.firstName} {reservation.lastName}
                            </td>
                            <td>{reservation.howManyPeople}</td>
                            <td className="text-nowrap">{reservation.reservationStart}</td>
                            <td className="text-nowrap">{reservation.reservationEnd}</td>
                            <td>
                                {
                                    reservation.reservationType === "INTERNET_RESERVATION" ?
                                        <Badge bg="success">AKTYWNA, ZWYKŁA </Badge> :
                                        <Badge bg="success">AKTYWNA, INNA</Badge>
                                }
                            </td>
                        </tr>

                    ))
                    : <p className="my-4">Brak rezerwacji</p>
                }
                </tbody>
            </Table>
            {
                today.length > 4 ?
                    <NavLink
                        onClick={() => localStorage.setItem("MSSearchParams", JSON.stringify({
                            addingReservationDate: date
                        }))}
                        to={"/admin/rezerwacje"}>Zobacz więcej...</NavLink> : ""
            }
        </div>
    );
}

export default LatestReservationsWidget;