import React from 'react';
import CookieConsent from "react-cookie-consent";

const CookiesModal = () => {
    return (
        <>
            <CookieConsent
                location="none"
                buttonText="Akceptuj"
                cookieName="MarkoweSzczawinyCookie"
                style={{background: "#000", borderRadius: "20px", zIndex: "1051", maxWidth: "700px", left: "unset"}}
                buttonClasses="btn btn-lg btn-grad"
                buttonWrapperClasses="d-grid m-3 mt-4"

                containerClasses="d-block p-3"

                expires={150}
                overlay
                overlayClasses="d-flex justify-content-center align-items-center"
                overlayStyle={{zIndex: "9999"}}
                disableButtonStyles={true}
            >
                <h1 className="h5 fw-bold">Twoja prywatność</h1>
                <p>
                    W ramach naszej witryny stosujemy pliki cookies. Jeśli korzystacie Państwo z nich bez zmiany
                    ustawień
                    przeglądarki w Waszym urządzeniu, przyjmujemy, że wyrażacie zgodę na użycie tych plików. W każdym
                    momencie jednak możecie Państwo zmienić ustawienia przeglądarki decydujące o tym użyciu.
                    <span className="ms-2">
                        <a target="_blank" href="/media/Polityka-prywatnosci.pdf">
                            Polityka prywatności
                        </a>
                    </span>
                </p>
            </CookieConsent>
        </>
    );
};

export default CookiesModal;