import React, {useEffect, useState} from 'react';
import DayPicker, {DateUtils} from "react-day-picker";
import {Popover, OverlayTrigger, Tooltip} from "react-bootstrap";
import 'react-day-picker/lib/style.css';
import './datepicker.scss'

import {formatDate} from 'react-day-picker/moment';
import MomentLocaleUtils from "react-day-picker/moment";

import 'moment/locale/pl';
import moment from "moment";
import Button from "react-bootstrap/Button";
import {HiArrowSmLeft, HiArrowSmRight} from "react-icons/all";
import AuthService from "../../service/AuthService";
import {Trans, useTranslation} from "react-i18next";


const DatePickerHooks = (props) => {

    const { t } = useTranslation();

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        setUser(AuthService.getCurrentUser())

        let reservationString = sessionStorage.getItem("reservation");
        let reservation = JSON.parse(reservationString);

        try {
            setFrom(new Date(reservation.reservationDayStart))
            setTo(new Date(reservation.reservationDayEnd))
        } catch (error) {

        }
    }, [])

    // SELECTED DAYS RANGE
    const modifiers = {start: from, end: to};
    // SELECTED FIRST LAST
    const selectedDays = [from, {from, to: to}];
    // DISABLED DAYS
    const today = new Date().getHours();
    const disabledDays = [
        today >= 15 && !user ? new Date() : null,
        {
            before: new Date(),
        },
        // {
        //     before: new Date(2023, 0, 5),
        //     after: new Date(2022, 11, 28)
        // },
        // !user && new Date(2023, 0, 27),
        //!user && new Date(2024, 0, 27),
        ...generateDisabledDates(2023, 100),

    ];

    function generateDisabledDates(startYear, numYears) {
        const disabledDates = [];
        for (let i = 0; i < numYears; i++) {
            const year = startYear + i;
            const dateToDisable = moment({ year, month: 11, day: 31 }); // December is month 11
            disabledDates.push(dateToDisable.toDate());
        }
        if (!user) {
            return disabledDates;
        } else {
            return []; // Return an empty array when user is truthy
        }
    }

    function renderDay(day) {
        const date = day.getDate();
        const now = new Date();

        const info = {
            [moment(now).format('YYYY-MM-DD')]: [t('DatePickerHooks_3')],
            [moment('2022-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2023-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2024-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2025-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2026-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2027-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2028-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2029-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2030-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2031-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2032-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            [moment('2033-12-31').format('YYYY-MM-DD')]: ['Prosimy o bezpośredni kontakt ze schroniskiem w celu dokonania rezerwacji w tym terminie'],
            //[moment('2024-01-27').format('YYYY-MM-DD')]: ['Blokada rezerwacji'],
        };


        return (
            <div className="position-relative">
                <div>{date}</div>
                {info[moment(day).format('YYYY-MM-DD')] &&
                    info[moment(day).format('YYYY-MM-DD')].map((name, i) => (
                        <OverlayTrigger
                            key={i}
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-calendar-${i}`}>
                                    {name}
                                </Tooltip>
                            }
                        >
                            <div className="position-absolute top-0 w-100 h-100"/>
                        </OverlayTrigger>
                    ))}
            </div>
        );
    }


    const isSelectingFirstDay = (from, to, day) => {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }


    const handleDayClick = (day, {disabled}) => {

        // HANDLE RESET DATES
        if ((from && to && day >= from && day <= to) || day - from === 0 || disabled) {
            handleResetClick();
            return;
        }

        if (moment('01-27-2023', 'MM-DD-YYYY').isBetween(day, to)) {
            handleResetClick();
            return;
        }


        if (isSelectingFirstDay(from, to, day)) {
            setFrom(day)
            setTo(null)
            props.onDayStartChange(moment(day).format("YYYY-MM-DD"))
            props.onDayEndChange(null)
        } else {
            setTo(day)
            props.onDayEndChange(moment(day).format("YYYY-MM-DD"))
            props.onBlur(moment(day).diff(moment(from), 'days'))
        }


    }

    // CHANGE ON MOUSE ENTER
    // const handleDayMouseEnter = (day) => {
    //     if (!isSelectingFirstDay(from, to, day)) {
    //         props.onDayEndChange(moment(day).format("YYYY-MM-DD"))
    //     }
    // }


    const handleResetClick = () => {
        console.log("reset")
        props.onDayStartChange(null)
        props.onDayEndChange(null)
        setFrom(null)
        setTo(null)
    }


    function Navbar({
                        onPreviousClick,
                        onNextClick,
                    }) {
        const styleLeft = {
            float: 'left',
        };
        const styleRight = {
            float: 'right',
        };
        return (
            <div style={{left: "0", right: "0"}}
                 className="position-fixed d-flex justify-content-between w-100 pt-2 px-4">
                <Button variant="counter" style={styleLeft} onClick={() => onPreviousClick()}>
                    <HiArrowSmLeft/>
                </Button>
                <Button variant="counter" style={styleRight} onClick={() => onNextClick()}>
                    <HiArrowSmRight/>
                </Button>
            </div>
        );
    }

    const popover = (
        <Popover className="mw-100 shadow-lg border-0 box-rounded px-4 py-3" id="popover-basic">
            <div className="InputFromTo ">
                <DayPicker
                    navbarElement={<Navbar/>}
                    localeUtils={MomentLocaleUtils}
                    locale={t('lang')}
                    className="Range"
                    numberOfMonths={2}

                    selectedDays={selectedDays}
                    disabledDays={disabledDays}
                    modifiers={modifiers}
                    onDayClick={handleDayClick}

                    renderDay={renderDay}
                />
                <div
                    className="text-center py-3 mt-2 border-top calendar-footer d-flex justify-content-start align-items-center mx-4">
                    <span>
                    {!from && !to && t('DatePickerHooks_1')}
                        {from && !to && t('DatePickerHooks_2')}
                        {from && to && `${formatDate(from, 'll', t('lang'))} - ${formatDate(to, 'll', t('lang'))} `}
                        {from && to && <strong
                            className="ms-1 text-nowrap">({moment(to).diff(moment(from), 'days')} {moment(to).diff(moment(from), 'days') === 1 ? t('noc') : moment(to).diff(moment(from), 'days') < 5 ? t('nocy') : t('noce')})</strong>}
                    </span>
                </div>
            </div>
        </Popover>
    );

    const Mobile = (
        <DayPicker
            navbarElement={<Navbar/>}
            localeUtils={MomentLocaleUtils}
            locale={t('lang')}
            className="Range"
            numberOfMonths={5}

            selectedDays={selectedDays}
            disabledDays={disabledDays}
            modifiers={modifiers}
            onDayClick={handleDayClick}

            renderDay={renderDay}
        />
    )

    // const inputInfo = () => {
    //     if (!from && !to) {
    //         return 'Zameldowanie - Wymeldowanie'
    //     } else if (from && !to) {
    //         return `${formatDate(from, 'LL', 'pl')} - Wymeldowanie`
    //     } else if (from && to) {
    //         return `${formatDate(from, 'LL', 'pl')} - ${formatDate(to, 'LL', 'pl')}`
    //     }
    // }

    return (
        <div className="date-picker h-100">
            {props.variant !== 2 ?
                <OverlayTrigger transition={false} rootClose trigger="click" placement="bottom" overlay={popover}>
                    {props.comp}
                </OverlayTrigger> : null
            }
            {
                props.variant === 2 ?
                    <div>{Mobile}</div> : null
            }
        </div>
    );
};

export default DatePickerHooks;
