import React, {useState} from 'react';
import "./admin-panel.scss"
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {SidebarDataAdmin} from "./SidebarDataAdmin"
import {Sling as Hamburger} from 'hamburger-react'
import {NavLink} from "react-router-dom";

const SidebarAdmin = () => {

    const [isOpen, setOpen] = useState(false)

    return <div className="admin-sidebar">
        <div className="admin-logo bg-white d-flex justify-content-center h-100 align-items-center">
            <Navbar.Brand href="/" className="brand-img m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="34"
                     viewBox="0 0 264.484 134.395">
                    <path id="Path_1" data-name="Path 1"
                          d="M623.42,462.213,711.75,349.52l53.074,67.6,16.889-22L813.8,436.879h21.238l-53.327-70.217-16.889,22.463-15.665-19.8,32.554-41.505,106.19,134.4H802.487l-20.774-25.334-16.889,20.267L711.75,389.125l-36.9,47.754h20.731l16.171-19.76,15.707,19.76-19.8,25.334Z"
                          transform="translate(-623.42 -327.818)" fill="#1E2856"/>
                </svg>
            </Navbar.Brand>
            <div className="txt-brand-admin d-none">
                <Hamburger color="#1E2856" toggled={isOpen} toggle={setOpen}
                           onClick={() => setOpen(!isOpen)}/>
            </div>
        </div>

        <Nav id={isOpen ? "open" : ""}
             className="flex-column p-4 admin-sidebar-down">


            {SidebarDataAdmin.map((val, key) => {
                return (
                    <NavLink key={key} exact={val.title === "Dashboard"} to={val.hrf} onClick={() => setOpen(false)} className={`nav-link bg-gradient ${val.hrf === "/" ? "bg-dark" : ""}`}>
                        <div className="nav-icon">{val.icon}</div>
                        <p id="nav-title" className="ps-3">{val.title}</p>
                    </NavLink>
                )
            })}
        </Nav>


    </div>
}

export default SidebarAdmin;