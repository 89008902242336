import React, {Component} from 'react';
import moment from "moment";
import {Button, Col, Modal, Row, Form, OverlayTrigger, Popover, Offcanvas, Stack} from "react-bootstrap";
import "./welcomewindow.scss"
import {withRouter} from 'react-router-dom';
import DatePickerHooks from "../datepicker/DatePickerHooks";
import {formatDate} from "react-day-picker/moment";
import {FaSearch} from "react-icons/all";
import GuestPicker from "../datepicker/GuestPicker";
import Container from "react-bootstrap/Container";
import {Trans, withTranslation} from "react-i18next";
import AuthService from "../../service/AuthService";


class SearchBar extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        reservationDayStart: moment().clone().add(1, 'days').format('YYYY-MM-DD'),
        reservationDayEnd: moment().clone().add(2, 'days').format('YYYY-MM-DD'),
        howManyPeople: 1,
        nights: 1,
        amountToPay: 0,
        isStartingDayAfterEndingDay: false,
        isOpen: false,

        defaultStart: null,
        defaultEnd: null,
        defaultPeople: 1,
        showPickers: false,
        backdropShow: false,
        showDatePopover: false,
        showGuestPopover: false,

        showMobileDate: false,
        showMobileGuest: false,

        user: false
    }

    componentDidMount() {
        let reservationString = sessionStorage.getItem("reservation");
        let reservation = JSON.parse(reservationString);

        this.setState({
            user: AuthService.getCurrentUser()
        })

        if (reservation !== null) {
            this.setState({
                reservationDayStart: reservation.reservationDayStart,
                reservationDayEnd: reservation.reservationDayEnd,
                howManyPeople: this.props.location.pathname === "/" ? 1 : reservation.howManyPeople,
                nights: reservation.nights,

                defaultStart: reservation.reservationDayStart,
                defaultEnd: reservation.reservationDayEnd,
                defaultPeople: reservation.howManyPeople,
            })
        }

        document.addEventListener('scroll', this.handleScroll);
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.key === "Escape") {
            this.setState({
                showPickers: false,
                backdropShow: false
            })
        }
    }

    handleScroll = () => {
        if (window.scrollY > 100) {
            this.setState({
                showPickers: false,
                backdropShow: false,
            })
            try {
                this.myRef.current.blur()
            } catch (e) {
            }
            document.body.click()
        }
    }


    numberOfPeopleChanger = (e) => {
        this.setState({
            howManyPeople: e
        })
    }

    closeModal = () => {
        this.setState({
            isOpen: false
        })
    }

    dateCheckAndCountNightsHandler = (nights) => {
        this.setState({
            nights: nights
        })
    }


    validateAndSendHandler = (e) => {

        e.preventDefault();


        const reservationDayStart = this.state.reservationDayStart
        const reservationDayEnd = this.state.reservationDayEnd


        for (let i = 2022 ; i < 2122 ; i++) {
            if (!this.state.user && (moment(i+'-12-31', 'YYYY-MM-DD').isBetween(reservationDayStart, reservationDayEnd) || moment(i+'-12-31', 'YYYY-MM-DD').isSame(reservationDayStart, 'day') || moment(i+'-12-31', 'YYYY-MM-DD').isSame(reservationDayEnd, 'day')) ) {
                // console.log("blokada");
                return;
            }
        }

        //if (!this.state.user && (moment('2024-01-27', 'YYYY-MM-DD').isBetween(reservationDayStart, reservationDayEnd) || moment('2024-01-27', 'YYYY-MM-DD').isSame(reservationDayStart, 'day') || moment('2024-01-27', 'YYYY-MM-DD').isSame(reservationDayEnd, 'day')) ) {
            // console.log("blokada");
            //return;
        //}




        if (this.state.nights === 0 || isNaN(this.state.nights)) {
            return;
        }

        // after moon 24:00
        if (moment(reservationDayStart).isBefore(moment().format('YYYY-MM-DD'), 'day')) {
            this.setState({
                isOpen: true
            })
            return;
        }

        if (this.state.reservationDayStart !== null && this.state.reservationDayEnd !== null) {

            if (moment(reservationDayStart).isAfter(reservationDayEnd)) {
                this.setState({
                    isStartingDayAfterEndingDay: true
                })
            } else {
                this.setState({
                    isStaringDayAfterEndingDay: false
                })

                let reservation = {
                    reservationDayStart: this.state.reservationDayStart,
                    reservationDayEnd: this.state.reservationDayEnd,
                    howManyPeople: this.state.howManyPeople,
                    nights: this.state.nights
                }
                sessionStorage.setItem("reservation", JSON.stringify(reservation))
                this.props.history.push('/rooms', {})
                console.log(this.state.reservationDayStart)
                console.log(this.state.reservationDayEnd)


                this.setState({
                    defaultStart: this.state.reservationDayStart,
                    defaultEnd: this.state.reservationDayEnd,
                    defaultPeople: this.state.howManyPeople,
                })
            }
        }
    }

    handleStartDateChange = start => {
        this.setState({reservationDayStart: start})
    }
    handleEndDateChange = end => {
        this.setState({reservationDayEnd: end})
    }

    inputInfo = () => {
        if (!this.state.reservationDayStart && !this.state.reservationDayEnd) {
            return `${this.props.t('SearchBar_5')} - ${this.props.t('SearchBar_6')} `
        } else if (this.state.reservationDayStart && !this.state.reservationDayEnd) {
            return `${formatDate(this.state.reservationDayStart, 'LL', this.props.t('lang'))} - ${this.props.t('SearchBar_6')}`
        } else if (!this.state.reservationDayStart && this.state.reservationDayEnd) {
            return `${this.props.t('SearchBar_5')} - ${formatDate(this.state.reservationDayEnd, 'LL', this.props.t('lang'))}`
        } else if (this.state.reservationDayStart && this.state.reservationDayEnd) {
            return `${formatDate(this.state.reservationDayStart, 'LL', this.props.t('lang'))} - ${formatDate(this.state.reservationDayEnd, 'LL', this.props.t('lang'))}`
        }
    }

    render() {

        const handleClose = () => this.setState({showMobileDate: false});
        const handleShow = () => this.setState({showMobileDate: true});
        const handleCloseGuest = () => this.setState({showMobileGuest: false});
        const handleShowGuest = () => this.setState({showMobileGuest: true});

        return (
            <>
                <div style={{right: "0"}} className="sticky-top d-none d-md-flex w-100">
                    {this.props.location.pathname === "/rooms" ?
                        <div style={{zIndex: "2"}}
                             className="jumbotron-fluid bg-white border-bottom text-black py-3 shadow-xl w-100">
                            <Container>
                                <div tabIndex="1"
                                     ref={this.myRef}
                                     id="pickersField"
                                     className={"d-inline-flex bg-white"}>

                                    <OverlayTrigger transition={false} rootClose trigger="click"
                                                    placement="bottom-start" overlay={
                                        <Popover style={{maxWidth: "350px"}}
                                                 className=" box-rounded shadow-lg border-0 p-4"
                                                 id="popover-basic popover-guest">
                                            <GuestPicker
                                                number={this.state.howManyPeople}
                                                handleChange={this.numberOfPeopleChanger}/>
                                        </Popover>
                                    }>
                                        <div title="Zmień"
                                             onClick={() => this.setState({showPickers: true, backdropShow: true})}
                                             className={`cursor-pointer box-hover me-2 shadow-sm px-3 py-2 d-flex justify-content-start align-items-center bg-white border ${this.state.showPickers ? "border-primary" : ""} box-rounded`}>
                                            <div>
                                                {
                                                    this.state.backdropShow ?
                                                        <>
                                                            <strong>
                                                                {this.state.howManyPeople}
                                                            </strong> {(this.state.howManyPeople === 1 ? this.props.t('osoba') :
                                                            this.state.howManyPeople < 5 ? this.props.t('osoby') : this.props.t('osob'))}
                                                        </> :
                                                        <>
                                                            <strong>
                                                                {this.state.defaultPeople}
                                                            </strong> {(this.state.defaultPeople === 1 ? this.props.t('osoba') :
                                                            this.state.defaultPeople < 5 ? this.props.t('osoby') : this.props.t('osob'))}
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </OverlayTrigger>

                                    <DatePickerHooks
                                        onDayStartChange={this.handleStartDateChange}
                                        onDayEndChange={this.handleEndDateChange}
                                        onBlur={this.dateCheckAndCountNightsHandler}
                                        comp={
                                            <div onClick={() => this.setState({showPickers: true, backdropShow: true})}
                                                 className="d-flex">
                                                <div
                                                    title="Zmień"
                                                    className={`cursor-pointer box-hover me-2 shadow-sm px-3 py-2 d-flex justify-content-start align-items-center bg-white border ${this.state.showPickers ? "border-primary" : ""} box-rounded`}>
                                                    <div><Trans i18nKey='SearchBar_5'>Zameldowanie</Trans></div>
                                                    <div className=" mx-2"> •</div>
                                                    <div>
                                                        {
                                                            this.state.backdropShow ?
                                                                <strong>{this.state.reservationDayStart === null ? <Trans i18nKey='SearchBar_11'>Wybierz</Trans> : formatDate(this.state.reservationDayStart, 'LL', this.props.t('lang'))}</strong> :
                                                                <strong>{this.state.defaultStart === null ? <Trans i18nKey='SearchBar_11'>Wybierz</Trans> : formatDate(this.state.defaultStart, 'll', this.props.t('lang'))}</strong>
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    title="Zmień"
                                                    className={`cursor-pointer box-hover me-2 shadow-sm px-3 py-2 d-flex justify-content-start align-items-center bg-white border ${this.state.showPickers ? "border-primary" : ""} box-rounded`}>
                                                    <div><Trans i18nKey='SearchBar_6'>Wymeldowanie</Trans></div>
                                                    <div className=" mx-2"> •</div>
                                                    {
                                                        this.state.backdropShow ?
                                                            <strong>{this.state.reservationDayEnd === null ? <Trans i18nKey='SearchBar_11'>Wybierz</Trans> : formatDate(this.state.reservationDayEnd, 'LL', this.props.t('lang'))}</strong> :
                                                            <strong>{this.state.defaultEnd === null ? <Trans i18nKey='SearchBar_11'>Wybierz</Trans> : formatDate(this.state.defaultEnd, 'll', this.props.t('lang'))}</strong>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    />

                                    <Button
                                        onClick={
                                            (e) => {
                                                this.validateAndSendHandler(e)
                                                this.setState({
                                                    showPickers: false,
                                                    backdropShow: false
                                                })
                                            }
                                        }
                                        className="box-rounded-lg px-5 ms-2 text-white"
                                        variant={"grad"}>
                                        <FaSearch/>
                                        {this.state.showPickers ? <Trans i18nKey='SearchBar_4'> Szukaj</Trans> : null}
                                    </Button>
                                </div>
                            </Container>
                        </div> :
                        <div className="p-1 bg-white box-rounded-lg search-bar w-100">
                            <Row className="p-0 h-picker m-2 w-100">
                                {/*ILOSC OSOB*/}
                                <Col xs={12} sm={3} className="p-0">
                                    <OverlayTrigger transition={false} rootClose trigger="click" placement="bottom"
                                                    overlay={
                                                        <Popover style={{maxWidth: "350px"}}
                                                                 className="box-rounded shadow-lg border-0 p-4"
                                                                 id="popover-basic popover-guest">
                                                            <GuestPicker number={this.state.howManyPeople}
                                                                         handleChange={this.numberOfPeopleChanger}/>
                                                        </Popover>
                                                    }>
                                        <Form.Control className="cursor-pointer h-100 border-0"
                                                      title={this.props.t('SearchBar_2')}
                                                      value={this.state.howManyPeople + " " + (this.state.howManyPeople === 1 ? this.props.t('osoba') :
                                                          this.state.howManyPeople < 5 ? this.props.t('osoby') : this.props.t('osob'))}
                                                      type="text" readOnly
                                        />

                                    </OverlayTrigger>
                                </Col>
                                <Col xs={12} sm={6} className="px-1">
                                    <DatePickerHooks
                                        onDayStartChange={this.handleStartDateChange}
                                        onDayEndChange={this.handleEndDateChange}
                                        onBlur={this.dateCheckAndCountNightsHandler}
                                        comp={
                                            <Form.Control id={"date-input"} title={this.props.t('SearchBar_3')}
                                                          className="cursor-pointer h-100 border-0" type="text"
                                                          value={this.inputInfo()} readOnly/>
                                        }
                                    />
                                </Col>
                                <Col xs={12} sm={3} className="pe-3 ps-0">
                                    <Button onClick={(e) => {
                                        this.validateAndSendHandler(e)
                                        if (this.state.reservationDayStart === null && this.state.reservationDayEnd === null) {
                                            document.getElementById("date-input").click()
                                        }
                                    }}
                                            type="submit"
                                            variant="grad"
                                            className="m-0 w-100 h-100">
                                        <FaSearch/> <Trans i18nKey='SearchBar_4'>Szukaj</Trans>
                                    </Button>
                                    {this.state.isStartingDayAfterEndingDay ?
                                        <div className="alert alert-danger position-absolute" role="alert">
                                            <Trans i18nKey='SearchBar_8'>Proszę wybrać datę wyjazdu.</Trans>
                                        </div> : null
                                    }
                                </Col>
                            </Row>
                        </div>
                    }
                    {this.state.backdropShow ?
                        <div onClick={() => this.setState({backdropShow: false, showPickers: false})}
                             className="backdrop"/> : ""}

                </div>

                <div
                    className={`d-flex justify-content-center align-items-center py-3 d-md-none sticky-top ${this.props.location.pathname === "/" ? "" : "bg-white border-bottom shadow-sm"}`}>


                    {(this.state.defaultStart === '' && this.state.defaultEnd === '') || this.props.location.pathname === "/" ?
                        <div
                            title="Zmień"
                            onClick={handleShow}
                            className={`cursor-pointer box-hover shadow-sm px-3 py-2 d-flex justify-content-start align-items-center bg-white border ${this.state.showPickers ? "border-primary" : ""} box-rounded`}>

                            <span><Trans i18nKey='SearchBar_10'>Witaj, kiedy chcesz przyjechać?</Trans></span>
                            <span className="text-primary ms-2"><FaSearch/></span>
                        </div> :

                        <Stack direction="horizontal" gap={3}>

                            <div
                                title={this.props.t('SearchBar_11')}
                                onClick={handleShowGuest}
                                className={`cursor-pointer box-hover shadow-sm px-3 py-2 d-flex justify-content-start align-items-center bg-white border ${this.state.showPickers ? "border-primary" : ""} box-rounded`}>

                                {this.state.defaultPeople !== 0 ?
                                    <span>{this.state.defaultPeople} {(this.state.defaultPeople === 1 ? this.props.t('osoba') :
                                        this.state.defaultPeople < 5 ? this.props.t('osoby') : this.props.t('osob'))}</span> :
                                    <span><Trans i18nKey="SearchBar_1">Liczba osób</Trans></span>
                                }
                            </div>
                            <div
                                title={this.props.t('SearchBar_11')}
                                onClick={handleShow}
                                className={`cursor-pointer box-hover shadow-sm px-3 py-2 d-flex justify-content-start align-items-center bg-white border ${this.state.showPickers ? "border-primary" : ""} box-rounded`}>

                                {this.state.defaultStart !== null && this.state.defaultEnd !== null ?
                                    <span>
                                {formatDate(this.state.defaultStart, 'MMM', this.props.t('lang')) === formatDate(this.state.defaultEnd, 'MMM', this.props.t('lang')) ? formatDate(this.state.defaultStart, 'DD', this.props.t('lang')) : formatDate(this.state.defaultStart, 'DD MMM', this.props.t('lang'))} - {formatDate(this.state.defaultEnd, 'DD MMM YYYY', this.props.t('lang'))}
                            </span> :
                                    <span><Trans i18nKey='SearchBar_3'>Czas pobytu</Trans></span>
                                }
                            </div>
                        </Stack>
                    }

                    <Offcanvas placement={"bottom"} show={this.state.showMobileDate} onHide={handleClose}>
                        <Offcanvas.Header closeButton className="border-bottom">
                            <Offcanvas.Title className="m-0"><Trans i18nKey='SearchBar_3'>Czas pobytu</Trans></Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="InputFromTo">
                                <DatePickerHooks
                                    onDayStartChange={this.handleStartDateChange}
                                    onDayEndChange={this.handleEndDateChange}
                                    onBlur={this.dateCheckAndCountNightsHandler}
                                    variant={2}
                                    comp={null}
                                />
                            </div>
                        </Offcanvas.Body>
                        <div className="p-3 border-top d-flex justify-content-between">
                            <Button
                                disabled={this.state.reservationDayStart === null || this.state.reservationDayEnd === null}

                                onClick={(e) => {
                                    if (this.props.location.pathname === "/") {
                                        handleShowGuest()
                                        handleClose()
                                    } else {
                                        this.validateAndSendHandler(e);
                                        handleClose()
                                    }

                                }}
                                className="text-white col-12"
                                variant={"grad"}>
                                {this.props.location.pathname === "/" ?
                                    <span><Trans i18nKey="SearchBar_1">Liczba osób</Trans> &#10230;</span> :
                                    <>
                                        <FaSearch/>
                                        <span className="ms-2"><Trans i18nKey='SearchBar_4'>Szukaj</Trans></span>
                                    </>
                                }
                            </Button>
                        </div>
                    </Offcanvas>

                    <Offcanvas placement={"bottom"} show={this.state.showMobileGuest} onHide={handleCloseGuest}>
                        <Offcanvas.Header closeButton className="border-bottom">
                            <Offcanvas.Title className="m-0"><Trans i18nKey="SearchBar_1">Liczba osób</Trans></Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <GuestPicker number={this.state.howManyPeople}
                                         handleChange={this.numberOfPeopleChanger}/>
                        </Offcanvas.Body>
                        <div className="p-3 border-top d-flex justify-content-end">

                            <Button
                                onClick={(e) => {
                                    this.validateAndSendHandler(e);
                                    handleCloseGuest()
                                }}
                                className="text-white col-12"
                                variant={"grad"}>
                                <FaSearch/>
                                <span className="ms-2"><Trans i18nKey='SearchBar_4'>Szukaj</Trans></span>
                            </Button>
                        </div>
                    </Offcanvas>

                </div>


                <Modal show={this.state.isOpen} onHide={this.closeModal}>

                    <Modal.Header closeButton>
                        <Modal.Title>Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Wprowadzony zakres dat jest nieprawidłowy.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="grad" onClick={this.closeModal}>
                            Zamknij
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }


}

export default withRouter(withTranslation()(SearchBar))
