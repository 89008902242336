import React, {useEffect, useState} from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import {format} from "date-fns";
import {pl} from "date-fns/locale";
import RoomService from "../../../../service/RoomService";
import overlayFactory from "react-bootstrap-table2-overlay";
import DeleteBlockade from "./DeleteBlockade";


const locale = {locale: pl}

const BlockadesTable = ({roomId, reFetch, setReFetch}) => {

    const [blockedDates, setBlockedDates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const columns = [
        {
            dataField: "dateFrom",
            text: "Od",
            sort: false,
            formatter: (cellContent, row) => (
                <>
                    {format(new Date(row.dateFrom), "EEE, dd MMM yyyy", locale)}
                </>
            )
        },
        {
            dataField: "dateTo",
            text: "Do",
            sort: false,
            formatter: (cellContent, row) => (
                <>
                    {format(new Date(row.dateTo), "EEE, dd MMM yyyy", locale)}
                </>
            )
        },
        {
            dataField: "creationDate",
            text: "Data dodania blokady",
            sort: false,
            formatter: (cellContent, row) => (
                <>
                    {format(new Date(row.creationDate), "EEE, dd MMM yyyy HH:mm:ss", locale)}
                </>
            )
        },
        {
            dataField: "delete",
            text: "",
            sort: false,
            formatter: (cellContent, row) => <DeleteBlockade blockadeId={row.blockadeId} reFetch={reFetch} setReFetch={setReFetch}/>
        }
    ];

    const getBlockades = RoomService.getBlockedDatesForSpecificRoom(roomId);

    useEffect(() => {

        getBlockades
            .then((r) => {
                setBlockedDates(r.data.blockedDates);
                setIsLoading(false);
            }).catch((error => {
                console.log(error);
            }
        ))

    }, [reFetch])


    const expandRow = {
        renderer: row => (
            <div className="p-4">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <u className="fw-bold">Istnieją następujące rezerwacje dla tej blokady:</u>
                    <div>ID blokady: {row.blockadeId}</div>
                </div>
                <div className="flex">
                    {
                        row.relatedReservations.length > 0 ?

                            <table className="table-auto w-100">
                                <thead>
                                <tr className="text-sm">
                                    <th className="py-2 pe-4">Imię</th>
                                    <th className="py-2 pe-4">Nazwisko</th>
                                    <th className="py-2 pe-4">Email</th>
                                    <th className="py-2 pe-4">Data rozpoczęcia</th>
                                    <th className="py-2 pe-4">Data zakończenia</th>
                                    <th className="py-2 pe-4">Data złożenia rezerwacji</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    row.relatedReservations.map((res, key) => {
                                        return (
                                            <tr key={key} className="text-sm">
                                                <td className="py-2 pe-4">{res.firstName}</td>
                                                <td className="py-2 pe-4">{res.lastName}</td>
                                                <td className="py-2 pe-4">{res.email}</td>
                                                <td className="py-2 pe-4">{res.reservationDayStart}</td>
                                                <td className="py-2 pe-4">{res.reservationDayEnd}</td>
                                                <td className="py-2 pe-4">
                                                    {format(new Date(res.dateOfAddingReservation), "EEE, dd MMM yyyy HH:mm:ss", locale)}
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </table> : "Brak powiązanych rezerwacji"
                    }
                </div>
            </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true
    };

    return (
        <div className="overflow-auto max-h-[50vh]">
            <BootstrapTable
                bootstrap4
                keyField='blockadeId'
                data={blockedDates}
                columns={columns}
                expandRow={expandRow}
                noDataIndication={isLoading === false ? "Brak blokad" : <div/>}
                loading={isLoading}
                overlay={overlayFactory({
                    spinner: true,
                    styles: {
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(30, 40, 86, 0.5)'
                        })
                    }
                })}
            />
        </div>
    );
};

export default BlockadesTable;
