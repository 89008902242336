import React from 'react';
import {Col, Row, Stack} from "react-bootstrap";
import Rules from "./settings-page/rules/Rules";
import Amenities from "./settings-page/amenities/Amenities";

const Shelter = () => {
    return (
        <div className="container-fluid overloading">
            <Row>
                <Col>
                    <div className="admin-panel-title mb-4">Stan w pokojach</div>
                </Col>
            </Row>
            <div className="card-admin p-4">
                <Stack gap={5}>
                    <Rules/>
                    <Amenities/>
                </Stack>
            </div>
        </div>
    );
};

export default Shelter;