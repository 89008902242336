import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import "./Dashboard.scss"
import ReservationService from "../../../service/ReservationService";

const OverviewWidget = ({date, refresh}) => {

    const [overview, setOverview] = useState([]);

    useEffect(() => {
        ReservationService.getReservationsOverview({providedDate: date}).then(response => {
            setOverview(response.data);
        }).catch(e => console.log(e))
    }, [date, refresh]);


    return (
        <Row className="overview-widget">
            <Col className="">
                <div className="overview-body">
                    <p>{overview.newReservations}</p>
                    <p>nowych<br/>rezerwacji</p>
                </div>
            </Col>
            <Col className="">
                <div className="overview-body">
                    <p>{overview.todayReservations}</p>
                    <p>przyjazdy<br/>w tym dniu</p>
                </div>
            </Col>
            <Col className="">
                <div className="overview-body">
                    <p>{overview.numberOfPeoplePerDay}</p>
                    <p>osoby na<br/>schronisku</p>
                </div>
            </Col>
            <Col className="">
                <div className="overview-body">
                    <p>{overview.outReservations}</p>
                    <p>wyjazdy w<br/>tym dniu</p>
                </div>
            </Col>
        </Row>
    );
}

export default OverviewWidget;