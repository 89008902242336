import React from 'react';

const Facebook = () => {
    return (
        <div>
            <div id="fb-root"> </div>

            <div id="fb-customer-chat" className="fb-customerchat">
            </div>
        </div>
    );
};

export default Facebook;