import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import ReservationService from "../../../service/ReservationService";
import {NavLink} from "react-router-dom";

const UpcomingArrivalsWidget = ({date, refresh}) => {

    const [coming, setComing] = useState([]);

    useEffect(() => {
        ReservationService.getComingReservations({providedDate: date}).then(response => {
            setComing(response.data.reservations);
            console.log(response.data.reservations)
        }).catch(e => console.log(e))
    }, [date, refresh]);


    return (
        <div className="upcoming-arrivals">
            <Table responsive>
                <thead>
                <tr>
                    <th scope="col">Imię i nazwisko</th>
                    <th scope="col">Ilość osób</th>
                    <th scope="col">Pokój</th>
                    <th scope="col">Przyjazd</th>
                    <th scope="col">Wyjazd</th>
                </tr>
                </thead>
                <tbody>
                {coming.length > 0 ?
                    coming.slice(0, 4).map((reservation, key) => {

                        return (
                            <tr key={key}>
                                <td className="d-flex align-items-center text-nowrap">
                                    <div className="list-avatar me-3">{reservation.firstLetterOfSurname}</div>
                                    {reservation.firstName} {reservation.lastName}
                                </td>
                                <td>{reservation.howManyPeople}</td>
                                <td>{reservation.roomNumbers.join(', ')}</td>
                                <td className="text-nowrap">{reservation.reservationStart}</td>
                                <td className="text-nowrap">{reservation.reservationEnd}</td>
                            </tr>
                        )

                    })
                    : <div className="my-4">Brak przyjazdów</div>
                }
                </tbody>
            </Table>
            {
                coming.length > 4 ?
                    <NavLink
                        onClick={() => localStorage.setItem("MSSearchParams", JSON.stringify({
                            arrivalDate: date
                        }))}
                        to={"/admin/rezerwacje"}>Zobacz więcej...</NavLink> : ""
            }
        </div>
    );
}

export default UpcomingArrivalsWidget;