import React, {Component} from 'react';
import RoomService from "../../service/RoomService";
import Card from 'react-bootstrap/Card';
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {
    FaDoorClosed, FaEye,
    FaInfoCircle,
    FaShoppingBag, FaUser,
    GiMountainClimbing,
} from "react-icons/all";
import {CloseButton, Container, Spinner} from "react-bootstrap";
import "../shoppingcart/cart.scss"
import RoomAvailableDate from "../datepicker/RoomAvailableDate";

import {motion} from "framer-motion";
import Cart from "../shoppingcart/Cart";
import MobileBottomMenu from "../navbar/MobileBottomMenu";
import moment from "moment";
import AuthService from "../../service/AuthService";
import RoomDetailsOffcanvas from "./RoomDetailsOffcanvas";
import {formatDate} from "react-day-picker/moment";
import {Trans, withTranslation} from "react-i18next";

const container = {
    hidden: {opacity: 1, scale: 0},
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2
        }
    }
};

const item = {
    hidden: {y: 20, opacity: 0},
    visible: {
        y: 0,
        opacity: 1
    }
};

class RoomListComponent extends Component {

    constructor(props) {
        super(props);
        this.cartRef = React.createRef();
    }

    state = {
        rooms: [],
        reservationDayStart: null,
        reservationDayEnd: null,
        howManyPeople: null,
        nights: null,
        isOpen: false,
        roomUrl: "a",
        ifAtLeastOneRoomIsDisplayed: false,
        cartRooms: [],
        show: false,
        type: "na wyłączność",
        peopleAccommodated: 0,
        deleteCopy: true,
        arePeopleAccommodated: false,
        amountToPay: 0,
        roomDescription: "",
        isStartingDayAfterEndingDay: false,
        roomId: null,
        infoModal: true,
        cartModal: false,
        reload: false,
        noRoomsInfo: false,
        showDatePicker: false,
        numberOfPeople: null,
        isLoading: false
    }

    openModal = (imgUrl, roomDescription, roomId) => {

        this.setState({
            roomUrl: imgUrl,
            description: roomDescription,
            roomId: roomId,
            isOpen: true,
        })
    }
    closeModal = () => this.setState({isOpen: false});


    async componentDidMount() {
        this.setState({
            isLoading: true
        })

        try {
            let reservationString = sessionStorage.getItem("reservation");
            let reservation = JSON.parse(reservationString);

            let arrivalDetails = {
                numberOfPeople: reservation.howManyPeople,
                reservationDayStart: reservation.reservationDayStart,
                reservationDayEnd: reservation.reservationDayEnd
            }
            this.setState({
                reservationDayStart: reservation.reservationDayStart,
                reservationDayEnd: reservation.reservationDayEnd,
                howManyPeople: reservation.cart !== undefined ? reservation.howManyPeople : 0,
                nights: reservation.nights,
                cartRooms: reservation.cart !== undefined ? reservation.cart : [],
                amountToPay: reservation.cart !== undefined ? reservation.amountToPay : 0,
                numberOfPeople: reservation.howManyPeople
            })
            let currentUser = AuthService.getCurrentUser();

            if (currentUser) {

                const res = await RoomService.getAvailableAdminRooms(arrivalDetails);

                if (res) {
                    this.setState({
                        rooms: res.data,
                        noRoomsInfo: res.data.length === 0,
                        isLoading: false
                    })
                }


            } else {

                const res = await RoomService.getAvailableRooms(arrivalDetails);

                this.setState({
                    rooms: res.data,
                    noRoomsInfo: res.data.length === 0,
                    isLoading: false
                })
            }

        } catch (e) {
            this.setState({
                reservationDayStart: moment().clone().add(1, 'days').format('YYYY-MM-DD'),
                reservationDayEnd: moment().clone().add(2, 'days').format('YYYY-MM-DD'),
                howManyPeople: 0,
                nights: 1,
                cartRooms: [],
                amountToPay: 0,
                numberOfPeople: 1
            })
            let arrivalDetails = {
                numberOfPeople: 1,
                reservationDayStart: moment().clone().add(1, 'days').format('YYYY-MM-DD'),
                reservationDayEnd: moment().clone().add(2, 'days').format('YYYY-MM-DD')
            }
            let currentUser = AuthService.getCurrentUser();

            if (currentUser) {
                RoomService.getAvailableAdminRooms(arrivalDetails).then(res => this.setState({
                    rooms: res.data,
                    noRoomsInfo: res.data.length === 0,
                    isLoading: false
                }));
            } else {
                RoomService.getAvailableRooms(arrivalDetails).then(res => this.setState({
                    rooms: res.data,
                    noRoomsInfo: res.data.length === 0,
                    isLoading: false
                }));
            }
        }


    }

    closeInfoModal = () => {
        this.setState({
            infoModal: false
        })
    }

    handleClose = () => {
        this.setState({
            show: false
        })
    }

    handleShow = () => {
        this.setState({
            show: true
        })
    }


    goToForm = () => {
        let allRoomsWithAtLeastOnePerson = true;

        for (const cartRoom of this.state.cartRooms) {
            if (cartRoom.numberOfGuests === 0) {
                allRoomsWithAtLeastOnePerson = false
            }
        }

        if (!allRoomsWithAtLeastOnePerson) {
            alert("Usuń z koszyka puste pokoje")
            return;
        }

        let customer = {
            reservationDayStart: this.state.reservationDayStart,
            reservationDayEnd: this.state.reservationDayEnd,
            howManyPeople: this.state.howManyPeople,
            nights: this.state.nights,
            cart: this.state.cartRooms,
            amountToPay: this.state.amountToPay
        }

        sessionStorage.setItem("reservation", JSON.stringify(customer))

        this.props.history.push(`/make-reservation/1`, {});
    }

    addRoomToCart = (newRoom, type = "na miejsca") => {
        // console.log(newRoom)
        let cartRooms = this.state.cartRooms;
        let cartRoom;
        if (type === "na wyłączność") {
            cartRoom = {
                details: newRoom,
                type: type,
                numberOfGuests: newRoom.forHowManyPeople,
            }
        } else if (type === "na miejsca") {
            cartRoom = {
                details: newRoom,
                type: type,
                numberOfGuests: 0
            }
        }
        let isTheRoomInCart = false
        this.state.cartRooms.forEach(room => {
            if (room.details.id === newRoom.id) {
                isTheRoomInCart = true
            }

        })
        if (isTheRoomInCart) {
            alert("Nie możesz dodać dwa razy tego samego pokoju.")
        } else {
            if (type === "na miejsca") {

            } else {
                let amountToPay = this.state.amountToPay
                let howManyPeople = this.state.howManyPeople
                amountToPay += (newRoom.forHowManyPeople * newRoom.pricePerPeron)
                howManyPeople += newRoom.forHowManyPeople
                this.setState({
                    cartRooms: cartRooms,
                    amountToPay: amountToPay,
                    howManyPeople: howManyPeople
                })
            }
            cartRooms.push(cartRoom);
            this.setState({
                cartRooms: cartRooms
            })
        }
    }


    addPeopleToRoom = (number, room) => {
        let howManyPeople = this.state.howManyPeople
        let cartRooms = this.state.cartRooms
        let amountToPay = this.state.amountToPay

        if (number < 0) {
            number = -1
        } else {
            number = 1;
        }


        if (number > 0) {
            for (let i = 0; i < cartRooms.length; i++) {
                if (cartRooms[i].details.id === room.details.id) {
                    cartRooms[i].numberOfGuests = cartRooms[i].numberOfGuests + number
                }
            }
            howManyPeople = howManyPeople + number
            amountToPay += room.details.pricePerPeron * number
        } else {
            for (let i = 0; i < cartRooms.length; i++) {
                if (cartRooms[i].details.id === room.details.id) {
                    cartRooms[i].numberOfGuests = cartRooms[i].numberOfGuests + number
                }
            }
            howManyPeople = howManyPeople + number
            amountToPay -= room.details.pricePerPeron * (number * (-1))
        }


        this.setState({
            cartRooms: cartRooms,
            amountToPay: amountToPay,
            howManyPeople: howManyPeople
        })

    }

    removeRoomFromCart = (roomToDelete) => {
        let cartRooms = this.state.cartRooms
        let amountToPay = this.state.amountToPay
        let howManyPeople = this.state.howManyPeople
        cartRooms = cartRooms.filter(room => room.details.id !== roomToDelete.details.id)

        amountToPay -= roomToDelete.details.pricePerPeron * roomToDelete.numberOfGuests

        howManyPeople -= roomToDelete.numberOfGuests

        this.setState({
            cartRooms: cartRooms,
            amountToPay: amountToPay,
            howManyPeople: howManyPeople
        })
    }

    isInCart = (id) => {
        let isIn = this.state.cartRooms.map((item) => {
            return item.details.id
        })
        return isIn.includes(id)
    }

    handleGuestChange = guests => {
        this.setState({howManyPeople: guests})
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            // navigated!
            this.componentDidMount()
        }
    }

    render() {

        return (
            <section>
                <h1 className="d-none">Schronisko PTTK Markowe Szczawiny | Lista dostępnych pokoi</h1>
                <Container>
                    <div className="my-3 d-flex">
                        <span className="me-2">
                            <Trans
                                i18nKey='RoomList_9'>Wyniki wyszukiwania</Trans> {formatDate(this.state.reservationDayStart, 'MMM', this.props.t('lang')) === formatDate(this.state.reservationDayEnd, 'MMM', this.props.t('lang')) ?
                            formatDate(this.state.reservationDayStart, 'DD', this.props.t('lang')) :
                            formatDate(this.state.reservationDayStart, 'DD MMM', this.props.t('lang'))} - {formatDate(this.state.reservationDayEnd, 'DD MMM YYYY', this.props.t('lang'))} •
                            <strong> <Trans
                                i18nKey='RoomList_10'>Znaleziono</Trans> {this.state.rooms.length}  </strong>
                        </span>
                    </div>
                    <div className="mb-5 row">
                        {
                            this.state.rooms.length !== 0 && this.state.isLoading ?
                                <div className="col-lg-9 col-md-8 col-12">
                                    <div className=" card border p-4 mb-4">
                                        <span className="text-center"><Spinner animation="grow"/></span>
                                    </div>
                                </div> : null
                        }
                        <motion.div
                            className="col-lg-9 col-md-8 col-12"
                            key={this.props.location.key}
                            variants={container}
                            initial="hidden"
                            animate={this.state.rooms.length && !this.state.isLoading > 0 && "visible"}
                        >
                            {
                                this.state.rooms.map((room, index) => (
                                    <motion.div key={index} className="" variants={item}>
                                        <div className="card p-0 mb-4">
                                            <div className="row order-rl">

                                                <RoomDetailsOffcanvas
                                                    room={room}
                                                    isInCart={this.isInCart(room.id)}
                                                    addRoom={() => this.addRoomToCart(room, this.state.type)}
                                                    addPlaces={() => this.addRoomToCart(room)}
                                                />

                                                <div className="col-xl-8">
                                                    <div className="row card-body h-100">
                                                        <div className="col-xl-8 card-title m-0 p-0">
                                                            <h4>{this.props.t(room.description)}</h4>
                                                            <div className="icons-inline">
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip>{this.props.t("Liczba miejsc")}</Tooltip>}
                                                                >
                                                                    <p>
                                                                        <FaUser/> {room.forHowManyPeople}
                                                                    </p>
                                                                </OverlayTrigger>

                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip>{this.props.t("Liczba wolnych miejsc")}</Tooltip>}
                                                                >
                                                                    <p>
                                                                        <FaUser
                                                                            className="text-success"/> {room.placesLeft}
                                                                    </p>
                                                                </OverlayTrigger>


                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip>{this.props.t("Numer pokoju")}</Tooltip>}
                                                                >
                                                                    <p>
                                                                        <FaDoorClosed/> {room.roomNumber}
                                                                    </p>
                                                                </OverlayTrigger>

                                                            </div>
                                                            <div className="mt-2">
                                                                <p className="mb-1">
                                                                <span className="link-primary cursor-pointer"
                                                                      onClick={() => this.openModal(room.imgUrl, room.description, room.id)}>
                                                                    <FaEye/> Sprawdź
                                                                    dostępność
                                                                </span>
                                                                </p>
                                                                {(room.forHowManyPeople !== room.placesLeft) ? null :
                                                                    <p className="d-flex align-items-center">
                                                                        <FaInfoCircle/>
                                                                        <span className="fst-italic ms-2">
                                                                    <Trans i18nKey='RoomList_8'>Istnieje możliwość wynajęcia całego pokoju</Trans>
                                                                </span>
                                                                    </p>
                                                                }
                                                            </div>
                                                            <div className="row order-second text-nowrap mt-3">
                                                                <div
                                                                    className={this.isInCart(room.id) ? "d-none" : "mb-2 col"}>
                                                                    <Button variant="grad"
                                                                            className="col-12"
                                                                            size="lg"
                                                                            disabled={(room.forHowManyPeople === 3) || (room.forHowManyPeople === 2) || (this.isInCart(room.id))}
                                                                            onClick={() => this.addRoomToCart(room)}
                                                                    >
                                                                        <Trans i18nKey='RoomList_12'>Wynajmij
                                                                            miejsca</Trans>
                                                                    </Button>
                                                                </div>
                                                                <div className="col">
                                                                    <Button variant="grad"
                                                                            className="col-12 mb-2"
                                                                            size="lg"
                                                                            disabled={(room.forHowManyPeople !== room.placesLeft) || (this.isInCart(room.id))}
                                                                            onClick={() => this.addRoomToCart(room, this.state.type)}
                                                                    >
                                                                        {(this.isInCart(room.id)) ?
                                                                            <Trans i18nKey='RoomList_13'>W
                                                                                koszyku</Trans> :
                                                                            <Trans i18nKey='RoomList_11'>Wynajmij
                                                                                pokój</Trans>}

                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="col-xl-4 col text-nowrap d-flex justify-content-center align-items-center room-price desc-price">
                                                            <div className="mobile-price" title="Cena za noc">
                                                                <p className="price-up">
                                                                    {room.pricePerPeron} zł <small>/ <Trans
                                                                    i18nKey='RoomList_14'>os.</Trans></small>
                                                                </p>
                                                                <p className="price-down">
                                                                    {room.pricePerPeron * room.forHowManyPeople} zł <small>/ <Trans
                                                                    i18nKey='RoomList_15'>łącznie</Trans></small>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </motion.div>
                                ))
                            }
                        </motion.div>
                        {
                            this.state.rooms.length !== 0 ? null :
                                <div className="col-lg-9 col-md-8 col-12">
                                    <div className=" card border p-4 mb-4">
                                        {this.state.noRoomsInfo ?
                                            <div><Trans i18nKey='RoomList_27'>Przepraszamy, na wskazany
                                                termin</Trans>
                                                <strong>{this.state.reservationDayStart} - {this.state.reservationDayEnd}</strong><> </>
                                                <Trans i18nKey='RoomList_28'>nie
                                                    posiadamy wolnych miejsc noclegowych, zapraszamy do kontaktu ze
                                                    schroniskiem lub wyboru innego dostępnego terminu.</Trans></div> :
                                            <span className="text-center"><Spinner animation="grow"/></span>
                                        }
                                    </div>
                                </div>
                        }
                        <div className="col d-none d-md-inline px-2">
                            <div className="cart-sticky">
                                <div className="border box-rounded-sm shadow-xl bg-white p-3 ">
                                    <div className="mt-3 mb-4 text-center">
                                        <div className="h4 fw-bold "><Trans i18nKey='RoomList_16'>Twoja
                                            rezerwacja</Trans></div>
                                        <p><Trans i18nKey='RoomList_17'>Wybierz pokój i przejdź do podsumowania</Trans>
                                        </p>
                                    </div>

                                    {this.state.cartRooms.length === 0 ?
                                        <div>
                                            <div className="d-flex my-4 flex-column align-items-center">
                                                <div className="h2"><FaShoppingBag/></div>
                                                <div><strong><Trans i18nKey='RoomList_18'>Koszyk jest
                                                    pusty</Trans></strong></div>
                                                <div><small><Trans i18nKey='RoomList_19'>Dodaj pokój i zarezerwuj
                                                    termin</Trans></small></div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="cart-container border-0 overflow-auto">
                                                {this.state.cartRooms.map((room, k) => {
                                                    return (
                                                        <div key={k} className="cart-item">
                                                            <Card className="flex-row border-0">
                                                                <Card.Body className="p-0">
                                                                    <Card.Title
                                                                        className="d-flex mb-0 justify-content-between align-items-center">
                                                        <span
                                                            className="d-block cart-title">
                                                            {this.props.t(room.details.description)}
                                                        </span>
                                                                        <small>
                                                                            <CloseButton
                                                                                onClick={() => this.removeRoomFromCart(room)}
                                                                                aria-label="Hide"/>
                                                                        </small>
                                                                    </Card.Title>
                                                                    <Card.Text className="my-0">
                                                                        <small className="d-block">
                                                                            <span>{this.props.t(room.type)}</span> •
                                                                            nr {room.details.roomNumber}
                                                                        </small>

                                                                        <span
                                                                            className="d-flex justify-content-between align-items-center ">
                                                            <span className="d-flex align-items-center">
                                                                {
                                                                    room.type === this.state.type ?
                                                                        (<span className="fw-bold">
                                                                            {room.numberOfGuests}
                                                                        </span>) :
                                                                        (
                                                                            (room.numberOfGuests > 0 ?
                                                                                    (
                                                                                        room.numberOfGuests == room.details.placesLeft ?
                                                                                            (
                                                                                                <>
                                                                                                    <Button
                                                                                                        variant="counter"
                                                                                                        size=""
                                                                                                        disabled={false}
                                                                                                        onClick={() => this.addPeopleToRoom(-2, room)}>-
                                                                                                    </Button>
                                                                                                    <div
                                                                                                        className="counter-value">{room.numberOfGuests}</div>
                                                                                                    <Button
                                                                                                        variant="counter"
                                                                                                        size=""
                                                                                                        disabled={true}
                                                                                                        onClick={() => this.addPeopleToRoom(1, room)}>+
                                                                                                    </Button>
                                                                                                </>
                                                                                            ) :
                                                                                            (
                                                                                                room.numberOfGuests == room.details.placesLeft - 2 ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <Button
                                                                                                                variant="counter"
                                                                                                                size=""
                                                                                                                disabled={false}
                                                                                                                onClick={() => this.addPeopleToRoom(-1, room)}>-
                                                                                                            </Button>
                                                                                                            <div
                                                                                                                className="counter-value">{room.numberOfGuests}</div>
                                                                                                            <Button
                                                                                                                variant="counter"
                                                                                                                size=""
                                                                                                                disabled={false}
                                                                                                                onClick={() => this.addPeopleToRoom(2, room)}>+
                                                                                                            </Button>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <Button
                                                                                                                variant="counter"
                                                                                                                size=""
                                                                                                                disabled={false}
                                                                                                                onClick={() => this.addPeopleToRoom(-1, room)}>-
                                                                                                            </Button>
                                                                                                            <div
                                                                                                                className="counter-value">{room.numberOfGuests}</div>
                                                                                                            <Button
                                                                                                                variant="counter"
                                                                                                                size=""
                                                                                                                disabled={false}
                                                                                                                onClick={() => this.addPeopleToRoom(1, room)}>+
                                                                                                            </Button>
                                                                                                        </>
                                                                                                    )
                                                                                            )

                                                                                    ) : (
                                                                                        (
                                                                                            room.numberOfGuests == room.details.placesLeft ?
                                                                                                (
                                                                                                    <>
                                                                                                        <Button
                                                                                                            variant="counter"
                                                                                                            size=""
                                                                                                            disabled={true}
                                                                                                            onClick={() => this.addPeopleToRoom(-1, room)}>-
                                                                                                        </Button>
                                                                                                        <div
                                                                                                            className="counter-value">{room.numberOfGuests}</div>
                                                                                                        <Button
                                                                                                            variant="counter"
                                                                                                            size=""
                                                                                                            disabled={true}
                                                                                                            onClick={() => this.addPeopleToRoom(1, room)}>+
                                                                                                        </Button>
                                                                                                    </>
                                                                                                ) :
                                                                                                (
                                                                                                    room.numberOfGuests == room.details.placesLeft - 2 ?
                                                                                                        (
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    variant="counter"
                                                                                                                    size=""
                                                                                                                    disabled={true}
                                                                                                                    onClick={() => this.addPeopleToRoom(-1, room)}>-
                                                                                                                </Button>
                                                                                                                <div
                                                                                                                    className="counter-value">{room.numberOfGuests}</div>
                                                                                                                <Button
                                                                                                                    variant="counter"
                                                                                                                    size=""
                                                                                                                    disabled={false}
                                                                                                                    onClick={() => this.addPeopleToRoom(2, room)}>+
                                                                                                                </Button>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    variant="counter"
                                                                                                                    size=""
                                                                                                                    disabled={true}
                                                                                                                    onClick={() => this.addPeopleToRoom(-1, room)}>-
                                                                                                                </Button>
                                                                                                                <div
                                                                                                                    className="counter-value">{room.numberOfGuests}</div>
                                                                                                                <Button
                                                                                                                    variant="counter"
                                                                                                                    size=""
                                                                                                                    disabled={false}
                                                                                                                    onClick={() => this.addPeopleToRoom(1, room)}>+
                                                                                                                </Button>
                                                                                                            </>
                                                                                                        )
                                                                                                )


                                                                                        )
                                                                                    )
                                                                            )
                                                                        )
                                                                }
                                                                <span
                                                                    className="ms-1 fw-bold">{room.numberOfGuests === 1 ? this.props.t("osoba") : room.numberOfGuests < 5 && room.numberOfGuests > 0 ? this.props.t("osoby") : this.props.t("osob")}</span>
                                                        </span>
                                                        <span>
                                                            <span
                                                                className="fw-bold">{room.details.pricePerPeron * room.numberOfGuests * this.state.nights} zł</span>
                                                        </span>
                                                        </span>

                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>

                                                    )
                                                })}
                                            </div>
                                            <div className="pt-2 mt-2 border-top">
                                                <div className="d-flex justify-content-between">
                                                    <span><Trans i18nKey='RoomList_22'>Łączna liczba osób</Trans></span>
                                                    <span className="">{this.state.howManyPeople}</span>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span><Trans i18nKey='RoomList_23'>Łączna liczba nocy</Trans></span>
                                                    <span className="">{this.state.nights}</span>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span className="fw-bold"><Trans i18nKey='RoomList_24'>Łącznie do zapłaty</Trans></span>
                                                    <span className="fw-bold">
                                        {this.state.cartRooms.length === 0 ? <>0
                                            zł</> : <>{this.state.amountToPay * this.state.nights} zł</>}
                                    </span>
                                                </div>

                                                <div className="mt-2">
                                                    <Button
                                                        disabled={this.state.howManyPeople === 0 || this.state.cartRooms.length === 0 ||
                                                            (this.state.numberOfPeople > this.state.howManyPeople)}
                                                        variant={this.state.howManyPeople === 0 || this.state.cartRooms.length === 0 ||
                                                        (this.state.numberOfPeople > this.state.howManyPeople) ? "warning" : "success"}
                                                        size="lg"
                                                        className="col-12"
                                                        onClick={this.goToForm}
                                                    >
                                                        {this.state.numberOfPeople > this.state.howManyPeople ? <><Trans
                                                                i18nKey='RoomList_26'>Minimalna
                                                                liczba osób</Trans>: {this.state.numberOfPeople}</> :
                                                            <Trans i18nKey='RoomList_25'>Przejdź do
                                                                podsumowania</Trans>}
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div
                                    className="border-0 box-rounded-sm map-widget bg-white py-4 mt-3 d-flex justify-content-center align-items-center">
                                    <Button
                                        target="_blank"
                                        href="https://www.google.com/maps/place/Schronisko+PTTK+Markowe+Szczawiny/@49.5901254,19.472797,12.19z/data=!4m8!3m7!1s0x0:0xea25d06bb53d3456!5m2!4m1!1i2!8m2!3d49.5878044!4d19.5167022?hl=pl">
                                        <Trans i18nKey='Footer_4'>Zobacz lokalizacje</Trans>
                                    </Button>
                                </div>
                            </div>
                        </div>


                        <Modal dialogClassName="modal-room-info" show={this.state.isOpen} onHide={this.closeModal}>
                            {/*<img src={this.state.roomUrl} alt="room" className="img-cover"/>*/}
                            <Modal.Header closeButton>
                                <span className="text-black">
                                    Dostępność pokoju (min 1 miejsce wolne)
                                    <br/>
                                    <span className="fw-bold">{this.state.description}</span>
                                </span>
                            </Modal.Header>
                            <RoomAvailableDate id={this.state.roomId}/>
                        </Modal>
                    </div>
                </Container>

                <Modal show={this.state.infoModal} onHide={this.closeInfoModal}>
                    <Modal.Header className="text-center">
                        <Modal.Title><GiMountainClimbing style={{fontSize: "50px"}}/><Trans i18nKey='RoomList_6'>Ważne
                            informacje</Trans></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <p>
                            <Trans i18nKey='RoomList_1'>Został panu/pani przedstawiony najbardziej optymalny wybór pokoi
                                dla </Trans>
                            {this.state.numberOfPeople} {this.state.numberOfPeople === 1 ? this.props.t("osoby") : this.props.t("osob")}.
                        </p>
                        <p>
                            <Trans i18nKey='RoomList_2'>Oczywiście nic nie stoi na przeszkodzie by zakwaterować inną,
                                lecz nie mniejszą liczbę osób niż</Trans>
                            <> </>
                            {this.state.numberOfPeople}.
                            <Trans i18nKey='RoomList_3'> Przedstawione pokoje są jednak odpowiednie pasujące dla tej
                                ilości</Trans>
                        </p>


                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="outline-success" className="btn-xxl text-center" onClick={() => {
                            this.closeInfoModal();
                            this.setState({
                                cartModal: true
                            })
                        }}>
                            <Trans i18nKey='RoomList_7'>Zrozumiałem</Trans>
                        </Button>
                    </Modal.Footer>
                </Modal>

                <MobileBottomMenu
                    component={
                        <Cart cartRooms={this.state.cartRooms} removeRoomFromCart={this.removeRoomFromCart}
                              addPeopleToRoom={this.addPeopleToRoom} howManyPeople={this.state.howManyPeople}
                              amountToPay={this.state.amountToPay} nights={this.state.nights}
                              numberOfPeople={this.state.numberOfPeople} goToForm={this.goToForm}
                              type={this.state.type}
                        />
                    }
                />
            </section>
        );
    }
}

export default withTranslation()(RoomListComponent);
