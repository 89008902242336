import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {FaFacebook} from "react-icons/all";
import {Trans} from "react-i18next";

const FooterComponent = () => {
    return (
        <footer className="text-start text-black color-brand-secondary-bg border-top">
            <Container className="py-5">
                <Row className="text-left" as="address">
                    <Col md={6} xs={12}>
                        <h3 className="fw-bold"><Trans i18nKey="Footer_1">Kontakt</Trans></h3>
                        <ul className="list-unstyled mb-4">
                            <li>
                                <a href="tel:338775105">33 877 51 05</a>
                            </li>
                            <li>
                                <a href="tel:+48604527417">+48 604 527 417</a> • <Trans i18nKey="Footer_2">jak nie odbieramy można wysłać
                                SMS</Trans>
                            </li>
                            <li>
                                <a href="mailto:markowe.szczawiny@op.pl">markowe.szczawiny@op.pl</a>
                            </li>
                        </ul>
                        <a
                            rel="noreferrer"
                            href="https://www.facebook.com/Schronisko-G%C3%B3rskie-PTTK-Markowe-Szczawiny-223432244393822/"
                            target="_blank">
                            <FaFacebook className="h1"/>
                        </a>
                    </Col>

                    <Col md={6} xs={12}>
                        <h3 className="fw-bold"><Trans i18nKey="Footer_3">Dane adresowe</Trans></h3>

                        <p>
                            34-222 Zawoja
                            <br/>
                            Babiogórski Park Narodowy
                        </p>

                        <div
                            className="col-12 col-md-8 col-lg-6 border-0 box-rounded-sm map-widget bg-white py-4 my-4 d-flex justify-content-center align-items-center">
                            <Button
                                target="_blank"
                                href="https://www.google.com/maps/place/Schronisko+PTTK+Markowe+Szczawiny/@49.5901254,19.472797,12.19z/data=!4m8!3m7!1s0x0:0xea25d06bb53d3456!5m2!4m1!1i2!8m2!3d49.5878044!4d19.5167022?hl=pl">
                                <Trans i18nKey="Footer_4">Zobacz lokalizacje</Trans>
                            </Button>
                        </div>

                    </Col>
                </Row>
            </Container>
            <div className="d-flex flex-column copyright pb-5 pt-4 py-md-4 border-top mb-5 mb-md-0">
                <Container>
                    <div className="d-flex justify-content-between text-black text-nowrap">
                        <div className="d-none" itemScope itemType="https://schema.org/WebApplication">
                            <span itemProp="name">System rezerwacji online</span>
                            <span itemProp="applicationSuite">TraveLynx</span>
                            <span itemProp="browserRequirements">requires HTML5 support</span>
                        </div>

                        <div className="d-flex gap-3 flex-wrap">
                        <span>
                            <a target="_blank" href="/media/Polityka-prywatnosci.pdf">
                                <Trans i18nKey="Footer_5">Polityka prywatności</Trans>
                            </a>
                        </span>
                            <span>
                            <a target="_blank" href="/media/Regulamin-Świadczenia-Usług.pdf">
                                <Trans i18nKey="Footer_6">Regulamin świadczenia usług</Trans>
                            </a>
                        </span>
                            <span>
                            <a target="_blank" href="/media/Regulamin-Schroniska-PTTK.pdf">
                                <Trans i18nKey="Footer_7">Regulamin schroniska</Trans>
                            </a>
                        </span>
                        </div>

                        <div>
                            © {new Date().getFullYear()} TraveLynx
                        </div>
                    </div>
                </Container>
            </div>
        </footer>
    );
}

export default FooterComponent;
