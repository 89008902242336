import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Nav, Row} from "react-bootstrap";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import RoomService from "../../service/RoomService";
import ReactExport from "react-data-export";
import overlayFactory from "react-bootstrap-table2-overlay";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const Overloading = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [rooms, setRooms] = useState([]);
    const [allRooms, setAllRooms] = useState([]);
    const [roomsTemp, setRoomsTemp] = useState([]);
    const [maxV, setMaxV] = useState();
    const [name, setName] = useState();
    const [number, setNumber] = useState();
    const [providedDate, setProvidedDate] = useState(moment().format("YYYY-MM-DD"));
    const [excelButton, setExcelButton] = useState({
        text: "Eksportuj do EXCEL",
        clicked: false,
    })
    const [isOwner, setIsOwner] = useState(true)


    useEffect(() => {
        setIsLoading(true)
        RoomService.getOverloading({providedDate: providedDate}).then(response => {
            setRooms(response.data.sort((a, b) => (a.roomNumber > b.roomNumber) ? 1 : ((b.roomNumber > a.roomNumber) ? -1 : 0)))
            setRoomsTemp(response.data.filter((item) => item.roomNumber === response.data[0].roomNumber))
            setIsLoading(false)
        }).catch((error) => {
            if (error.response.status === 403) setIsOwner(false)
            console.log(error.response.status)
        })

        RoomService.getAllRooms().then(res => {
            setAllRooms(res.data.sort((a, b) => (a.roomNumber > b.roomNumber) ? 1 : ((b.roomNumber > a.roomNumber) ? -1 : 0)))
        })

    }, [providedDate])

    const toExcel = () => {
        RoomService.exportToExcel(rooms).then(response => response.statusText);
        setExcelButton({
            text: "Eksportowano",
            clicked: true,
        })
    }

    const columns = [
        // {
        //     dataField: "roomNumber",
        //     text: "Numer pokoju",
        //     style: {
        //         textAlign: 'center',
        //     },
        //     formatter: (cellContent, row, rowIndex) => (
        //         <span className="text-center w-100"><strong>{cellContent}</strong></span>
        //     ),
        // },
        {
            dataField: "name",
            text: "Gość",
            footer: ''
        },
        {
            dataField: "phoneNumber",
            text: "Telefon",
            footer: ''
        },
        {
            dataField: "reservationDayStart",
            text: "Przyjazd",
            footer: ''
        },
        {
            dataField: "reservationDayEnd",
            text: "Odjazd",
            footer: ''
        },
        {
            dataField: "alreadyPaid",
            text: "Zadatek",
            footer: ''
        },
        {
            dataField: "numberOfPeople",
            text: "Ilość osób",
            formatter: (cellContent, row) => {
                setMaxV(row.forHowManyPeople);
                setName(row.description);
                setNumber(row.roomNumber);
                console.log(row.roomNumber)
                return <>{cellContent} ({Math.floor((cellContent / row.forHowManyPeople) * 100)} %)</>
            },
            footer: columnData => {
                const total = columnData.reduce((acc, item) => acc + item, 0);
                return (
                    <>
                        {roomsTemp.length !== 0 ? <>{total}/{maxV} ({Math.floor((total / maxV) * 100)} %)</> : "-"}
                    </>);
            },
            footerClasses: 'table-info'
        },

    ];


    const unique = [...new Set(rooms.map(item => item.roomNumber))]

    const handleRoomChange = (id) => {
        const filteredRooms = rooms.filter((item) => {
            return item.roomNumber === id;
        })
        setRoomsTemp(filteredRooms)
    }

    const sum = (nr) => {
        return rooms.filter((item) => item.roomNumber === nr).map(i => i.numberOfPeople).reduce((x, y) => x + y, 0)
    }

    const multiDataSet = [
        {
            columns: [
                {
                    title: providedDate,
                    width: {wpx: 120},
                    style: {font: {sz: "18", bold: true}}
                },
                {
                    title: "Nr",
                    width: {wpx: 20},
                    style: {fill: {fgColor: {rgb: "d9d9d9"}}}
                },
                {
                    title: "Ilość osób",
                    width: {wpx: 60},
                    style: {fill: {fgColor: {rgb: "d9d9d9"}}}
                },
                {
                    title: "Imię i Nazwisko",
                    width: {wpx: 150},
                    style: {fill: {fgColor: {rgb: "d9d9d9"}}}
                },
                {
                    title: "Nr telefonu",
                    width: {wpx: 90},
                    style: {fill: {fgColor: {rgb: "d9d9d9"}}}
                },
                {
                    title: "Zadatek",
                    width: {wpx: 60},
                    style: {fill: {fgColor: {rgb: "d9d9d9"}}}
                },
                {
                    title: "Stan",
                    width: {wpx: 40},
                    style: {fill: {fgColor: {rgb: "d9d9d9"}}}
                },

            ],
            data:
                allRooms.map(nr => (
                    [
                        {
                            value: " ",
                            style: {
                                alignment: {vertical: "top"},
                            }

                        },
                        {
                            value: nr.roomNumber,
                            style: {
                                alignment: {vertical: "top"},
                                border: {
                                    top: {style: "thin", color: {auto: 1}},
                                    bottom: {style: "thin", color: {auto: 1}},
                                    left: {style: "thin", color: {auto: 1}},
                                    right: {style: "thin", color: {auto: 1}},
                                },
                            }

                        },
                        {
                            value:
                                `${rooms.filter((item) => item.roomNumber === nr.roomNumber).map(i => i.numberOfPeople).join('\r\n')}`,
                            style: {
                                alignment: {wrapText: true, horizontal: "center"},
                                border: {
                                    top: {style: "thin", color: {auto: 1}},
                                    bottom: {style: "thin", color: {auto: 1}},
                                    left: {style: "thin", color: {auto: 1}},
                                    right: {style: "thin", color: {auto: 1}},
                                },
                            }
                        },
                        {
                            value:
                                `${rooms.filter((item) => item.roomNumber === nr.roomNumber).map(i => i.name).join('\r\n')}`,

                            style: {
                                alignment: {wrapText: true},
                                border: {
                                    top: {style: "thin", color: {auto: 1}},
                                    bottom: {style: "thin", color: {auto: 1}},
                                    left: {style: "thin", color: {auto: 1}},
                                    right: {style: "thin", color: {auto: 1}},
                                },
                            }
                        },
                        {
                            value:
                                `${rooms.filter((item) => item.roomNumber === nr.roomNumber).map(i => i.phoneNumber).join('\r\n')}`,
                            style: {
                                alignment: {wrapText: true},
                                border: {
                                    top: {style: "thin", color: {auto: 1}},
                                    bottom: {style: "thin", color: {auto: 1}},
                                    left: {style: "thin", color: {auto: 1}},
                                    right: {style: "thin", color: {auto: 1}},
                                },
                            }
                        },
                        {
                            value:
                                `${rooms.filter((item) => item.roomNumber === nr.roomNumber).map(i => i.alreadyPaid).join('\r\n')}`,
                            style: {
                                alignment: {wrapText: true},
                                border: {
                                    top: {style: "thin", color: {auto: 1}},
                                    bottom: {style: "thin", color: {auto: 1}},
                                    left: {style: "thin", color: {auto: 1}},
                                    right: {style: "thin", color: {auto: 1}},
                                },
                            }
                        },
                        {
                            value:
                                `${sum(nr.roomNumber)} / ${nr.forHowManyPeople}`,
                            style: {
                                alignment: {wrapText: true},
                                border: {
                                    top: {style: "thin", color: {auto: 1}},
                                    bottom: {style: "thin", color: {auto: 1}},
                                    left: {style: "thin", color: {auto: 1}},
                                    right: {style: "thin", color: {auto: 1}},
                                },
                            }
                        },

                    ]
                ))
        }
    ];


    return (
        <div className="container-fluid overloading">
            <Row>
                <Col>
                    <div className="admin-panel-title mb-4">Stan w pokojach</div>
                </Col>
            </Row>
            <div className="card-admin p-4">
                <Row>
                    <Col>
                        {/*<Button onClick={toExcel} disabled={excelButton.clicked}>{excelButton.text}</Button>*/}
                        <ExcelFile filename="Przeładowanie"
                                   element={<Button disabled={unique.length === 0}>Eksportuj do EXCEL</Button>}>
                            <ExcelSheet dataSet={multiDataSet} name={providedDate}/>
                        </ExcelFile>
                    </Col>
                    <Col>
                        <Form className="d-flex float-end">
                            <input
                                type="date"
                                id="selectDateAdmin"
                                placeholder="Data"
                                className="form-control buttons-lp me-2"
                                name="providedDate"
                                defaultValue={providedDate}
                                onChange={(e) => {
                                    setProvidedDate(e.target.value);
                                    setExcelButton({clicked: false, text: "Eksportuj do EXCEL"})
                                }}
                            />
                        </Form>
                    </Col>
                </Row>
                <div className="mt-3">
                    <Nav variant="tabs" defaultActiveKey={unique[0]}>
                        {unique.map((room, key) => {
                            return (
                                <Nav.Item key={key}>
                                    <Nav.Link className="" onClick={() => handleRoomChange(room)}
                                              eventKey={room}
                                              active={room === number}>nr <strong>{room}</strong></Nav.Link>
                                </Nav.Item>
                            )
                        })}
                    </Nav>

                    <BootstrapTable bootstrap4 keyField='rowIndex' data={roomsTemp} columns={columns}
                                    bordered={false}
                                    // noDataIndication={isOwner ? "Brak gości w podanym terminie" : "Brak uprawnień"}
                                    noDataIndication={isLoading === false ?
                                        "Brak gości na schronisku w podanym terminie " + providedDate :
                                        <div className="my-5"/>}
                                    loading={isLoading}
                                    overlay={overlayFactory({
                                        spinner: true,
                                        styles: {
                                            overlay: (base) => ({
                                                ...base,
                                                background: 'rgba(30, 40, 86, 0.5)'
                                            })
                                        }
                                    })}
                    />
                    <strong>
                        {roomsTemp.length !== 0 ? <>{name} nr {number}</> : ""}
                    </strong>
                </div>
            </div>

        </div>
    );
}

export default Overloading;