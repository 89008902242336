import React, {useState} from 'react';
import DeleteAmenityModal from "./DeleteAmenityModal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import {AmenitiesDTO} from "../../../../interfaces/settings.interface";
import SettingsService from "../../../../service/SettingsService";
import useToastContext from "../../../nativeComponents/useToastContext";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";


interface Props extends AmenitiesDTO {
    children: any;
}


const EditAmenityModal = ({uuid, name, description, children}: Props) => {

    const [show, setShow] = useState<boolean>(false);
    const [field, setField] = useState<AmenitiesDTO>({uuid, name, description});

    const addToast = useToastContext();

    const setClose = () => setShow(false);

    const handleFieldChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        setField({...field, [name]: value});
    }

    const queryClient = useQueryClient();

    const {mutate, isLoading} = useMutation({
        mutationFn: SettingsService.updateAmenity,
        onSuccess: () => {
            addToast({text: "Operacja przebiegła pomyślnie!", variant: true});
            setShow(false);
            queryClient.invalidateQueries(['settingsAmenitiesAdmin']);
        },
        onError: (error: AxiosError) => {
            addToast({text: "Nie udało się wykonać operacji!", variant: false});
        }
    });

    const handleAddField = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        mutate(field)
    };

    return (
        <>
            <Button onClick={() => setShow(true)} variant="dark" className="fw-bold">
                {children}
            </Button>

            <>
                <Modal closeButton={true} show={show} onHide={setClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edycja</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleAddField}>
                        <Modal.Body>
                            <div className="d-grid gap-3">
                                <Form.Group>
                                    <Form.Label className="mb-2">Udogodnienie</Form.Label>
                                    <Form.Control value={field.name} name="name" onChange={handleFieldChange} placeholder="Nazwa"/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="mb-2">Opis</Form.Label>
                                    <Form.Control as="textarea" value={field.description} name="description" onChange={handleFieldChange}
                                              rows={6}
                                              placeholder="Tu wpisz opis..."/>
                                </Form.Group>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                                <DeleteAmenityModal onClose={setClose} uuid={uuid as string}/>
                                <Button disabled={isLoading || field.description.length < 1}
                                        type="submit">Zmień</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>

        </>
    );
};

export default EditAmenityModal;