import i18next from 'i18next';
import {initReactI18next} from "react-i18next";

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'pl',
        resources: {
            pl: {
                translation: {
                    lang: 'pl',
                    noc: 'noc',
                    noce: 'noce',
                    nocy: 'nocy',
                    pokoj: 'pokój',
                    pokoje: 'pokoje',
                    pokoi: 'pokoi',
                    osoba: 'osoba',
                    osoby: 'osoby',
                    osob: 'osób',
                    'na wyłączność': 'na wyłączność',
                    'na miejsca': 'na miejsca',

                    DatePickerHooks_1: "Wybierz dzień przyjazdu",
                    DatePickerHooks_2: "Wybierz dzień wyjazdu",
                    DatePickerHooks_3: "Rezerwacja możliwa tylko do godziny 15:00",

                    SearchBar_11: "Zmień",
                    SearchBar_2: "Wybierz ilość osób",
                    SearchBar_3: "Wybierz czas pobytu",
                    SearchBar_5: "Zameldowanie",
                    SearchBar_6: "Wymeldowanie",

                    ProgressBar_1: 'Wybór',
                    ProgressBar_2: 'Szczegóły',
                    ProgressBar_3: 'Płatność',
                    ProgressBar_4: 'Potwierdzenie',

                    ResComp_28: 'Chcę otrzymać fakturę',
                    ResComp_42: 'Wprowadź numer - 9 cyfr bez +48',
                }
            },
            en: {
                translation: {
                    lang: 'en',
                    noc: 'night',
                    noce: 'nights',
                    nocy: 'nights',
                    pokoj: 'room',
                    pokoje: 'rooms',
                    pokoi: 'rooms',
                    osoba: 'person',
                    osoby: 'people',
                    osob: 'people',
                    checkin: 'Check-in',
                    checkout: 'Check-out',
                    'na wyłączność': 'exclusively',
                    'na miejsca': 'spot in rooms',
                    'Liczba miejsc': 'spots',
                    'Liczba wolnych miejsc': 'free spots',
                    'Numer pokoju': 'room number',

                    'Pokój ośmioosobowy': 'Room for eight people',
                    'Pokój sześcioosobowy': 'Room for six people',
                    'Pokój siedmioosobowy': 'Room for seven people',
                    'Pokój dwunastoosobowy': 'Room for twelve people',
                    'Pokój szesnastoosobowy': 'Room for sixteen people',
                    'Pokój czteroosobowy z łazienką': 'Room for four people en suite',
                    'Pokój pięcioosobowy (STUDIO)': 'Room for five people (studio)',
                    'Pokój trzyosobowy z łazienka (jedno łóżko podwójne)': 'Triple room en suite (one bunk bed)',
                    'Pokój dwuosobowy z łazienką': 'Double room en suite',
                    'Pokój czteroosobowy': 'Room for four people',
                    'Pokój sześcioosobowy (STUDIO)': 'Room for six people (studio)',
                    'Pokój siedemnastoosobowy na poddaszu': 'Attic room four seventeen people',

                    WelcomeWindow_h1: "Mountain Hostel PTTK Markowe Szczawiny",
                    WelcomeWindow_h2: "Your safe haven in Beskid Mountains",

                    SearchBar_1: "Person count",
                    SearchBar_2: "choose the number of people",
                    SearchBar_3: "length of stay",
                    SearchBar_4: "search",
                    SearchBar_5: "Check-in",
                    SearchBar_6: "Check-out",
                    SearchBar_10: "Hello, when would you like to arrive?",
                    SearchBar_11: "select",
                    SearchBar_12: "change",

                    DatePickerHooks_1: "Please select your arrival date",
                    DatePickerHooks_2: "Please select a departure day",
                    DatePickerHooks_3: "Reservations possible only until 12:00 noon",

                    GuestPicker_1: "Person count",
                    GuestPicker_2: "Rooms are searched based on this number of people. Of course, it is possible to rent a larger room.",

                    Navbar_1: "Home Page",
                    Navbar_2: "News",

                    Navbar_profile: "Sign in",

                    Footer_1: "Contact",
                    Footer_2: "In case we do not answer, you could send text message",
                    Footer_3: "Your address info:",
                    Footer_4: "check location",
                    Footer_5: "privacy policy",
                    Footer_6: "terms of service",
                    Footer_7: "The rules of our Hostel",

                    RoomList_1: 'You’ve been presented with the most optimal choice of rooms for ',
                    RoomList_2: 'Of course, nothing stands in your way to accommodate another amount of people. However, it should not be less than ',
                    RoomList_3: ' The rooms presented to you are proper for the chosen amount of guests.',
                    RoomList_6: 'Important',
                    RoomList_7: 'Understood',
                    RoomList_8: 'It is possible to book the whole room',
                    RoomList_9: 'Search results from',
                    RoomList_10: 'Found',
                    RoomList_11: 'book a room',
                    RoomList_12: 'book a spot',
                    RoomList_13: 'In cart',
                    RoomList_14: 'pp',
                    RoomList_15: 'in total',
                    RoomList_16: 'Your reservation',
                    RoomList_17: 'Choose your room and proceed to final details',
                    RoomList_18: 'Your cart is empty',
                    RoomList_19: 'Add a room and make reservation',
                    RoomList_22: 'Total amount of people',
                    RoomList_23: 'Total amount of nights',
                    RoomList_24: 'Total amount to pay',
                    RoomList_25: 'Final details',
                    RoomList_26: 'Minimum number of people',
                    RoomList_27: 'Sorry, for the indicated date of',
                    RoomList_28: 'we do not have available accommodation, please feel free to contact the hostel or choose another date.',
                    RoomList_29: 'Cart',
                    RoomList_30: 'Add more rooms',

                    RoomDetails_1: 'Room details',
                    RoomDetails_2: 'Availability',
                    RoomDetails_3: 'pp',
                    RoomDetails_4: 'in total',
                    RoomDetails_5: 'book a room',
                    RoomDetails_6: 'book a spot',
                    RoomDetails_7: 'Check cart',
                    RoomDetails_8: 'In cart',

                    ProgressBar_1: 'Choice',
                    ProgressBar_2: 'Details',
                    ProgressBar_3: 'Payment',
                    ProgressBar_4: 'Confirmation',

                    StickyTabNav_1: 'Choice',
                    StickyTabNav_2: 'Amenities',
                    StickyTabNav_3: 'Important',
                    StickyTabNav_4: 'Amenities',
                    StickyTabNav_5: 'Your details',

                    ResComp_1: 'Details of reservation',
                    ResComp_2: 'Chosen rooms',
                    ResComp_3: 'Amenities',
                    ResComp_4: 'free wifi',
                    ResComp_5: 'Kitchen',
                    ResComp_6: 'The kitchen is available from 8 am to 8 pm',
                    ResComp_7: 'Play room',
                    ResComp_8: 'On the first floor there is a play play room with board games as well as books for interested people',
                    ResComp_9: 'Equipment rental',
                    ResComp_10: 'There is an extra paid equipment rental. For more info you may check this link: ',
                    ResComp_11: 'Important',
                    ResComp_12: 'Editing your reservation is possible up to three weeks prior to arrival. <1>Advance payment made for reservation purpose is non-returnable. In general, we do not return advance payment. Unpleasant weather is not a valid reason to return your advance payment. We kindly advise you to book with caution.</1>',
                    ResComp_13: 'Reservation starts at 3pm o’clock and it lasts until 10am the next day. However, we may offer you to store your equipment before 3pm. <1>The check-in desk is available 8am – 8pm. If you plan a later arrival, we will kindly ask you for a call or an e-mail.</1>',
                    ResComp_14: 'Discounts are applied at check-in desk if you show proper documents. You will find accepted discounts at this link',
                    ResComp_15: 'Book-in calendar',
                    ResComp_16: 'In case of questions, feel free to contact us by phone or contact form that you can find at this <1>link</1>.',
                    ResComp_17: 'Amenities',
                    ResComp_18: 'Pick your amenities',
                    ResComp_19: 'Towels',
                    ResComp_20: 'Sheets',
                    ResComp_21: 'Your details',
                    ResComp_22: 'First name',
                    ResComp_23: 'Last name',
                    ResComp_24: 'Amount of people',
                    ResComp_25: 'Phone',
                    ResComp_26: 'E-mail',
                    ResComp_27: 'Additiona info <1>(optional)</1>',
                    ResComp_28: 'I want to get invoice',
                    ResComp_29: 'Check-in',
                    ResComp_30: 'Check-out',
                    ResComp_31: 'Length of stay',
                    ResComp_32: 'Amount of people',
                    ResComp_33: 'Total',
                    ResComp_34: 'Half of the total amount (deposit). The other half is to be paid at the shelter.',
                    ResComp_35: 'For foreign payments, please contact us directly.',
                    ResComp_36: 'I accept <1>rules of Hostel</1>',
                    ResComp_37: 'Proceed to payment',
                    ResComp_38: 'Proceeding to payment...',
                    ResComp_39: 'I am obligated to pay.',
                    ResComp_40: 'A confirmation of the reservation will be sent to the email address.',
                    ResComp_41: 'For example, you can specify the planned time of arrival.',
                    ResComp_42: 'Enter a number - 9 digits',
                    ResComp_43: 'Check-in time starts from 3:00 pm',
                    ResComp_44: 'Check-in ends at 10:00 am',
                    ResComp_45: 'Do you need more rooms?',
                    ResComp_46: 'Add more',
                    ResComp_47: 'Payment',


                    ReservationSuccess_1: 'Dear ',
                    ReservationSuccess_2: 'Congratulations! Your reservation has been successful. We hope your stay will be pleasant. Reservation confirmation was sent to your email.',
                    ReservationSuccess_3: 'Please see the details.',
                    ReservationSuccess_4: 'Travel period.',
                    ReservationSuccess_5: 'From',
                    ReservationSuccess_6: 'to',
                    ReservationSuccess_7: 'Book-in calendar',
                    ReservationSuccess_8: 'You choose ',
                    ReservationSuccess_9: 'Rooms',
                    ReservationSuccess_10: 'room number',
                    ReservationSuccess_11: 'total',
                    ReservationSuccess_12: 'Final details',
                    ReservationSuccess_13: 'Price',
                    ReservationSuccess_14: 'Advance payment',
                    ReservationSuccess_15: 'Amount to pay at the Hostel',
                    ReservationSuccess_16: 'Important info regarding your reservation',
                    ReservationSuccess_17: 'Thank you for your reservation at our place. See the disclosure requirements below.',
                    ReservationSuccess_18: 'Important',
                    ReservationSuccess_19: 'Editing your reservation is possible up to one week prior to arrival. In case  of the amount to pay is very high, please contact us through phone or email.',
                    ReservationSuccess_20: 'Advance payment made for reservation purpose is non-returnable. In general, we do not return advance payment. Unpleasant weather is not a valid reason to return your advance payment. We kindly advise you to book with caution.',
                    ReservationSuccess_21: 'Reservation starts at 1pm o’clock and it lasts until 10am the next day. However, we may offer you to store your equipment before 3pm.',
                    ReservationSuccess_22: 'The check-in desk is available 8am – 8pm. If you plan a later arrival, we will kindly ask you for a call or an e-mail.',
                    ReservationSuccess_23: 'Discounts are applied at check-in desk if you show proper documents. You will find accepted discounts at this link:',
                }
            }
        }
    })
