import React from 'react';
import {Redirect, Route} from "react-router-dom";
import AuthService from "../../service/AuthService";

const ProtectedRoute = ({component: Component, ...rest}) => {

    const user = AuthService.getCurrentUser();

    return (
        <Route
            {...rest}
            render={props => {
                if (user) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to={{
                        pathname: "/",
                        state: {
                            from: props.location
                        }
                    }}/>
                }
            }}
        />
    );
};

export default ProtectedRoute;