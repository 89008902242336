import React, {useState} from 'react';
import AuthService from "../../service/AuthService";
import {Button, Card, Form} from "react-bootstrap";
import {useFormik} from 'formik';
import * as Yup from "yup";
import {useHistory} from "react-router-dom";

const schema = Yup.object().shape({
    username: Yup
        .string()
        .required('Pole wymagane!'),
    password: Yup
        .string()
        .required('Pole wymagane!'),

});

const Login = (props) => {

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            username: "",
            password: ""
        },

        validationSchema: schema,
        onSubmit: (e) => {
            // e.preventDefault();
            setLoading(true);
            AuthService.login(username, password).then(
                () => {
                    history.push("/admin");
                    // window.location.reload();
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();


                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        },
        validateOnBlur: true

    });

    return (
        <div style={{height: '70vh'}} className="d-flex justify-content-center align-items-center">
            <h1 className="d-none">Schronisko PTTK Markowe Szczawiny | Rezerwacje online</h1>
            <Card style={{width: '26rem'}}>
                <Card.Body>
                    <Card.Title>Zaloguj się</Card.Title>
                    <Card.Text as="div">
                        <Form noValidate onSubmit={formik.handleSubmit}>
                            <Form.Group>
                                <Form.Label htmlFor="username">Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    value={username}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        setUsername(e.target.value);
                                    }}
                                    isInvalid={!!formik.errors.username}
                                    autoFocus
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.username}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mt-2">
                                <Form.Label htmlFor="password">Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        setPassword(e.target.value);
                                    }}
                                    isInvalid={!!formik.errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/*<Card.Link href="#" className="float-end">Przypomnij hasło</Card.Link>*/}
                            <Button
                                type="submit"
                                className="mt-4 btn-block"
                                variant="brand"
                                disabled={loading}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"> </span>
                                )}
                                <span> Login</span>
                            </Button>
                            <p className="text-center text-danger">{message}</p>
                        </Form>

                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}
export default Login;
