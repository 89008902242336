import React, {createContext, useCallback, useEffect, useState} from 'react';
import {Toast, ToastContainer} from "react-bootstrap";

const ToastContext = createContext();

export default ToastContext;

export function ToastContextProvider({children}) {
    const [toasts, setToasts] = useState([]);

    useEffect(() => {
        if (toasts.length > 0) {
            const timer = setTimeout(
                () => setToasts(toasts => toasts.slice(1)),
                3000
            );
            return () => clearTimeout(timer)
        }
    }, [toasts]);

    const addToast = useCallback(
        function (toast) {
            setToasts(toasts => [...toasts, toast]);
        },
        [setToasts]
    );

    return (
        <ToastContext.Provider value={addToast}>
            {children}
            <ToastContainer style={{zIndex: "9999"}} className="position-fixed p-3" position="bottom-center">
                {
                    toasts.map((toast, key) => (
                        <Toast key={key}>
                            <Toast.Header className="py-2" closeButton={false}>
                                <div
                                    style={{height: "20px", width: "20px"}}
                                    className={`rounded me-2 ${toast.variant ? "bg-success" : "bg-danger"}`}
                                />
                                <strong className="me-auto">Markowe Szczawiny</strong>
                                <small className="text-muted">teraz</small>
                            </Toast.Header>
                            <Toast.Body>
                                {toast.text}
                            </Toast.Body>
                        </Toast>
                    ))
                }
            </ToastContainer>
        </ToastContext.Provider>
    );
}