import axios from "axios";
import {ENVIRONMENT} from "../config/environment";
import authHeader from "./AuthHeader";
import getParameters from "./GetParameters";

const API_URL = `${ENVIRONMENT.HOST_ADDRESS}${ENVIRONMENT.PORT}`;

class RoomService {

    getAvailableRooms(reservation) {
        return axios.post(API_URL + "/api/rooms/", reservation)
    }

    getRoomById(roomId) {
        return axios.get(API_URL + "/api/rooms/" + roomId)
    }

    getAllRooms() {
        return axios.get(API_URL + "/api/rooms/")
    }

    getAvailableAdminRooms(reservation) {
        return axios.post(API_URL + "/api/rooms/admin", reservation, {headers: authHeader()})
    }
    getAvailableAdminRooms2(id, reservation) {
        return axios.post(API_URL + "/api/rooms/admin/edition/" + id, reservation, {headers: authHeader()})
    }

    getAvailableTerms(id, date) {
        return axios.post(API_URL + "/api/rooms/date/" + id, date)
    }

    getOverloading(date){
        return axios.post(API_URL + "/api/rooms/admin/overloading", date, { headers: authHeader()})
    }


    setRoomPrice(roomId, price) {
        return axios.patch(API_URL + "/api/rooms/admin/price" + roomId, {}, {
            params: getParameters(price),
            headers: authHeader()
        })
    };

    exportToExcel(guests) {
        return axios.post(API_URL + "/api/rooms/admin/excel", guests, { headers: authHeader()})
    }

    async getBlockedDatesForSpecificRoom(roomId) {
        return await axios.get(API_URL + `/api/rooms/blocked-dates?roomId=${roomId}`, { headers: authHeader()})
    }

    async blockRoomForSpecificDate(data) {
        return await axios.post(API_URL + "/api/rooms/blocked-dates", data, { headers: authHeader()})
    }
    async deleteRoomBlockade(blockadeId) {
        return await axios.delete(API_URL + `/api/rooms/blocked-dates?blockadeId=${blockadeId}`, { headers: authHeader()})
    }

}

export default new RoomService();
