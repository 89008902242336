import React, {Component} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";

import AuthService from "../../service/AuthService";
import AdminPanelService from "../../service/WorkerService";
import {Button} from "react-bootstrap";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                To pole jest wymagane!
            </div>
        );
    }
};

const email = value => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                To nie jest poprawny adres e-mail.
            </div>
        );
    }
};

const vusername = value => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                Login od 3 do 20 liter.
            </div>
        );
    }
};

const vpassword = value => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                Długosć hasła od 6 do 40 liter.
            </div>
        );
    }
};

const vfirstName = value => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                Imię musi być pomiędzy 3, a 20 liter.
            </div>
        );
    }
};

const vLastName = value => {
    if (value.length < 2 || value.length > 30) {
        return (
            <div className="alert alert-danger" role="alert">
                Nazwisko musi być pomiędzy 2, a 30 liter.
            </div>
        );
    }
};


export default class AddEmployeeComponent extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this)

        this.state = {
            username: "",
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            isOwnerAccount: true,
            successful: false,
            message: ""
        };
    }

    componentDidMount() {

    }

    onChangeInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleRegister(e) {
        e.preventDefault();

        this.setState({
            message: "",
            successful: false
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            AdminPanelService.createWorker(
                this.state.username,
                this.state.email,
                this.state.password,
                this.state.firstName,
                this.state.lastName
            ).then(
                response => {
                    this.setState({
                        message: response.data.message,
                        successful: true
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        successful: false,
                        message: resMessage
                    });
                }
            );
        }
    }

    render() {
        const {isOwnerAccount} = this.state;

        return (
           <>
            { isOwnerAccount ? (
                <div className="container card-admin p-4">
                    <p className="title-card">Dodaj pracownika</p>
                        <Form
                            onSubmit={this.handleRegister}
                            ref={c => {
                                this.form = c;
                            }}
                        >
                            {!this.state.successful && (
                                <>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="username">Nazwa użytkownika</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="username"
                                                value={this.state.username}
                                                onChange={this.onChangeInput}
                                                validations={[required, vusername]}
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label htmlFor="email">Email</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.onChangeInput}
                                                validations={[required, email]}
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label htmlFor="email">Imię</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="firstName"
                                                value={this.state.firstName}
                                                onChange={this.onChangeInput}
                                                validations={[required, vfirstName]}
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label htmlFor="email">Nazwisko</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="lastName"
                                                value={this.state.lastName}
                                                onChange={this.onChangeInput}
                                                validations={[required, vLastName]}
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label htmlFor="password">Hasło</label>
                                            <Input
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.onChangeInput}
                                                validations={[required, vpassword]}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <Button type="submit" variant="primary" className="mr-2">Dodaj</Button>
                                        <Button onClick={this.props.history.goBack} variant="secondary">Wróć</Button>
                                    </div>
                                </>
                            )}

                            {this.state.message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            this.state.successful
                                                ? "alert alert-success"
                                                : "alert alert-danger"
                                        }
                                        role="alert"
                                    >
                                        {this.state.message}
                                    </div>
                                </div>
                            )}
                            <CheckButton
                                style={{display: "none"}}
                                ref={c => {
                                    this.checkBtn = c;
                                }}
                            />
                        </Form>
                </div> ) : null}
           </>
        );
    }
}

