import React, {useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import moment from "moment/moment";
import {format} from "date-fns";
import {pl} from "date-fns/locale";
import PeopleByMonth from "./settings-page/statistics/PeopleByMonth";
import ReservationsByMonth from "./settings-page/statistics/ReservationsByMonth";
const Statistics = () => {
    const [date, setDate] = useState(moment().format("yyyy-MM"));

    const dateFormatter = date ? format(new Date(date), 'LLLL yyyy', {locale: pl}) : '-';

    const yearFormatter = date && format(new Date(date), 'yyyy').toUpperCase();
    const monthFormatter = date && format(new Date(date), 'LLLL').toUpperCase();

    console.log(monthFormatter)

    return (
        <div className="container-fluid">
            <Row>
                <Col>
                    <div className="admin-panel-title mb-4">Statystyki</div>
                </Col>
            </Row>
            <div className="card-admin p-4">
                <Row>
                    <Col>
                        <Form className="d-flex float-end">
                            <input
                                type="month"
                                id="selectDateAdmin"
                                placeholder="Data"
                                className="form-control buttons-lp me-2"
                                name="providedDate"
                                defaultValue={date}
                                onChange={(e) => {
                                    setDate(e.target.value);
                                }}
                            />
                        </Form>
                    </Col>
                </Row>
                <div className="mt-3">
                    <Row className="overview-widget row-cols-lg-5">
                        <PeopleByMonth month={monthFormatter} year={yearFormatter}/>
                        <ReservationsByMonth month={monthFormatter} year={yearFormatter}/>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default Statistics;