import axios from "axios";
import {ENVIRONMENT} from "../config/environment";
import authHeader from "./AuthHeader";
import {AmenitiesDTO, RulesDTO} from "../interfaces/settings.interface";

const API_URL = `${ENVIRONMENT.HOST_ADDRESS}${ENVIRONMENT.PORT}`;

const apiClient = axios.create({
    baseURL: `${API_URL}/api`,
    headers: authHeader(),
});

class SettingsService {


    getRules = async () => {
        const response = await apiClient.get<RulesDTO[]>("/rules");
        // console.log(response.data);
        return response.data;
    }
    createRule = async ({icon, name, description}: RulesDTO) => {
        const response = await apiClient.post("/rules", {
            icon,
            name,
            description
        });
        return response.data;
    }
    updateRule = async ({icon, name, description, uuid}: RulesDTO) => {
        const response = await apiClient.put(`/rules/${uuid}`, {
            icon,
            name,
            description
        });
        return response.data;
    }
    deleteRule = async (uuid: string) => {
        const response = await apiClient.delete(`/rules/${uuid}`);
        return response.data;
    }

    getAmenities = async () => {
        const response = await apiClient.get<AmenitiesDTO[]>("/amenities");
        // console.log(response.data);
        return response.data;
    }
    createAmenity = async ({name, description}: AmenitiesDTO) => {
        const response = await apiClient.post("/amenities", {
            name,
            description
        });
        return response.data;
    }
    updateAmenity = async ({name, description, uuid}: AmenitiesDTO) => {
        const response = await apiClient.put(`/amenities/${uuid}`, {
            name,
            description
        });
        return response.data;
    }
    deleteAmenity = async (uuid: string) => {
        const response = await apiClient.delete(`/amenities/${uuid}`);
        return response.data;
    }

}

export default new SettingsService();
