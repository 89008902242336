import React from 'react';
import Rule from "./Rule";
import AddRuleModal from "./AddRuleModal";
import {RulesDTO} from "../../../../interfaces/settings.interface";
import SettingsService from "../../../../service/SettingsService";
import {Stack} from "react-bootstrap";
import {useQuery} from "@tanstack/react-query";

const Rules = () => {

    const {
        // status,
        isLoading,
        isFetching,
        // isError,
        data,
        error
    } = useQuery<RulesDTO[]>(['settingsRulesAdmin'], async () => {
            return await SettingsService.getRules();
        }
    );


    return (
        <section id="Zasady">
            <div className="d-flex justify-content-between align-items-center mb-4 bg-white py-2">
                <div className="h5 fw-bold mb-1">Zasady</div>
                <div className="d-flex align-items-center gap-3">
                    <AddRuleModal/>
                </div>
            </div>

            <div>
                {
                    !data && <div>Brak dodanych pól. Kliknij na plus, aby dodać nowe pole.</div>
                }
                <Stack gap={3}>
                    {
                        data?.map((rule) => {
                            return (
                                <Rule key={rule.uuid} icon={rule.icon} description={rule.description} name={rule.name}
                                      uuid={rule.uuid}/>
                            )
                        })
                    }
                </Stack>
            </div>
        </section>
    );
};

export default Rules;