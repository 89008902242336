import React, {Component} from 'react';
import ReservationService from "../../service/ReservationService";

class ConfirmPaymentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            firstName: '',
            lastName: '',
            amountToPay: 0,
            amountWithDiscount: 0,
            wasDiscountShowed: '',
            advancePaid: '',
            accommodationPaid: '',
        }
    }

    componentDidMount() {
        ReservationService.getReservationById(this.state.id).then(res => {
            let reservation = res.data
            this.setState({
                firstName: reservation.firstName,
                lastName: reservation.lastName,
                amountToPay: reservation.amountToPay,
                amountWithDiscount: reservation.amountWithDiscount,
                wasDiscountShowed: reservation.wasDiscountShowed,
                advancePaid: reservation.advancePaid,
                accommodationPaid: reservation.accommodationPaid,
                xd: reservation.xd
            })
        })
    }

    sendUpdate = () => {
         let update = {
             wasDiscountShowed: this.state.wasDiscountShowed,
             advancePaid: this.state.advancePaid,
             accommodationPaid: this.state.accommodationPaid,
         }
        ReservationService.confirmPaymentOrDiscount(this.state.id, update).then(res => {
            this.props.history.push(this.props.history.push('/reservations'))
        })

    }

    changeAccommodationPaymentHandler = () => {
        this.setState({
            accommodationPaid: !this.state.accommodationPaid,
        })
    }

    changeDiscountHandler = () => {
        this.setState({
            wasDiscountShowed: !this.state.wasDiscountShowed
        })
    }

    changeAdvancePaymentHandler = () => {
        this.setState({
            advancePaid: !this.state.advancePaid
        })
    }

    change
    render() {
        return (
            <>
                <div className="jumbotron-fluid text-white p-4 container text-center mt-5">
                    <h4>ZATWIERDZENIE REZERWACJI</h4>
                </div>
                <div className="container reservation p-4">
                    <p>
                        Imię: <strong>{this.state.firstName}</strong>
                        <br/>
                        Nazwisko: <strong>{this.state.lastName}</strong>
                    </p>
                    <div className="row">
                        <div className="col">
                            <div>Kwota do zapłaty: <strong>{this.state.amountToPay}</strong> </div>
                            <div>Czy pobyt został opłacony? {this.state.accommodationPaid ? <p><b className="text-success">TAK</b></p> : <p><b className="text-danger">NIE</b></p>}</div>
                            <button className="btn btn-dark btn-sm rounded-0" onClick={this.changeAccommodationPaymentHandler}>{this.state.accommodationPaid ?   "ANULUJ ZAPŁATĘ KWOTY": "AKCEPTUJ ZAPŁATĘ KWOTY"} </button>

                        </div>
                        <div className="col">
                            <div>Zaliczka do zapłaty: <strong>{this.state.amountToPay/2}</strong> </div>
                            <div>Czy zaliczka została opłacona? {this.state.advancePaid ? <p><b className="text-success">TAK</b></p> : <p><b className="text-danger">NIE</b></p>}</div>
                            <button className="btn btn-dark btn-sm rounded-0" onClick={this.changeAdvancePaymentHandler}>{this.state.advancePaid ? "ANULUJ WPŁATE ZALICZKI" : "AKCEPTUJ WPŁATĘ ZALICZKI"}</button>

                        </div>
                        <div className="col">
                            <div>Kwota do zapłaty z rabatem: <strong>{this.state.amountWithDiscount ? this.state.amountWithDiscount : <p>NIE DOTYCZY</p>}</strong></div>
                            {this.state.amountWithDiscount ?
                                <div>
                                <div>Czy zniżka została pokazana {this.state.wasDiscountShowed ? <p className="text-capitalize"><b className="text-success">TAK</b></p> : <p className="text-capitalize"><b className="text-danger">NIE</b></p>}
                                </div>
                                <button className="btn btn-dark btn-sm rounded-0"
                                onClick={this.changeDiscountHandler}>
                                {this.state.wasDiscountShowed ? "ANULUJ ZNIŻKĘ" : "AKCEPTUJ ZNIŻKĘ"}
                                </button>
                                </div>

                            : <p></p>}
                        </div>
                    </div>

                    <br/>
                    <br/>
                        <button onClick={this.sendUpdate} type="submit"
                                className="btn btn-primary btn-lg mr-2">Zapisz zmiany
                        </button>
                     {/*<button onClick={this.sendUpdate}>Zapisz zmiany</button>*/}
                </div>
            </>
        );
    }
}

export default ConfirmPaymentComponent;
