import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from "yup";
import {Alert, Button, Col, Form, Image, Modal, OverlayTrigger, Row, Stack, Tooltip} from "react-bootstrap";
import {Redirect, useHistory} from "react-router-dom";
import AuthService from "../../service/AuthService";
import ReservationService from "../../service/ReservationService";
import {
    AiOutlineInfoCircle,
    AiOutlineQuestionCircle, AiOutlineWarning,
    BiBed,
    BiBlanket,
} from "react-icons/all";
import RoomAvailableDate from "../datepicker/RoomAvailableDate";
import StickyTabNav from "./StickyTabNav";
import CalendarSuccess from "../datepicker/CalendarSuccess";
import {formatDate} from "react-day-picker/moment";
import {Trans, useTranslation} from "react-i18next";
import i18next from "i18next";
import SettingsService from "../../service/SettingsService";
import {ICONS} from "../../utils/IconsMapper";


const schema = Yup.object().shape({
    firstName: Yup
        .string().max(40, "Imię musi składać się maksymalnie z 40 znaków").min(2, "Imię musi składać się przynajmniej z 2 znaków")
        .required('Pole wymagane'),
    lastName: Yup
        .string().max(40, "Nazwisko musi składać się maksymalnie z 40 znaków").min(2, "Nazwisko musi składać się przynajmniej z 2 znaków")
        .required('Pole wymagane'),
    phone: Yup
        .string().length(9, "Błędny numer telefonu").max(9, "Błędny numer telefonu").matches(/^[0-9]+$/, "Tylko cyfry")
        .required("Pole wymagane"),
    email: Yup
        .string().email("Błędny adres e-mail").max(50, "Email musi składać się maksymalnie z 50 znaków").min(5, "Email musi składać się przynajmniej z 5 znaków")
        .required("Pole wymagane"),
    additional: Yup
        .string().max(255),
    terms: Yup.bool().required().oneOf([true], 'Regulamin musi być zaakceptowany'),

});

const ResComp = (props) => {
    const {t} = useTranslation();

    const [rules, setRules] = useState([]);
    const [amenities, setAmenities] = useState([]);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [additional, setAdditional] = useState("");
    const [phone, setPhone] = useState(undefined);
    const [email, setEmail] = useState("");
    const [terms, setTerms] = useState(false);

    const [reservation, setReservation] = useState([]);
    const [cart, setCart] = useState([]);
    const [numberOfPeople, setNumberOfPeople] = useState(null);
    const [toPay, setToPay] = useState(null);


    const [isFormSent, setIsFormSent] = useState(false);


    const [modalShow, setModalShow] = useState(false);
    const [modalId, setModalId] = useState(null);
    const [modalDesc, setModalDesc] = useState("");

    const [modalRoomsShow, setModalRoomsShow] = React.useState(false);
    const [checked, setChecked] = useState({faktura: false, posciel: false, recznik: false});

    const history = useHistory();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);

    useEffect(() => {

        let reservationLocalStorage = JSON.parse(sessionStorage.getItem("reservation"))

        try {
            setReservation(reservationLocalStorage)
            setCart(reservationLocalStorage.cart)
            setNumberOfPeople(reservationLocalStorage.howManyPeople)
            setToPay(reservationLocalStorage.amountToPay * reservationLocalStorage.nights)
        } catch (e) {
            console.log("no data in local storage")
            props.history.push("/")
        } finally {

        }

        SettingsService.getRules().then(response => {
            setRules(response);
        }).catch(e => console.log(e))

        SettingsService.getAmenities().then(response => {
            setAmenities(response);
        }).catch(e => console.log(e))


    }, [])

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: undefined,
            description: '',
            terms: false
        },

        validationSchema: schema,
        onSubmit: (e) => {
            // e.preventDefault();

            setIsFormSent(true);
            console.log("success");

            let amount = 0;
            reservation.cart.forEach((room) => {
                amount += room.details.pricePerPeron * room.numberOfGuests
            })
            let amountToPay = amount * reservation.nights

            let newCart = []
            cart.forEach(room => {
                newCart.push(
                    {
                        room: room.details,
                        numberOfPeople: room.numberOfGuests
                    }
                )
            })
            console.log(newCart)


            let additionalInfo = additional
            if (checked.faktura) {
                additionalInfo += `\n(Chcę otrzymać fakturę)`
            }
            if (checked.posciel) {
                additionalInfo += `\n(Chcę otrzymać posciel)`
            }
            if (checked.recznik) {
                additionalInfo += `\n(Chcę otrzymać recznik)`
            }

            const language = i18next.language

            let pushReservation = {}
            pushReservation = {
                firstName: firstName,
                lastName: lastName,
                howManyPeople: numberOfPeople,
                phoneNumber: phone,
                reservationStart: reservation.reservationDayStart,
                reservationEnd: reservation.reservationDayEnd,
                amountToPay: amountToPay,
                email: email,
                extraInformation: additionalInfo,
                nights: reservation.nights,
                roomProducts: newCart,
                lang: language
            }

            sessionStorage.setItem("reservation", JSON.stringify(pushReservation))
            console.log(pushReservation)

            const user = AuthService.getCurrentUser();
            if (user !== null && (user.roles.includes("ROLE_OWNER") || user.roles.includes("ROLE_WORKER"))) {
                ReservationService.createAdminReservation(pushReservation).then((res) => {
                        history.push("/make-reservation/3")
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        if (resMessage === "There is no space for that number of people") {
                            setShowErrorModal(true)
                        }
                    }
                )
            } else {
                ReservationService.createReservation(pushReservation).then((res) => {

                        window.open(res.data.paymentUrl, "_self")
                        // JEZELI API PLATNOSCI ZAIMPLEMENTOWANE W APCE:
                        // history.push("/make-reservation/2")
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        if (resMessage === "There is no space for that number of people") {
                            setShowErrorModal(true)
                        } else {
                            // console.log(error.response)
                            props.history.push('/make-reservation/2', {
                                code: error.response.data.status,
                                error: error.response.data.error
                            })
                        }

                    }
                )
            }

        },
        validateOnBlur: false,
        validateOnChange: false

    });


    const slider = useRef(null);
    let isDown = useRef(false);

    function CustomCarousel(props) {


        let startX = useRef(null);
        let scrollLeft = useRef(null);

        useEffect(() => {
            if (slider && slider.current) {
                let sliderRef = slider.current;
                sliderRef.addEventListener("mousedown", one);
                sliderRef.addEventListener("mousedown", two);
                sliderRef.addEventListener("mouseleave", three);
                sliderRef.addEventListener("mouseup", four);
                sliderRef.addEventListener("mousemove", five);

                return () => {
                    sliderRef.removeEventListener("mousedown", one);
                    sliderRef.removeEventListener("mousedown", two);
                    sliderRef.removeEventListener("mouseleave", three);
                    sliderRef.removeEventListener("mouseup", four);
                    sliderRef.removeEventListener("mousemove", five);
                };
            }
        }, []);

        function one(e) {
            isDown.current = true;
            startX.current = e.pageX - slider.current.offsetLeft;
            scrollLeft.current = slider.current.scrollLeft;
        }

        function two(e) {
            isDown.current = true;
            startX.current = e.pageX - slider.current.offsetLeft;
            scrollLeft.current = slider.current.scrollLeft;
        }

        function three() {
            isDown.current = false;
        }

        function four() {
            isDown.current = false;
        }

        function five(e) {
            if (!isDown.current) return;
            e.preventDefault();
            const x = e.pageX - slider.current.offsetLeft;
            const walk = x - startX.current;
            slider.current.scrollLeft = scrollLeft.current - walk;
        }


        return (
            <div className="items pb-2" ref={slider}>
                {props.children}
            </div>
        );
    }


    const getIcon = (icon) => {
        const IconComponent = ICONS[icon] || ICONS["BookmarkIcon"]; // Użyj BookmarkIcon jako domyślnej
        return <IconComponent size={20}/>
    }


    return (
        <div className="pb-5 reservation-page">
            {cart === undefined ? <Redirect to="/"/> :
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col lg={8} className="pr-md-5">
                            <div className="reservation mb-4 mt-2">
                                <h1 id="wybor" className="mb-4 fw-bold"><Trans i18nKey='ResComp_1'>Szczegóły
                                    rezerwacji</Trans></h1>

                                <div className="position-relative align-items-center justify-content-end ">
                                    <div className="overflow-hidden">
                                        <Row>
                                            <CustomCarousel>
                                                {cart.map((room, key) => {
                                                    return (
                                                        <Col key={key} xs={12} sm={8} md={6}
                                                             lg={cart.length === 1 ? 8 : cart.length === 2 ? 6 : 4}
                                                             className={cart.length > 2 ? "card-room-info pe-2" : "pe-2"}>
                                                            <div className="card ">
                                                                <Image className="box-rounded-sm img-cover"
                                                                       style={{height: "300px"}}
                                                                       src={"/" + room.details.imgUrl}
                                                                       alt="Room"/>
                                                                <div
                                                                    style={{backgroundImage: "linear-gradient(#ff000000, rgb(0 0 0 / 55%))"}}
                                                                    className="card-img-overlay box-rounded-sm text-white d-flex flex-column justify-content-end">
                                                                    <h6 className="fw-bold m-0">{t(room.details.description)}</h6>
                                                                    <p className="card-text m-0">
                                                                        <span>{t(room.type)} • {room.numberOfGuests} {room.numberOfGuests === 1 ? t("osoba") : room.numberOfGuests < 5 ? t("osoby") : t("osob")}</span>
                                                                    </p>
                                                                    <div className="link d-flex h4 mt-2 fw-normal">
                                                                        <strong
                                                                            className="">{room.details.pricePerPeron * room.numberOfGuests} zł <small
                                                                            className="fw-normal"> / {t("noc")}</small></strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })}
                                                {cart.length < 3 ?
                                                    <div style={{flex: "auto"}} className="col me-1">
                                                        <div
                                                            className="card border h-100 p-4 text-center d-flex justify-content-center align-items-center">
                                                            <div className="mb-3"><Trans i18nKey='ResComp_45'>Potrzebujesz
                                                                więcej pokoi?</Trans></div>
                                                            <Button className="text-nowrap"
                                                                    onClick={() => history.push("/rooms")}><Trans
                                                                i18nKey='ResComp_46'>Dodaj
                                                                więcej</Trans></Button>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </CustomCarousel>
                                        </Row>
                                    </div>
                                    {cart.length > 1 ?
                                        <div onClick={(e) => slider.current.scrollLeft += 300}
                                             className="carousel-rooms right"> {'>'} </div>
                                        : null}
                                    {cart.length > 1 ?
                                        <div onClick={(e) => slider.current.scrollLeft -= 300}
                                             className="carousel-rooms left"> {'<'} </div>
                                        : null}
                                </div>
                                <h4 className="my-3"><Trans i18nKey='ResComp_2'>Wybrane pokoje</Trans></h4>
                                <Stack direction="horizontal" gap={2} className="mb-4">
                                    <p className="py-2 px-3 d-inline-flex box-rounded-sm border border-dark">
                                        <span><strong>{reservation.howManyPeople} </strong>{reservation.howManyPeople === 1 ? t("osoba") : reservation.howManyPeople < 5 ? t("osoby") : t("osob")}</span>
                                    </p>
                                    <p className="py-2 px-3 d-inline-flex box-rounded-sm border border-dark ">
                                        <span><strong>{reservation.nights}</strong> {reservation.nights === 1 ? t("noc") : reservation.nights < 5 ? t("noce") : t("nocy")}</span>
                                    </p>
                                    <p className="py-2 px-3 d-inline-flex box-rounded-sm border border-dark">
                                        <span><strong>{cart.length} </strong>{cart.length === 1 ? t("pokoj") : cart.length < 5 ? t("pokoje") : t("pokoi")}</span>
                                    </p>
                                </Stack>
                            </div>

                            <div className="pt-4 mb-4 reservation">
                                <h4 id="udogodnienia" className="mb-4"><Trans i18nKey='ResComp_3'>Udogodnienia</Trans>
                                </h4>

                                <dl className="row">
                                    {
                                        amenities?.length === 0 && <div>Brak dodanych udogodnień.</div>
                                    }
                                    {
                                        amenities?.map((amenity) => {
                                            return (
                                                <Fragment key={amenity.uuid}>
                                                    <dt className="col-sm-3">{amenity.name}</dt>
                                                    <dd className="col-sm-9">{amenity.description}</dd>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </dl>

                            </div>

                            <div className="pt-4 mb-4 reservation">
                                <h4 id="informacje" className="mb-4"><Trans i18nKey='ResComp_11'>Ważne
                                    informacje</Trans></h4>

                                <div className="d-grid gap-3">
                                    {
                                        rules?.length === 0 && <div>Brak dodanych nformacji.</div>
                                    }
                                    {
                                        rules?.map((rule) => {
                                            return (
                                                <div className="d-flex">
                                                    <h4 className="me-3">{getIcon(rule.icon)}</h4>
                                                    {rule.description}
                                                </div>
                                            )
                                        })
                                    }
                                </div>


                            </div>

                            <div className="pt-4 reservation mb-4">
                                <h4 id=""><Trans i18nKey='ResComp_15'>Kalendarz rezerwacji</Trans></h4>
                                <div>{formatDate(reservation.reservationDayStart, 'LL', t('lang'))} - {formatDate(reservation.reservationDayEnd, 'LL', t('lang'))}</div>
                                <div className="mt-4 d-flex justify-content-center">
                                    <CalendarSuccess arrival={reservation.reservationDayStart}
                                                     departure={reservation.reservationDayEnd}/>
                                </div>
                            </div>

                            <Alert variant="warning" className="border-warning box-rounded-sm">
                                <div className="d-flex">
                                    <h4 className="me-3"><AiOutlineInfoCircle/></h4>
                                    <span>
                                        <Trans i18nKey='ResComp_16'>
                                        W razie jakichkolwiek pytań zapraszamy do kontaktu telefonicznego lub poprzez formularz
                                        kontaktowy znajdujący się pod <Alert.Link target="_blank"
                                                                                  href="https://markoweszczawiny.pttk.pl/kontakt">tym linkiem.</Alert.Link>
                                        </Trans>
                                        <div className="mt-2">
                                        <Alert.Link href="tel:338775105">33 877 51 05 </Alert.Link>
                                            <br/>
                                        <Alert.Link href="tel:+48604527417"> +48 604 527 417 </Alert.Link>
                                        </div>
                                    </span>
                                </div>
                            </Alert>

                            <div className="pt-4 reservation mb-4">
                                <h4 id="dodatki"><Trans i18nKey='ResComp_17'>Dodatki</Trans></h4>
                                <p className="mb-4"><Trans i18nKey='ResComp_18'>Wybierz jakie dodatki chcesz otrzymać na
                                    schronisku</Trans></p>

                                <div className="d-flex align-items-center justify-content-start">
                                    <div style={{width: "50px", height: "50px"}}
                                         className="me-3 border box-rounded-sm color-brand-secondary-bg d-flex align-items-center justify-content-center">
                                        <h5 className="m-0"><BiBlanket/></h5>
                                    </div>
                                    <div className="d-flex">
                                        <div><Trans i18nKey='ResComp_19'>Ręcznik</Trans> - 10zł</div>
                                        <Form.Group>
                                            <Form.Check
                                                onClick={() => {
                                                    setChecked({...checked, recznik: !checked.recznik});
                                                }}
                                                type="switch"
                                                id={`default-checkbox3`}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center justify-content-start mt-3">
                                    <div style={{width: "50px", height: "50px"}}
                                         className="me-3 border box-rounded-sm color-brand-secondary-bg d-flex align-items-center justify-content-center">
                                        <h5 className="m-0"><BiBed/></h5>
                                    </div>
                                    <div className="d-flex">
                                        <div><Trans i18nKey='ResComp_20'>Pościel</Trans> - 18zł</div>
                                        <Form.Group>
                                            <Form.Check
                                                onClick={() => {
                                                    setChecked({...checked, posciel: !checked.posciel});
                                                }}
                                                type="switch"
                                                id={`default-checkbox3`}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                            </div>

                            <div className="pt-4 reservation mb-4">
                                <h4 id="dane" className="mb-4"><Trans i18nKey='ResComp_21'>Twoje dane</Trans></h4>
                                <div className="">
                                    <Row className="mb-3">
                                        <Form.Group as={Col}>
                                            <Form.Label><Trans i18nKey='ResComp_22'>Imię</Trans><span
                                                className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="firstName"
                                                value={firstName}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    setFirstName(e.target.value);
                                                }}
                                                isInvalid={!!formik.errors.firstName}
                                                maxLength="35"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.firstName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label><Trans i18nKey='ResComp_23'>Nazwisko</Trans><span
                                                className="text-danger">*</span></Form.Label>
                                            <Form.Control type="text" name="lastName"
                                                          value={lastName}
                                                          onChange={(e) => {
                                                              formik.handleChange(e);
                                                              setLastName(e.target.value);
                                                          }}
                                                          isInvalid={!!formik.errors.lastName}
                                                          maxLength="35"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.lastName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col}>
                                            <Form.Label><Trans i18nKey='ResComp_24'>Ilość osób</Trans></Form.Label>
                                            <Form.Control
                                                type="number"
                                                readOnly
                                                defaultValue={reservation.howManyPeople}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label><Trans i18nKey='ResComp_25'>Telefon</Trans><span
                                                className="text-danger">*</span></Form.Label>
                                            <Form.Control type="text" name="phone"
                                                          placeholder={t('ResComp_42')}
                                                          value={phone}
                                                          onChange={(e) => {
                                                              formik.handleChange(e);
                                                              setPhone(e.target.value);
                                                          }}
                                                          isInvalid={!!formik.errors.phone}
                                                          maxLength="9"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.phone}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <Trans i18nKey='ResComp_26'>Adres e-mail</Trans><span
                                            className="text-danger">*</span>
                                            <br/>
                                            <small className="fst-italic"><Trans i18nKey='ResComp_40'>Na podany adres
                                                zostanie wysłane
                                                potwierdzenie rezerwacji</Trans></small>
                                        </Form.Label>
                                        <Form.Control type="email" name="email"
                                                      placeholder="np. jan.kowalski@poczta.pl"
                                                      value={email}
                                                      onChange={(e) => {
                                                          formik.handleChange(e);
                                                          setEmail(e.target.value);
                                                      }}
                                                      isInvalid={!!formik.errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            <Trans i18nKey='ResComp_27'>Dodatkowe
                                                informacje <small>(opcjonalnie)</small></Trans>
                                            <br/>
                                            <small className="fst-italic"><Trans i18nKey='ResComp_41'>Można np. podać
                                                planowaną godzinę
                                                przyjazdu</Trans></small>
                                        </Form.Label>
                                        <Form.Check
                                            onClick={() => {
                                                setChecked({...checked, faktura: !checked.faktura});
                                            }}
                                            className="my-2 mx-0"
                                            type={"checkbox"}
                                            id={`default-checkbox-2`}
                                            label={t('ResComp_28')}
                                        />

                                        <Form.Control type="text" as="textarea" rows={3} name="additional"
                                                      value={additional}
                                                      onChange={(e) => {
                                                          formik.handleChange(e);
                                                          setAdditional(e.target.value);
                                                      }}
                                                      isInvalid={!!formik.errors.additional}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.additional}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                        </Col>

                        <Col id="summary" lg={4}>
                            <div style={{top: "100px", zIndex: "0"}}
                                 className="sticky-top border shadow-xl box-rounded-sm bg-white">
                                <div className=" p-4">
                                    <div className="mb-4 border-bottom pb-3">
                                        <div className="row">
                                            <div className="col">
                                                <div><Trans i18nKey='ResComp_29'>Zameldowanie</Trans></div>
                                                <h4 className="mb-0">{reservation.reservationDayStart}</h4>

                                                <span>15:00
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{show: 0, hide: 0}}
                                                overlay={
                                                    <Tooltip>
                                                        <Trans i18nKey='ResComp_43'>Doba rozpoczyna się od godziny
                                                            15:00</Trans>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="ms-1 text-primary"><AiOutlineQuestionCircle/></span>
                                            </OverlayTrigger>
                                        </span>
                                            </div>


                                            <div className="col">
                                                <div><Trans i18nKey='ResComp_30'>Wymeldowanie</Trans></div>
                                                <h4 className="mb-0">{reservation.reservationDayEnd}</h4>
                                                <span>10:00
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{show: 0, hide: 0}}
                                                overlay={
                                                    <Tooltip>
                                                        <Trans i18nKey='ResComp_44'>Doba kończy się o godzinie
                                                            10:00</Trans>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="ms-1 text-primary"><AiOutlineQuestionCircle/></span>
                                            </OverlayTrigger>
                                        </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="mt-3"><Trans i18nKey='ResComp_31'>Długość pobytu</Trans>
                                                </div>
                                                <strong>{reservation.nights} {reservation.nights === 1 ? t("noc") : reservation.nights < 5 ? t("noce") : t("nocy")}</strong>
                                            </div>


                                            <div className="col">
                                                <div className="mt-3"><Trans i18nKey='ResComp_32'>Ilość osób</Trans>
                                                </div>
                                                <strong>{reservation.howManyPeople}</strong>
                                            </div>
                                        </div>
                                    </div>

                                    {cart.map((room, key) => {
                                        return (
                                            <div key={key} className="d-flex justify-content-between">
                                                <span className="pe-2">{t(room.details.description)}</span>
                                                <span
                                                    className="text-nowrap">{room.details.pricePerPeron * room.numberOfGuests * reservation.nights} zł</span>
                                            </div>
                                        )
                                    })}


                                    <div className="d-flex justify-content-between fw-bold mt-4 pt-3 border-top">
                                        <span><Trans i18nKey='ResComp_33'>Razem</Trans><span
                                            className="fw-normal"> ({cart.length} {cart.length === 1 ? t("pokoj") : cart.length < 5 ? t("pokoje") : t("pokoi")} x {reservation.nights} {reservation.nights === 1 ? t("noc") : reservation.nights < 5 ? t("noce") : t("nocy")})</span></span>
                                        <span className="text-primary">{toPay / 2} zł *</span>
                                    </div>
                                    <p className="fst-italic mb-0"><small>*<Trans i18nKey='ResComp_34'>Połowa kwoty
                                        całkowitej (zadatek). Druga
                                        połowa do zapłaty na schronisku.</Trans></small></p>
                                </div>


                                <div className="px-4 pb-4 pt-0">

                                    <Alert variant="danger" className="border-danger box-rounded-sm">
                                        <div className="d-flex">
                                            <h4 className="me-3"><AiOutlineWarning/></h4>
                                            <span>
                                                <small>
                                                    <Trans i18nKey='ResComp_35'>W przypadku płatności zagranicznych, prosimy o bezpośredni kontakt z nami.</Trans>
                                                    <span className="ms-2">
                                                        <Alert.Link href="tel:338775105">33 877 51 05 </Alert.Link>,
                                                        <Alert.Link
                                                            href="tel:+48604527417"> +48 604 527 417 </Alert.Link>
                                                    </span>
                                                    </small>
                                            </span>
                                        </div>
                                    </Alert>
                                    <Form.Group>
                                        <Form.Check
                                            required
                                            feedback={formik.errors.terms}
                                            name="terms"
                                            feedbackType="invalid"
                                            className="my-2 me-1"
                                            type={"checkbox"}
                                            id={`checkbox-terms`}
                                            label={<Trans i18nKey='ResComp_36'>Akceptuję <span
                                                onClick={() => setShowTermsModal(true)}
                                                className="link-primary cursor-pointer">Regulamin schroniska</span></Trans>}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                setTerms(!terms);
                                            }}
                                            isInvalid={!!formik.errors.terms}
                                        />
                                    </Form.Group>

                                    <Button
                                        className="col-12"
                                        size="xxl"
                                        type="submit"
                                        variant="grad"
                                        disabled={!(formik.dirty && !isFormSent)}
                                    >
                                        {isFormSent ? <><span className="spinner-grow spinner-grow-sm mr-2"
                                                              role="status"
                                                              aria-hidden="true"/><Trans i18nKey='ResComp_38'>Przechodzenie
                                            do
                                            płatności...</Trans></> : <Trans i18nKey='ResComp_37'>Przejdź
                                            do płatności</Trans>}
                                        <div><small><small><Trans i18nKey='ResComp_39'>Rezerwuję z obowiązkiem
                                            zapłaty.</Trans></small></small></div>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            }
            <Modal
                dialogClassName="modal-room-info"
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <span className="text-black">
                        Dostępność pokoju (min 1 miejsce wolne)
                        <br/>
                        <span className="fw-bold">{modalDesc}</span>
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <RoomAvailableDate id={modalId}/>
                </Modal.Body>
            </Modal>

            <Modal
                dialogClassName="modal-room-info"
                show={modalRoomsShow}
                onHide={() => setModalRoomsShow(false)}
            >
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalRoomsShow(false)}>Dodaj</Button>
                </Modal.Footer>
            </Modal>

            <div className="d-none d-md-flex"><StickyTabNav/></div>
            <div style={{bottom: "0", left: "0", zIndex: "1040"}}
                 className="position-fixed bg-white border-top w-100 px-4 py-3 d-sm-none d-flex justify-content-between align-items-center">
                <div className="">
                    <span className="text-primary"><strong> {toPay / 2} zł </strong></span>
                    <span>
                        <>
                            ({formatDate(reservation.reservationDayStart, 'MMM', t('lang')) === formatDate(reservation.reservationDayEnd, 'MMM', t('lang')) ? formatDate(reservation.reservationDayStart, 'DD', t('lang')) : formatDate(reservation.reservationDayStart, 'DD MMM', t('lang'))} - {formatDate(reservation.reservationDayEnd, 'DD MMM YY', t('lang'))})
                        </>
                    </span>
                </div>
                <Button onClick={() => {
                    formik.handleSubmit();
                    document.getElementById('dane').scrollIntoView();
                }
                }
                        variant="grad"><Trans i18nKey='ResComp_47'>Zarezerwuj</Trans></Button>
            </div>

            {/*ERROR MODAL*/}
            <Modal
                show={showErrorModal}
                onHide={() => setShowErrorModal(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className="h2 fw-bold">Przykro nam...</span>
                        {/*<span className="h1"><RiEmotionSadLine/></span>*/}

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="">

                    Przepraszamy, ale najwidoczniej w tym czasie ktoś inny rezezerwował ten pokój.
                    Zachęcamy do wyboru innego pokoju :)

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="grad" size="xxl" onClick={() => history.push("/rooms")}>
                        Wróć do listy pokoi
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*TERMS MODAL*/}
            <Modal
                scrollable
                size="xl"
                show={showTermsModal}
                onHide={() => setShowTermsModal(false)}
                backdrop="true"
            >
                <Modal.Header className="p-md-5">
                    <Modal.Title>
                        <span className="h2 fw-bold">
                            Regulamin Schroniska
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-md-5">

                    <strong>1.</strong> Regulamin określa zasady świadczenia usług, odpowiedzialności oraz przebywania
                    na terenie Schroniska. Dokonanie zameldowania jak również dokonanie rezerwacji lub zapłaty zadatku
                    traktuje się jako integralną część umowy. Dokonując ww. czynności, Gość potwierdza, iż zapoznał się
                    i akceptuje warunki regulaminu.
                    <br/>
                    <br/>
                    <strong>2.</strong> Regulamin dotyczy wszystkich Gości przebywających na terenie Schroniska PTTK
                    Markowe Szczawiny
                    <br/>
                    <br/>
                    <strong>3.</strong> Gość Schroniska zobowiązuje się do okazania pracownikowi Recepcji dokumentu ze
                    zdjęciem potwierdzającego tożsamość podczas zameldowania gościa. W przypadku odmowy okazania
                    dokumentu recepcjonista ma obowiązek odmówić wydania klucza do pokoju.
                    <br/>
                    <br/>
                    <strong>4.</strong> Zameldowanie odbywa się na podstawie spisania podstawowych danych meldunkowych w
                    książce meldunkowej na terenie recepcji przez recepcjonistę. Pokój w Schronisku wynajmowany jest na
                    doby. Doba hotelowa trwa od godziny 15:00 w dniu wynajmu do godziny 10:00 w dniu wymeldowania. Okres
                    zameldowania jest przyjmowany na podstawie rezerwacji lub preferencji gościa, w innym przypadku
                    przyjmuje się, że pokój został wynajęty na jedną dobę.
                    <br/>
                    <br/>
                    <strong>5.</strong> Pozostawienie rzeczy po godzinie 10:00 bez wcześniejszej informacji, upoważnia
                    obsługę do przeniesienia tych rzeczy bez zgody Gościa do przechowalni bagażu. W przypadku pozostania
                    gościa w pokoju po zakończeniu doby noclegowej zostanie naliczona opłata za następny nocleg.
                    <br/>
                    <br/>
                    <strong>6.</strong> W przypadku pozostania gościa w pokoju po zakończeniu doby noclegowej zostanie
                    naliczona opłata za następny nocleg.
                    <br/>
                    <br/>
                    <strong>7.</strong> Prośba o przedłużenie doby może zostać najpóźniej złożona przed zamknięciem
                    recepcji przed dniem wymeldowania. Schronisko może nie uwzględnić życzenia przedłużenia pobytu ze
                    względu na wykorzystanie wszystkich miejsc noclegowych lub w przypadku gości nie przestrzegających
                    obowiązującego regulaminu.
                    <br/>
                    <br/>
                    <strong>8.</strong> Najmujący pokój Gość, nie może dokonać przekazania pokoju innym osobom.
                    <br/>
                    <br/>
                    <strong>9.</strong> Osoby niezameldowane mogą przebywać na terenie obiektu do godziny 22:00.
                    Przebywanie osób niezameldowanych po godzinie 22:00 jest równoznaczne z wyrażeniem przez nich zgody
                    na dokonanie zakwaterowania w najtańszej możliwej opcji noclegowej na wskazany dzień. W przypadku
                    braku miejsc jest naliczana opłata 30zł/os. Odpowiadająca miejscu na podłodze.
                    <br/>
                    <br/>
                    <strong>10.</strong> Dzieci do lat 5 nocują bezpłatnie z opiekunem na łóżku. W przypadku osobnego
                    łóżka zostanie naliczona opłata Za wszelkie szkody powstałe w wyniku działania dzieci,
                    odpowiedzialność ponoszą opiekunowie prawni.
                    <br/>
                    <br/>
                    <strong>11.</strong> Schronisko nie akceptuje zwierząt, ze względu na aktualnie obowiązujące
                    przepisy Babiogórskiego Parku Narodowego. Zwierzęta nie mogą przebywać na terenie Schroniska,
                    włączając w to miejsca publiczne jak jadalnia i hol główny obiektu.
                    <br/>
                    <br/>
                    <strong>12.</strong> Na terenie Schroniska, w tym – w pokojach Schroniska zgodnie z ustawą z dnia 8
                    kwietnia 2010 roku o zmianie ustawy o ochronie zdrowia przed następstwami używania tytoniu i wyrobów
                    tytoniowych oraz ustawy o Państwowej Inspekcji Sanitarnej (Dz. U. Nr 81, poz. 529) – obowiązuje
                    całkowity zakaz palenia papierosów i wyrobów tytoniowych, zakaz obejmuje korzystanie z e-papierosa.
                    Złamanie powyższego zakazu jest równoznaczne z wyrażeniem przez najmującego zgody na pokrycie
                    kosztów dearomatyzacji pokoju w wysokości 500 złotych.
                    <br/>
                    <br/>
                    <strong>13.</strong> W schronisku obowiązuje zachowanie ciszy nocnej od godziny 22.00 do 6.00 dnia
                    następnego
                    <br/>
                    <br/>
                    <strong>14.</strong> W godzinach ciszy nocnej nie wolno zakłócać spokoju innych gości. Obsługa
                    schroniska ma prawo zarekwirować wszelkiego odtwarzacze muzyczne zakłócające cisze nocną. Urządzenia
                    zostaną zwrócone podczas wymeldowania.
                    <br/>
                    <br/>
                    <strong>15.</strong> Gość ponosi pełną odpowiedzialność materialną i prawną za wszelkiego rodzaju
                    uszkodzenia lub zniszczenia przedmiotów wyposażenia i urządzeń Schroniska powstałe z winy jego lub
                    odwiedzających go osób.
                    <br/>
                    <br/>
                    <strong>16.</strong> Na terenie schroniska zabronione jest używanie: grzałek, żelazek i innych
                    urządzeń elektrycznych. Powyższe nie dotyczy ładowarek do urządzeń elektrycznych oraz suszarek do
                    włosów na terenie sanitariatów
                    <br/>
                    <br/>
                    <strong>17.</strong> Gość powinien zawiadomić recepcję Schroniska o wystąpieniu szkody niezwłocznie
                    po jej stwierdzeniu.
                    <br/>
                    <br/>
                    <strong>18.</strong> Zabrania się nadmiernego hałasowania na terenie Schroniska bez uprzedniego
                    ustalenia z obsługą Schroniska.
                    <br/>
                    <br/>
                    <strong>19.</strong> W przypadku naruszenia postanowień powyższego regulaminu. Schronisko może
                    odmówić dalszego świadczenia usług osobie, która je narusza. Osoba naruszająca powyższy regulamin ma
                    obowiązek zastosowania się do żądań personelu Schroniska oraz do zapłaty za ewentualnie poczynione
                    uszkodzenia i zniszczenia.


                </Modal.Body>
                <Modal.Footer className="px-md-5">
                    <Button variant="grad" size="xxl" onClick={() => {
                        setShowTermsModal(false)
                    }}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ResComp;
