import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {ICONS} from "../../../../utils/IconsMapper";
import Button from "react-bootstrap/Button";
import {Col, Row} from "react-bootstrap";


interface Props {
    show: boolean;
    setClose: () => void;
    onChange: (e: any) => void;
    currentIcon: string;
}

const ChooseIconModal = ({show, setClose, onChange, currentIcon}: Props) => {


    return (
        <Modal size={"sm"} show={show} onHide={setClose}>
            <Modal.Header closeButton>
                <Modal.Title>Zmiana ikony</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="my-8">
                    <Row sm={5} className="g-3">
                        {
                            Object.entries(ICONS).map(([name, Component], key) => {
                                return (
                                    <Col key={key}>
                                        <input checked={name === currentIcon} onChange={onChange}
                                               className="d-none peer/icon" type="radio" name="icon"
                                               id={`radio-${key}`} value={name}/>
                                        <label
                                            htmlFor={`radio-${key}`}
                                            style={{height: "45px", width: "45px"}}
                                            className={`rounded-3 bg-light shadow-sm d-flex justify-content-center align-items-center ${name === currentIcon ? "border border-2 border-dark" : ""}`}>
                                            <Component size={16}/>
                                        </label>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button onClick={setClose}>Zamknij</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChooseIconModal;