import React, {useEffect, useState} from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import 'moment/locale/pl';
import MomentLocaleUtils from "react-day-picker/moment";
import RoomService from "../../service/RoomService";
import Button from "react-bootstrap/Button";
import {HiArrowSmLeft, HiArrowSmRight} from "react-icons/all";

const RoomAvailableDate = ({id}) => {

    const [unavailableTerms, setUnavailableTerms] = useState([]);

    let date1 = unavailableTerms.map(a => a.startOfUnavailableTerm);

    const disabledDayss = unavailableTerms.map(i => ({
        before: new Date(i.endOfUnavailableTerm),
        after: new Date(i.startOfUnavailableTerm)
    }))

    const disabledDays = [
        new Date(date1),
        ...disabledDayss
    ];

    const birthdayStyle = `.DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
                                color: #721c24;
                                background-color: #f8d7da;
                                border-color: #f5c6cb;
                                border-radius: 0;
                            }
                            .DayPicker-Day:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
                                color: #155724;
                                background-color: #d4edda;
                                border-color: #c3e6cb;
                                border-radius: 0;
                            }`;
    const modifiersStyles = {
        birthday: {
            color: 'white',
            backgroundColor: '#ffc107',
        },
        thursdays: {
            color: '#ffc107',
            backgroundColor: '#fffdee',
        },
    };

    useEffect(() => {
        RoomService.getAvailableTerms(id, {providedDate: null}).then(response => {
            setUnavailableTerms(response.data);
        }).catch(e => {
            console.log(e)
        })
    }, [id])


    function Navbar({
                        onPreviousClick,
                        onNextClick,
                    }) {
        const styleLeft = {
            float: 'left',
        };
        const styleRight = {
            float: 'right',
        };
        return (
            <div style={{left: "0", right: "0"}} className="position-absolute d-flex justify-content-between w-100 pt-2 px-4">
                <Button variant="counter" style={styleLeft} onClick={() => onPreviousClick()}>
                    <HiArrowSmLeft/>
                </Button>
                <Button variant="counter" style={styleRight} onClick={() => onNextClick()}>
                    <HiArrowSmRight/>
                </Button>
            </div>
        );
    }

    return (
        <>
            <style>{birthdayStyle}</style>
            <DayPicker navbarElement={<Navbar />} fromMonth={new Date()} locale={"pl"}
                       localeUtils={MomentLocaleUtils} disabledDays={disabledDays}
                       modifiersStyles={modifiersStyles} numberOfMonths={2}/>
        </>
    );
};

export default RoomAvailableDate;