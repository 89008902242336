import axios from "axios";
import authHeader from "./AuthHeader";
import {ENVIRONMENT} from "../config/environment";

const API_URL = `${ENVIRONMENT.HOST_ADDRESS}${ENVIRONMENT.PORT}`;

class WorkerService {

    createWorker(username, email, password, firstName, lastName) {
        return axios.post(API_URL+"/api/panel/workers", {
            username,
            email,
            password,
            firstName,
            lastName
        }, {headers: authHeader()});
    }

    getWorkers() {
        return axios.get(API_URL+"/api/panel/workers", {headers: authHeader()});
    }

    deleteWorker(id) {
        return axios.delete(API_URL+"/api/panel/workers/" + id,{headers: authHeader()})
    }

    getWorkerById(id) {
        return axios.get(API_URL+"/api/panel/workers/" + id, {headers: authHeader()})
    }

    updateWorker(worker, id) {
        return axios.put(API_URL+"/api/panel/workers/" + id, worker, {headers: authHeader()})
    }

    getNotifications(id) {
        return axios.get(API_URL+"/api/panel/workers/notification/" + id, {headers: authHeader()})
    }

    clearNotifications(id){
        return axios.get(API_URL+"/api/panel/workers/notification/clear/" + id, {headers: authHeader()})
    }
}

export default new WorkerService();
