import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import ReservationService from "../../../service/ReservationService";
import {NavLink} from "react-router-dom";

const GuestListWidget = ({date, refresh}) => {

    const [guests, setGuests] = useState([]);

    useEffect(() => {
        ReservationService.getCurrentGuests({providedDate: date}).then(response => {
            setGuests(response.data);
            console.log(response.data)
        }).catch(e => console.log(e))
    }, [date, refresh])

    return (
        <div className="guestlist-widget">
            <Table responsive>
                <thead>
                <tr>
                    <th className="text-nowrap">Imię i nazwisko</th>
                    <th>Miejsca</th>
                    <th>Pokój</th>
                </tr>
                </thead>
                <tbody>
                {guests.length > 0 ? guests.slice(0, 4).map((guest, key) => (
                        <tr key={key}>
                            <td className="d-flex align-items-center text-nowrap">
                                <div className="list-avatar me-3">{guest.lastName.charAt(0)}</div>
                                {guest.firstName} {guest.lastName}
                            </td>
                            <td>{guest.numberOfCompanies}</td>
                            <td>{guest.roomNumbers.join(", ")}</td>
                        </tr>
                    )) :
                    <p className="my-4">Brak gości w dniu {date}</p>
                }
                </tbody>
            </Table>
            {guests.length > 4 ? <NavLink
                to={{
                    pathname: "/admin/goscie",
                    aboutProps: {
                        date: "tu mozna przeslac date"
                    }
                }}>Zobacz więcej...</NavLink> : ""}
        </div>
    );
}

export default GuestListWidget;