import React from 'react';
import "./admin-panel.scss"
import NavbarAdmin from "./NavbarAdmin";
import Dashboard from "./dashboard/Dashboard";
import SidebarAdmin from "./SidebarAdmin";
import {Route} from "react-router-dom";
import RoomList from "./RoomList";
import GuestList from "./GuestList";
import ReservationList from "../reservationList/ReservationList";
import WorkerListComponent from "../workerList/WorkerListComponent";
import ChatComponent from "../chatComponent/ChatComponent";
import EditReservationComponent from "../editReservationComponent/EditReservationComponent";
import EditWorkerComponent from "../editWorkerComponent/EditWorkerComponent";
import AddEmployeeComponent from "../addEmployeeComponent/AddEmployeeComponent";
import Overloading from "./Overloading";
import {ToastContextProvider} from "../nativeComponents/ToastContext";
import Shelter from "./Shelter";
import Statistics from "./Statistics";

const BodyAdmin = () => {

    return (
        <ToastContextProvider>
            <div className="body-admin">
                <div className="left-sidebar-admin">
                    <div className="admin-sidebar">

                        <SidebarAdmin/>

                    </div>
                </div>
                <div className="nav-and-content-admin">
                    <NavbarAdmin/>
                    <ChatComponent/>

                    <div className="container-fluid content-admin pb-5">
                        <Route exact path="/admin" component={Dashboard}/>
                        <Route exact path="/admin/rezerwacje" component={ReservationList}/>
                        <Route path="/admin/pokoje" component={RoomList}/>
                        <Route path="/admin/goscie" component={GuestList}/>
                        <Route path="/admin/statystyki" component={Statistics}/>
                        <Route exact path="/admin/pracownicy" component={WorkerListComponent}/>
                        <Route path="/admin/przeladowanie" component={Overloading}/>
                        <Route path="/admin/rezerwacje/edytuj/:id" component={EditReservationComponent}/>
                        <Route path="/admin/pracownicy/update-worker/:id" component={EditWorkerComponent}/>
                        <Route path="/admin/pracownicy/register" component={AddEmployeeComponent}/>
                        <Route path="/admin/schronisko" component={Shelter}/>
                    </div>
                </div>
            </div>
        </ToastContextProvider>
    );
}

export default BodyAdmin;