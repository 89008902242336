import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import {BiShoppingBag} from "react-icons/all";
import {CloseButton, Offcanvas} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import {Trans, useTranslation} from "react-i18next";

const Cart = (props) => {
    const [show, setShow] = useState(false);
    // const [reservationDayStart, setReservationDayStart] = useState()
    // const [reservationDayEnd, setReservationDayEnd] = useState()
    // const [howManyPeople, setHowManyPeople] = useState()
    // const [nights, setNights] = useState()
    // const [cartRooms, setCartRooms] = useState()
    // const [amountToPay, setAmountToPay] = useState()


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { t } = useTranslation();


    return (
        <>
            <div onClick={handleShow} className="d-block">
                <div className="h3 m-0 p-0 text-center"><BiShoppingBag/></div>

                <div style={{marginTop: "-5px"}}>
                        <span style={{
                            borderRadius: "50%",
                            height: "15px",
                            width: "15px",
                            marginTop: "-20px",
                            marginLeft: "5px"
                        }}
                              className="bg-primary position-absolute text-white d-flex justify-content-center align-items-center">
                                <small>{props.cartRooms.length}</small>
                            </span>
                    <small><Trans i18nKey='RoomList_29'>Koszyk</Trans></small>
                </div>

            </div>

            <Offcanvas scroll={false} placement={"bottom"} show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton className="border-bottom">
                    <Offcanvas.Title className="m-0"><Trans i18nKey='RoomList_29'>Koszyk</Trans></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pb-0">

                    {props.cartRooms.length === 0 ? <Trans i18nKey='RoomList_18'>Koszyk jest pusty</Trans> : null}

                    {props.cartRooms.map((room, key) => {
                        return (

                            <div key={key} className="cart-item">
                                <Card className="flex-row border-0">
                                    <Card.Img src={room.details.imgUrl}
                                              className="img-fluid box-rounded-sm"/>
                                    <Card.Body className="pt-0">
                                        <Card.Title
                                            className="d-flex m-0 fw-bold justify-content-between align-items-center">
                                                        <span
                                                            className="d-block cart-title">{t(room.details.description)}</span>
                                            <small>
                                                <CloseButton
                                                    onClick={() => props.removeRoomFromCart(room)}/>
                                            </small>

                                        </Card.Title>
                                        <Card.Text className="mt-0">
                                                            <span
                                                                className="d-block ">{t(room.type)}
                                                            </span>
                                            <span
                                                className="d-block text-primary fw-bold">{room.details.pricePerPeron * room.numberOfGuests * props.nights} zł</span>
                                            <span
                                                className="d-block d-flex  align-items-center">
                                                                <span className="me-2"><Trans i18nKey='ResComp_24'>Liczba osób</Trans>:</span>
                                                {
                                                    room.type === props.type ?
                                                        (<>
                                                            {room.numberOfGuests}
                                                        </>) :
                                                        (
                                                            (room.numberOfGuests > 0 ?
                                                                    (
                                                                        room.numberOfGuests == room.details.placesLeft ?
                                                                            (
                                                                                <>
                                                                                    <Button
                                                                                        variant="counter"
                                                                                        size="xs"
                                                                                        disabled={false}
                                                                                        onClick={() => props.addPeopleToRoom(-2, room)}>-
                                                                                    </Button>
                                                                                    <div
                                                                                        className="counter-value">{room.numberOfGuests}</div>
                                                                                    <Button
                                                                                        variant="counter"
                                                                                        size="xs"
                                                                                        disabled={true}
                                                                                        onClick={() => props.addPeopleToRoom(1, room)}>+
                                                                                    </Button>
                                                                                </>
                                                                            ) :
                                                                            (
                                                                                room.numberOfGuests == room.details.placesLeft - 2 ?
                                                                                    (
                                                                                        <>
                                                                                            <Button
                                                                                                variant="counter"
                                                                                                size="xs"
                                                                                                disabled={false}
                                                                                                onClick={() => props.addPeopleToRoom(-1, room)}>-
                                                                                            </Button>
                                                                                            <div
                                                                                                className="counter-value">{room.numberOfGuests}</div>
                                                                                            <Button
                                                                                                variant="counter"
                                                                                                size="xs"
                                                                                                disabled={false}
                                                                                                onClick={() => props.addPeopleToRoom(2, room)}>+
                                                                                            </Button>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Button
                                                                                                variant="counter"
                                                                                                size="xs"
                                                                                                disabled={false}
                                                                                                onClick={() => props.addPeopleToRoom(-1, room)}>-
                                                                                            </Button>
                                                                                            <div
                                                                                                className="counter-value">{room.numberOfGuests}</div>
                                                                                            <Button
                                                                                                variant="counter"
                                                                                                size="xs"
                                                                                                disabled={false}
                                                                                                onClick={() => props.addPeopleToRoom(1, room)}>+
                                                                                            </Button>
                                                                                        </>
                                                                                    )
                                                                            )

                                                                    ) : (
                                                                        (
                                                                            room.numberOfGuests == room.details.placesLeft ?
                                                                                (
                                                                                    <>
                                                                                        <Button
                                                                                            variant="counter"
                                                                                            size="xs"
                                                                                            disabled={true}
                                                                                            onClick={() => props.addPeopleToRoom(-1, room)}>-
                                                                                        </Button>
                                                                                        <div
                                                                                            className="counter-value">{room.numberOfGuests}</div>
                                                                                        <Button
                                                                                            variant="counter"
                                                                                            size="xs"
                                                                                            disabled={true}
                                                                                            onClick={() => props.addPeopleToRoom(1, room)}>+
                                                                                        </Button>
                                                                                    </>
                                                                                ) :
                                                                                (
                                                                                    room.numberOfGuests == room.details.placesLeft - 2 ?
                                                                                        (
                                                                                            <>
                                                                                                <Button
                                                                                                    variant="counter"
                                                                                                    size="xs"
                                                                                                    disabled={true}
                                                                                                    onClick={() => props.addPeopleToRoom(-1, room)}>-
                                                                                                </Button>
                                                                                                <div
                                                                                                    className="counter-value">{room.numberOfGuests}</div>
                                                                                                <Button
                                                                                                    variant="counter"
                                                                                                    size="xs"
                                                                                                    disabled={false}
                                                                                                    onClick={() => props.addPeopleToRoom(2, room)}>+
                                                                                                </Button>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <Button
                                                                                                    variant="counter"
                                                                                                    size="xs"
                                                                                                    disabled={true}
                                                                                                    onClick={() => props.addPeopleToRoom(-1, room)}>-
                                                                                                </Button>
                                                                                                <div
                                                                                                    className="counter-value">{room.numberOfGuests}</div>
                                                                                                <Button
                                                                                                    variant="counter"
                                                                                                    size="xs"
                                                                                                    disabled={false}
                                                                                                    onClick={() => props.addPeopleToRoom(1, room)}>+
                                                                                                </Button>
                                                                                            </>
                                                                                        )
                                                                                )


                                                                        )
                                                                    )
                                                            )
                                                        )
                                                }

                                                            </span>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>

                        )
                    })}

                </Offcanvas.Body>
                <footer className="p-4 text-center border-top">
                    <div>
                        <span className="d-block"><Trans i18nKey='RoomList_24'>Łącznie do zapłaty</Trans></span>
                        <span className="d-block fw-bold">
                                        {props.cartRooms.length === 0 ? <>0
                                            zł</> : <>{props.amountToPay * props.nights} zł</>}
                                    </span>
                        <span><Trans i18nKey='RoomList_22'>Łączna liczba osób</Trans>: {props.howManyPeople}</span>
                    </div>
                    <div className="my-2">

                        <Button
                            disabled={props.howManyPeople === 0 || props.cartRooms.length === 0 ||
                            (props.numberOfPeople > props.howManyPeople)}
                            variant={props.howManyPeople === 0 || props.cartRooms.length === 0 ||
                            (props.numberOfPeople > props.howManyPeople) ? "warning" : "success"}
                            size="lg"
                            className="col-12"
                            onClick={props.goToForm}
                        >
                            {props.numberOfPeople > props.howManyPeople ? <><Trans i18nKey='RoomList_26'>Minimalna
                                liczba
                                osób</Trans>: {props.numberOfPeople}</> : <Trans i18nKey='RoomList_25'>Przejdź do podsumowania</Trans>}
                        </Button>
                        <p onClick={handleClose}
                           className="small mb-0 mt-3 cursor-pointer">
                            <Trans i18nKey='RoomList_30'>Kontynuuj wybieranie</Trans>
                        </p>
                    </div>
                </footer>
            </Offcanvas>
        </>
    );
}

export default Cart;
