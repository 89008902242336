import React, {useEffect, useState} from 'react';
import {Pagination, Form} from "react-bootstrap";

const PaginationComponent = (props) => {
    const [pages, setPages] = useState([]);

    let active = props.number;
    let items = [];
    for (let number = 1; number <= props.totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => props.setNumber(number)}>
                {number}
            </Pagination.Item>
        )
        ;
    }

    useEffect(() => {
        setPages(items.slice(props.number === 1 ? props.number - 1 : props.number - 2, props.number + 1))
    }, [props])

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center pe-5">
                <span>Pokaż</span>
                <div style={{width: "70px"}} className="mx-2">
                    <Form.Select as="select" defaultValue={props.size} title="rows-per-page" onChange={(e) => props.setSize(e.target.value)}>
                        <option>{5}</option>
                        <option>{10}</option>
                        <option>{20}</option>
                    </Form.Select>
                </div>
                <span>pozycji</span>
            </div>
            <Pagination className="m-0">
                <Pagination.Prev
                    disabled={props.number === 1}
                    onClick={() => props.number > 1 ? props.setNumber(props.number - 1) : 1}/>
                <Pagination.Item className={props.number < 3 ? "d-none" : ""}
                                 onClick={() => props.setNumber(1)}>
                    {1}
                </Pagination.Item>
                {/*<Pagination.Item className={props.number < 4 ? "d-none" : ""} key={1} active={1 === active} onClick={() => props.setNumber(1)}>*/}
                {/*    {1}*/}
                {/*</Pagination.Item>*/}
                <Pagination.Ellipsis className={props.number < 4 ? "d-none" : ""}
                                     onClick={() => props.number > 1 ? props.setNumber(Math.round(props.number / 2)) : 1}/>

                {pages}

                <Pagination.Ellipsis className={props.number > (props.totalPages - 3) ? "d-none" : ""}
                                     onClick={() => props.number * 2 < props.totalPages ? props.setNumber(props.number * 2) : props.setNumber(props.totalPages)}/>

                {/*<Pagination.Last onClick={() => props.setNumber(props.totalPages)}/>*/}
                <Pagination.Item className={props.number > (props.totalPages - 2) ? "d-none" : ""}
                                 onClick={() => props.setNumber(props.totalPages)}>
                    {props.totalPages}
                </Pagination.Item>
                <Pagination.Next
                    disabled={props.number === props.totalPages}
                    onClick={() => props.number !== props.totalPages ? props.setNumber(props.number + 1) : props.totalPages}/>
            </Pagination>
        </div>
    );
};

export default PaginationComponent;