import axios from 'axios';
import authHeader from "./AuthHeader";
import {ENVIRONMENT} from "../config/environment";

const API_URL = `${ENVIRONMENT.HOST_ADDRESS}${ENVIRONMENT.PORT}`

class MessageService {

    getMessages(){
        return axios.get(API_URL+"/api/chat/message", {headers: authHeader()})
    }
    sendMessage(message){
        return axios.post(API_URL+"/api/chat/message", message, {headers: authHeader()})
    }

}

export default new MessageService();

