import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import {FaPlus} from "react-icons/all";
import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import SettingsService from "../../../../service/SettingsService";
import useToastContext from "../../../nativeComponents/useToastContext";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";


const defaultAmenityData = {
    name: "",
    description: "",
}

const AddAmenityModal = () => {

    const [show, setShow] = useState<boolean>(false);
    const [field, setField] = useState(defaultAmenityData);

    const addToast = useToastContext();

    const setClose = () => setShow(false);

    const handleFieldChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        setField({...field, [name]: value});
    }

    const queryClient = useQueryClient();

    const {mutate, isLoading} = useMutation({
        mutationFn: SettingsService.createAmenity,
        onSuccess: () => {
            addToast({text: "Operacja przebiegła pomyślnie!", variant: true});
            setField(defaultAmenityData);
            setShow(false);
            queryClient.invalidateQueries(['settingsAmenitiesAdmin']);
        },
        onError: (error: AxiosError) => {
            addToast({text: "Nie udało się wykonać operacji!", variant: false});
        }
    });

    const handleAddField = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        mutate(field)
    };


    return (
        <>
            <Button onClick={() => setShow(true)} variant="primary" className="fw-bold">
                <FaPlus/> Dodaj
            </Button>

            <>
                <Modal show={show} onHide={setClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Dodaj nowe pole</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleAddField}>
                        <Modal.Body>
                            <div className="d-grid gap-3">
                                <Form.Group>
                                    <Form.Label className="mb-2">Udogodnienie</Form.Label>
                                    <Form.Control className="w-full" value={field.name} name="name"
                                                  onChange={handleFieldChange} placeholder="Nazwa"/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="mb-2">Opis</Form.Label>
                                    <Form.Control as="textarea" value={field.description} name="description"
                                                  onChange={handleFieldChange}
                                                  rows={6}
                                                  placeholder="Tu wpisz opis..."/>
                                </Form.Group>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={setClose} variant="secondary">Anuluj</Button>
                            <Button disabled={isLoading || field.description.length < 1}
                                    type="submit">Dodaj</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>

        </>
    );
};

export default AddAmenityModal;