import React, {useEffect, useLayoutEffect, useState} from 'react';
import ReservationService from "../../service/ReservationService";
import AuthService from "../../service/AuthService";
import Button from "react-bootstrap/Button";
import {Badge, Col, Dropdown, Modal, Row} from "react-bootstrap";
import PaginationComponent from "../pagination/PaginationComponent";
import {FaEdit, FaTrashAlt, FaUserCheck} from "react-icons/all";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from 'react-bootstrap-table2-overlay';
import {Link} from "react-router-dom";
import EditRoomsModal from "./EditRoomsModal";
import moment from "moment";
import EditPriceModal from "./EditPriceModal";


const ReservationList = (props) => {

    const [reservations, setReservations] = useState([]);
    const [showAdminButtons, setShowAdminButtons] = useState(false);

    const [show, setShow] = useState(false);
    const [id, setId] = useState(null);

    const [data, setData] = useState({});

    const [showModalRooms, setShowModalRooms] = useState('');
    const [showModalPrice, setShowModalPrice] = useState(false);


    const initialState = {
        id: '',
        arrivalDate: '',
        departureDate: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        typeOfReservation: null,
        isAccommodationPaid: null,
        addingReservationDate: ""
    };
    const [localStorageForm, setLocalStorageForm] = useState(initialState);


    const handleClose = () => {
        setShow(false);
        setId(null)
    }
    const handleShow = (id) => {
        setShow(true);
        setId(id)
    }

    const [parameters, setParameters] = useState(JSON.parse(localStorage.getItem("MSSearchParams")) !== null ? JSON.parse(localStorage.getItem("MSSearchParams")) : {
        id: '',
        arrivalDate: '',
        departureDate: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: null,
        typeOfReservation: null,
        isAccommodationPaid: null,
        addingReservationDate: null
    });


    const mediaQ = {xs: 12, md: 6, lg: 4, xl: 3};

    //strona aktualna
    const [number, setNumber] = useState(1);
    //rezerwacji na strone
    const [size, setSize] = useState(10);
    //wszystkich postow
    const [totalElements, setTotalElements] = useState(0);
    //wszystkich stron
    const [totalPages, setTotalPages] = useState(0);

    const [resError, setResError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [reloadTable, setReloadTable] = useState(false);


    useLayoutEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) setShowAdminButtons(user.roles.includes("ROLE_OWNER") || user.roles.includes("ROLE_WORKER"))
    }, []);


    const fetchData = async () => {
        setIsLoading(true);
        const {data} = await ReservationService.getReservations(number, size, parameters);
        return data;
    }
    useEffect(() => {
        if (AuthService.getCurrentUser()) {

            fetchData().then((data) => {
                setReservations(data.reservationData);
                // console.log(data.reservationData);
                setTotalElements(data.pagination.totalElements);
                setTotalPages(data.pagination.totalPages);
                setIsLoading(false);

                if (JSON.parse(localStorage.getItem("MSSearchParams")) !== null) {
                    setLocalStorageForm(JSON.parse(localStorage.getItem("MSSearchParams")))
                }
            }).catch((err) => {
                setResError(true)
            });

        }
    }, [number, parameters, size, reloadTable]);


    const searchReservationByProperFeature = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        setNumber(1)

        setParameters({...parameters, [name]: value})

        localStorage.setItem("MSSearchParams", JSON.stringify({
            ...JSON.parse(localStorage.getItem("MSSearchParams")),
            [name]: value
        }))
        setLocalStorageForm({...JSON.parse(localStorage.getItem("MSSearchParams")), [name]: value})

    }

    const clearParameters = (e) => {
        e.preventDefault();
        setParameters(initialState);
        setLocalStorageForm(initialState);
        localStorage.removeItem("MSSearchParams");
    }

    const search = () => {
        setParameters(localStorageForm)
    }

    const editRooms = (idx) => {
        // setData(data2)
        setShowModalRooms(idx)
    }
    const editPrice = (data2) => {
        setData(data2)
        setShowModalPrice(true)
    }


    const editReservationHandler = (id) => {
        props.history.push(`/admin/rezerwacje/edytuj/${id}`)
    }

    const deleteReservationHandler = () => {
        ReservationService.deleteReservation(id).then(response => console.log(response.status))
        setReservations(reservations.filter(reservation => reservation.id !== id));
        handleClose();
    }

    const confirmPayment = (props) => {
        console.log("id " + props.id)
        ReservationService.confirmPayment(props.id, {accommodationPaid: props.choice}).then(res => {
            console.log(res.status)
            let newReservations = reservations.map(reservation => {
                if (reservation.id === props.id) {
                    reservation.accommodationPaid = res.data.accommodationPaid;
                    return reservation
                } else {
                    return reservation
                }
            })
            setReservations(newReservations)
        })
    }


    const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
        <span
            className="cursor-pointer"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </span>
    ));



    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true
        },
        {
            dataField: "firstName",
            text: "Imię",
            sort: true
        },
        {
            dataField: "lastName",
            text: "Nazwisko",
            sort: true
        },
        {
            dataField: "reservationTime",
            text: "Dokonanie rezerwacji",
            sort: true
        },
        {
            dataField: "howManyPeople",
            text: "Ile osób",
            sort: true
        },
        {
            dataField: "reservationStart",
            text: "Przyjazd",
            sort: true
        },
        {
            dataField: "reservationEnd",
            text: "Wyjazd",
            sort: true,
        },
        {
            dataField: "amountToPay",
            text: "Kwota rezerwacji",
            sort: true,
        },
        {
            dataField: "alreadyPaid",
            text: "Wpłacono",
            sort: true,
            formatter: (cellContent, row) => (
                <div className="cursor-pointer fw-bold action-icon" title="Edytuj cenę" onClick={() => editPrice(row)}>
                    {row.alreadyPaid}
                    <FaEdit className="ms-2"/>
                </div>
            )
        },
        {
            dataField: "typeOfReservation",
            text: "Rodzaj",
            sort: true,
            formatter: (cellContent, row) => (
                parameters.typeOfReservation === "ACTIVE_RESERVATION" && row.reservationType === "INTERNET_RESERVATION" ? "ZWYKŁA, AKTYWNA" :
                    parameters.typeOfReservation === "EXPIRED_RESERVATION" && row.reservationType === "INTERNET_RESERVATION" ? "ZWYKŁA, NIEAKTYWNA" :
                        parameters.typeOfReservation === "EXPIRED_RESERVATION" && row.reservationType === "CALL_RESERVATION" ? "INNA, AKTYWNA" :
                            parameters.typeOfReservation === "EXPIRED_RESERVATION" && row.reservationType === "CALL_RESERVATION" ? "INNA, NIEAKTYWNA" :
                                parameters.reservationType === "CANCELED_RESERVATION" ? "ANULOWANA" :
                                    row.reservationType === "INTERNET_RESERVATION" ? "ZWYKŁA" :
                                        row.reservationType === "CALL_RESERVATION" ? "INNA" :
                                            "NIEOKREŚLONA"
            )
        },
        {
            dataField: "accommodationPaid",
            text: "Płatność",
            sort: true,
            formatter: (cellContent, row) => (
                <Dropdown title="Zmień">
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        {row.accommodationPaid ? <Badge bg="success" text="white">Opłacona <FaUserCheck/></Badge> :
                            <Badge bg="danger" text="white">Nieopłacona <FaUserCheck/></Badge>}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => confirmPayment({choice: true, id: row.id})}>Opłacona</Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => confirmPayment({choice: false, id: row.id})}>Nieopłacona</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
        },
        {
            hidden: !showAdminButtons,
            dataField: "pricePerPeron",
            text: "Akcje",
            formatter: (cellContent, row) => (
                <span>
                    {
                        ((moment(row.reservationStart).diff(moment(), 'hours')) > 11) &&
                        <><span className="action-icon"
                                title="edytuj pokoje"
                                onClick={() => editRooms(row.id)}
                        >
                                <FaEdit/>
                            </span>
                            <EditRoomsModal
                                reload={() => setReloadTable(!reloadTable)}
                                data={row}
                                show={showModalRooms === row.id}
                                onHide={() => setShowModalRooms(null)}
                            /></>
                    }

                    {/*<span className="action-icon"*/}
                    {/*      title="edytuj szczegóły"*/}
                    {/*      onClick={() => editReservationHandler(row.id)}>*/}
                    {/*    <FaEdit/>*/}
                    {/*</span>*/}
                    <span className="action-icon"
                          title="usuń"
                          onClick={() => handleShow(row.id)}>
                        <FaTrashAlt/>
                    </span>
                </span>
            )
        },

    ];
    const expandRow = {
        parentClassName: 'table-info',
        renderer: row => (
            <div>
                <p><span className="me-3"><strong>Telefon:</strong> {row.phoneNumber}</span>
                    <strong>Email:</strong> {row.email}</p>
                <p><strong>Pokoje nr: </strong>{row.roomNumbers.join(", ")}</p>

                <p className="mb-0"><strong>Dodatkowe informacje:</strong></p>
                <p>{row.extraInfo.length > 0 ? row.extraInfo : "Brak "}</p>
            </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true
    };

    // const defaultSorted = [{
    //     dataField: 'id',
    //     order: 'desc'
    // }];

    return (
        <div className="container-fluid">
            <Row>
                <Col>
                    <div className="admin-panel-title mb-4">Rezerwacje</div>
                </Col>
            </Row>
            <div className="card-admin p-4">
                <Row>
                    <Col xs={12} className="mb-3 d-flex justify-content-end ">
                        <Link to="/">
                            <Button variant="success">Nowa rezerwacja</Button>
                        </Link>
                    </Col>
                    <Col xs={mediaQ.xs} md={mediaQ.md} lg={mediaQ.lg} xl={mediaQ.xl}>
                        <div className="filter-group">
                            <label>Identyfikator rezerwacji</label>
                            <input
                                value={localStorageForm.id || ''}
                                type="number"
                                className="form-control"
                                name="id"
                                onChange={searchReservationByProperFeature}
                            />
                        </div>
                    </Col>
                    <Col xs={mediaQ.xs} md={mediaQ.md} lg={mediaQ.lg} xl={mediaQ.xl}>
                        <div className="filter-group">
                            <label>Imię</label>
                            <input
                                value={localStorageForm.firstName || ''}
                                type="text"
                                className="form-control"
                                name="firstName"
                                onChange={searchReservationByProperFeature}
                            />
                        </div>
                    </Col>
                    <Col xs={mediaQ.xs} md={mediaQ.md} lg={mediaQ.lg} xl={mediaQ.xl}>
                        <div className="filter-group">
                            <label>Nazwisko</label>
                            <input
                                value={localStorageForm.lastName || ''}
                                type="text"
                                className="form-control"
                                name="lastName"
                                onChange={searchReservationByProperFeature}
                            />
                        </div>
                    </Col>
                    <Col xs={mediaQ.xs} md={mediaQ.md} lg={mediaQ.lg} xl={mediaQ.xl}>
                        <div className="filter-group">
                            <label>Email</label>
                            <input
                                value={localStorageForm.email || ''}
                                type="text"
                                className="form-control"
                                name="email"
                                onChange={searchReservationByProperFeature}
                            />
                        </div>
                    </Col>
                    <Col xs={mediaQ.xs} md={mediaQ.md} lg={mediaQ.lg} xl={mediaQ.xl}>
                        <div className="filter-group">
                            <label>Telefon</label>
                            <input
                                value={localStorageForm.phoneNumber || ''}
                                type="number"
                                className="form-control"
                                name="phoneNumber"
                                onChange={searchReservationByProperFeature}
                            />
                        </div>
                    </Col>
                    <Col xs={mediaQ.xs} md={mediaQ.md} lg={mediaQ.lg} xl={mediaQ.xl}>
                        <label>Data przyjazdu</label>
                        <input
                            value={localStorageForm.arrivalDate || ''}
                            type="date"
                            id="checkIn"
                            placeholder="Data przyjazdu"
                            className="form-control"
                            name="arrivalDate"
                            onChange={searchReservationByProperFeature}
                        />
                    </Col>
                    <Col xs={mediaQ.xs} md={mediaQ.md} lg={mediaQ.lg} xl={mediaQ.xl}>
                        <label>Data wyjazdu</label>
                        <input
                            value={localStorageForm.departureDate || ''}
                            type="date"
                            id="checkOut"
                            placeholder="Data wyjazdu"
                            className="form-control"
                            name="departureDate"
                            onChange={searchReservationByProperFeature}
                        />
                    </Col>
                    <Col xs={mediaQ.xs} md={mediaQ.md} lg={mediaQ.lg} xl={mediaQ.xl}>
                        <label>Data dokonania rezerwacji</label>
                        <input
                            value={localStorageForm.addingReservationDate || ''}
                            type="date"
                            id="checkOut"
                            placeholder="Data dokonania rezerwacji"
                            className="form-control"
                            name="addingReservationDate"
                            onChange={searchReservationByProperFeature}
                        />
                    </Col>
                    <Col xs={mediaQ.xs} md={mediaQ.md} lg={mediaQ.lg} xl={mediaQ.xl}>
                        <div className="filter-group">
                            <label>Status płatności</label>
                            <select
                                value={localStorageForm.isAccommodationPaid === null ? "default" : localStorageForm.isAccommodationPaid}
                                className="form-control"
                                name="isAccommodationPaid"
                                onChange={searchReservationByProperFeature}
                            >
                                <option value="default" disabled>Wybierz...</option>
                                <option value={true}>Opłacona</option>
                                <option value={false}>Nieopłacona</option>
                            </select>
                        </div>
                    </Col>
                    <Col xs={mediaQ.xs} md={mediaQ.md} lg={mediaQ.lg} xl={mediaQ.xl}>
                        <div className="filter-group">
                            <label>Rodzaj rezerwacji</label>
                            <select
                                value={localStorageForm.typeOfReservation === null ? "default" : localStorageForm.typeOfReservation}
                                className="form-control"
                                name="typeOfReservation"
                                onChange={searchReservationByProperFeature}
                            >
                                <option value="default" disabled>Wybierz...</option>
                                <option value="INTERNET_RESERVATION">Zwykła</option>
                                <option value="CALL_RESERVATION">Inna</option>
                                <option value="CANCELED_RESERVATION">Anulowana</option>
                                <option value="ACTIVE_RESERVATION">Aktywna</option>
                                <option value="EXPIRED_RESERVATION">Nieaktywna</option>
                            </select>
                        </div>
                    </Col>
                    <Col className="align-self-end">
                        <Button onClick={search} className="me-2">Szukaj</Button>
                        <Button onClick={clearParameters} className="">Wyczyść</Button>
                    </Col>
                </Row>
                <br/>
                <div>
                    {resError ? (
                        <div>Błąd podczas pobierania danych</div>
                    ) : (
                        <BootstrapTable bootstrap4 keyField='id' data={reservations} columns={columns}
                                        expandRow={expandRow}
                                        noDataIndication={isLoading === false ? "Brak rezerwacji" :
                                            <div className="my-5"/>}
                                        loading={isLoading}
                                        overlay={overlayFactory({
                                            spinner: true,
                                            styles: {
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: 'rgba(30, 40, 86, 0.5)'
                                                })
                                            }
                                        })}
                        />
                    )}

                    {reservations.length !== 0 ?

                        <PaginationComponent
                            number={number}
                            size={size}
                            totalElements={totalElements}
                            totalPages={totalPages}

                            setNumber={setNumber}
                            setSize={setSize}
                            setTotalElements={setTotalElements}
                        />
                        : ""

                    }
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Usuń rezerwacje</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Czy chcesz usunąć rezerwacje? Operacja jest nieodwracalna</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Anuluj
                            </Button>
                            <Button variant="primary" onClick={deleteReservationHandler}>
                                Usuń rezerwację
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/*<EditRoomsModal*/}
                    {/*    reload={() => setReloadTable(!reloadTable)}*/}
                    {/*    data={data}*/}
                    {/*    show={showModalRooms}*/}
                    {/*    onHide={() => setShowModalRooms(false)}*/}
                    {/*/>*/}

                    <EditPriceModal
                        reload={() => setReloadTable(!reloadTable)}
                        data={data}
                        show={showModalPrice}
                        onHide={() => setShowModalPrice(false)}
                    />


                </div>
            </div>
        </div>
    );
}

export default ReservationList;


