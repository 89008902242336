import React from 'react';
import {Link} from "react-router-dom";
import Menu from "./Menu";
import MenuMobileComponent from "./MenuMobileComponent";
import ProfilePanel from "./ProfilePanel";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

import {useTranslation, Trans} from "react-i18next";

const langs = {
    pl: {nativeName: 'polski', icon: "../../images/pl-icon.svg", alt: "lang-pl"},
    en: {nativeName: 'English', icon: "../../images/uk-icon.svg", alt: "lang-en"}
}

const NavbarComponent = () => {

    const {i18n} = useTranslation();

    return (
        <nav className="navigation d-flex align-items-center position-relative border-bottom">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center header-items">
                    <div className="d-flex text-left align-items-center justify-content-start">
                        <a href="https://markoweszczawiny.pttk.pl/" title="Markowe Szczawiny" className="logo text-decoration-none me-3">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="66.907px" height="34px"
                                     viewBox="0 0 66.907 34">
                                    <path id="Path_1" data-name="Path 1"
                                          d="M623.42,361.818l22.345-28.509,13.426,17.1,4.273-5.565,8.117,10.564h5.373l-13.49-17.763-4.273,5.683-3.963-5.009,8.235-10.5,26.864,34H668.72l-5.255-6.409-4.273,5.127-13.426-17.209-9.335,12.081h5.244l4.091-5,3.974,5-5.009,6.409Z"
                                          transform="translate(-623.42 -327.82)"/>
                                </svg>
                            </div>
                        </a>
                        <div className="d-none d-md-flex"><Menu/></div>
                    </div>

                    <div className="d-flex">
                        <div className="language-icons d-none d-md-flex align-items-center">

                            {
                                Object.keys(langs).map((lng) => (
                                    <div key={lng} className="icon-header me-3">
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip>{langs[lng].nativeName}</Tooltip>}
                                        >
                                            <button className="btn btn-link p-0 border-0 bg-transparent" onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng}>
                                                <img
                                                    height="25px"
                                                    width="25px"
                                                    alt={langs[lng].alt}
                                                    src={langs[lng].icon}
                                                    className="img-fluid"
                                                />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                ))
                            }

                            <ProfilePanel/>
                        </div>

                    </div>
                    <div className="d-flex d-md-none"><MenuMobileComponent/></div>

                </div>
            </div>
        </nav>
    )
}

export default NavbarComponent
