import React, {useEffect, useReducer} from 'react';
import {Button} from "react-bootstrap";
import {HiMinus, HiPlus} from "react-icons/all";
import {Trans} from "react-i18next";


const GuestPicker = ({handleChange, number}) => {
    const [state, dispatch] = useReducer(reducer, {count: number});

    function reducer(state, action) {
        switch (action.type) {
            case 'increment':
                return {count: state.count + 1};
            case 'decrement':
                return {count: state.count - 1};
            case 'reset':
                return {count: 1}
            default:
                throw new Error();
        }
    }

    useEffect(() => {
        handleChange(state.count)
    }, [handleChange, state.count])


    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div><Trans i18nKey='GuestPicker_1'>Liczba osób</Trans></div>
                <div className="d-flex align-items-center">
                    <Button size="sm" variant="counter"
                            disabled={state.count <= 1}
                            onClick={() => dispatch({type: 'decrement'})}
                    ><HiMinus/>
                    </Button>

                    <div className="counter-value user-select-none">{state.count}</div>

                    <Button size="sm" variant="counter"
                            disabled={state.count >= 100}
                            onClick={() => dispatch({type: 'increment'})}
                    ><HiPlus/>
                    </Button>
                </div>
            </div>
            <div className="mt-3 d-flex justify-content-between align-items-center">
                {/*<span onClick={() => dispatch({type: 'reset'})}*/}
                {/*      className="me-2 cursor-pointer link-primary">*/}
                {/*    reset*/}
                {/*</span>*/}
                <small><Trans i18nKey='GuestPicker_2'>W oparciu o tę liczbę osób wyszukiwane są pokoje. Oczywiście istnieje możliwość wynajęcia
                    większego pokoju.</Trans></small>
            </div>

        </>
    );
};

export default GuestPicker;
