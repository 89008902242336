import React, {Component} from 'react';
import WorkerService from "../../service/WorkerService";
import Button from "react-bootstrap/Button";

class EditWorkerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            firstName: '',
            lastName: '',
            email: '',

        }
    }

    componentDidMount() {
        WorkerService.getWorkerById(this.state.id)
            .then((response) => {
                let worker = response.data
                this.setState({
                    firstName: worker.firstName,
                    lastName: worker.lastName,
                    email: worker.email,
                })
            })
    }

    updateWorkerHandler = (e) => {
        e.preventDefault();
        let worker = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
        }
        WorkerService.updateWorker(worker, this.state.id).then((response) => {
            this.props.history.push('/admin/pracownicy/')
        })
    }

    changeTypingValueHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <>
                <div className="container card-admin p-4">
                    <p className="title-card">Edycja danych pracownika: <span className="text-primary"> {this.state.firstName} {this.state.lastName}</span></p>
                    <form method="post">
                        <div className="row gy-3">
                            <div className="form-group col-md-6">
                                <label htmlFor="firstName"> Imię </label>
                                <input type="text" id="firstName" placeholder="Wprowadź imię"
                                       className="form-control" name="firstName"
                                       value={this.state.firstName} onChange={this.changeTypingValueHandler}/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="lastName"> Nazwisko </label>
                                <input type="text" id="lastName" placeholder="Wprowadź nazwisko"
                                       name="lastName" onChange={this.changeTypingValueHandler}
                                       value={this.state.lastName} className="form-control"/>
                            </div>


                            <div className="form-group col-md-6">
                                <label htmlFor="email"> Adres e-mail </label>
                                <input type="email" id="email" placeholder="Wprowadź e-mail" className="form-control"
                                       value={this.state.email} name="email" onChange={this.changeTypingValueHandler}/>

                            </div>
                            <div className="form-group">
                                <Button onClick={this.updateWorkerHandler}
                                        type="submit"
                                        variant="primary"
                                        className="me-2"
                                >
                                    Aktualizuj
                                </Button>

                                <Button onClick={this.props.history.goBack} variant="primary">Wróć</Button>
                            </div>
                        </div>

                    </form>
                </div>
            </>
        );
    }
}

export default EditWorkerComponent;
