import React, {useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import moment from "moment";
import {FaSyncAlt} from "react-icons/all";
import OverviewWidget from "./OverviewWidget";
import UpcomingArrivalsWidget from "./UpcomingArrivalsWidget";
import LatestReservationsWidget from "./LatestReservationsWidget";
import GuestListWidget from "./GuestListWidget";
import "./Dashboard.scss"
import {formatDate} from "react-day-picker/moment";

const Dashboard = ({setNameState}) => {

    const [providedDate, setProvidedDate] = useState(moment().format("YYYY-MM-DD"));
    const [refresh, setRefresh] = useState(false);


    return (
        <div className="container-fluid dashboard">
            <Row className="justify-content-between mb-4">
                <Col>
                    <div className="admin-panel-title">Dashboard</div>
                </Col>
                <Col className="">
                    <Form className="d-flex float-end">
                        <input
                            type="date"
                            id="selectDateAdmin"
                            placeholder="Data"
                            className="form-control buttons-lp me-2"
                            name="providedDate"
                            defaultValue={moment().format("YYYY-MM-DD")}
                            onChange={(e) => setProvidedDate(e.target.value)}
                        />
                        <Button onClick={() => setRefresh(!refresh)}><FaSyncAlt/></Button>
                    </Form>
                </Col>
            </Row>

            <Row>
                <Col lg={8} className="">
                    <div className="card-admin p-4">
                        <p className="title-card">Przegląd na dzień <span className="fw-bold">
                            {formatDate(providedDate, 'LL', 'pl')}
                        </span></p>
                        <OverviewWidget date={providedDate} refresh={refresh}/>
                    </div>
                    <div className="card-admin p-4">
                        <p className="title-card">Przyjazdy</p>
                        <UpcomingArrivalsWidget date={providedDate} refresh={refresh}/>
                    </div>
                    <div className="card-admin p-4">
                        <p className="title-card">Rezerwacje, które wpłynęły do systemu</p>
                        <LatestReservationsWidget date={providedDate} refresh={refresh}/>
                    </div>
                </Col>
                <Col lg={4} className="">
                    {/*<Col className="card-admin p-4">*/}
                    {/*    <p className="title-card">Kalendarz</p>*/}
                    {/*    <CalendarWidget/>*/}
                    {/*</Col>*/}
                    {/*<Col className="card-admin p-4">*/}
                    {/*    <p className="title-card">Status rezerwacji</p>*/}
                    {/*    <StatusWidget/>*/}
                    {/*</Col>*/}
                    <Col className="card-admin p-4">
                        <p className="title-card">Goście na schronisku</p>
                        <GuestListWidget date={providedDate} refresh={refresh}/>
                    </Col>
                </Col>
            </Row>
        </div>
    );
}

export default Dashboard;