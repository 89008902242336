import React, {Component, useEffect, useRef} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {Accordion, Col, Image, Row, useAccordionButton} from "react-bootstrap";
import "./reservationSuccess.scss";
import CalendarSuccess from "../datepicker/CalendarSuccess";
import {formatDate} from "react-day-picker/moment";
import {Trans, withTranslation} from "react-i18next";

class ReservationSuccessComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: null,
            lastName: null,
            reservationStart: null,
            reservationEnd: null,
            howManyPeople: null,
            amountToPay: null,
            amountWithDiscount: null,
            nights: null,
            isOpen: false,
            cart: [],
        }
    }

    componentDidMount() {
        let reservation = JSON.parse(sessionStorage.getItem("reservation"));
        try {
            console.log(reservation.roomProducts)
            this.setState({
                firstName: reservation.firstName,
                lastName: reservation.lastName,
                howManyPeople: reservation.howManyPeople,
                phoneNumber: reservation.phoneNumber,
                reservationStart: reservation.reservationStart,
                reservationEnd: reservation.reservationEnd,
                amountToPay: reservation.amountToPay,
                email: reservation.email,
                extraInformation: reservation.extraInformation,
                nights: reservation.nights,
                cart: reservation.roomProducts,
            })
        } catch (e) {
            console.log("no data in local storage")
            this.props.history.push("/")
        } finally {

        }

    }

    closeModal = () => {
        this.setState({
            isOpen: false
        })
    }

    render() {

        function CustomCarousel(props) {

            const slider = useRef(null);
            let isDown = useRef(false);

            let startX = useRef(null);
            let scrollLeft = useRef(null);

            useEffect(() => {
                if (slider && slider.current) {
                    let sliderRef = slider.current;
                    sliderRef.addEventListener("mousedown", one);
                    sliderRef.addEventListener("mousedown", two);
                    sliderRef.addEventListener("mouseleave", three);
                    sliderRef.addEventListener("mouseup", four);
                    sliderRef.addEventListener("mousemove", five);

                    return () => {
                        sliderRef.removeEventListener("mousedown", one);
                        sliderRef.removeEventListener("mousedown", two);
                        sliderRef.removeEventListener("mouseleave", three);
                        sliderRef.removeEventListener("mouseup", four);
                        sliderRef.removeEventListener("mousemove", five);
                    };
                }
            }, []);

            function one(e) {
                isDown.current = true;
                startX.current = e.pageX - slider.current.offsetLeft;
                scrollLeft.current = slider.current.scrollLeft;
            }

            function two(e) {
                isDown.current = true;
                startX.current = e.pageX - slider.current.offsetLeft;
                scrollLeft.current = slider.current.scrollLeft;
            }

            function three() {
                isDown.current = false;
            }

            function four() {
                isDown.current = false;
            }

            function five(e) {
                if (!isDown.current) return;
                e.preventDefault();
                const x = e.pageX - slider.current.offsetLeft;
                const walk = x - startX.current;
                slider.current.scrollLeft = scrollLeft.current - walk;
            }


            return (
                <>
                    <div className="items pb-2" ref={slider}>
                        {props.children}
                    </div>
                    {props.children.length > 3 ?
                        <div onClick={(e) => slider.current.scrollLeft += 100}
                             className="carousel-rooms"> {'>'} </div>
                        : null}
                </>
            );
        }

        function CustomToggle({children, eventKey}) {
            const decoratedOnClick = useAccordionButton(eventKey, () =>
                console.log('totally custom!'),
            );

            return (
                <div
                    className="cursor-pointer link-primary"
                    onClick={decoratedOnClick}
                >
                    {children}
                </div>
            );
        }

        return (
            <div className="reservation-success">

                <div className="mb-4">
                    <Row>
                        <Col sm={12} xl={8}>

                            <div className="reservation mt-2 mb-4">
                                <h1 className="fw-bold" id=""><Trans i18nKey='ReservationSuccess_1'>Szanowny/a </Trans>
                                    <span
                                        className="text-success">{this.state.firstName} {this.state.lastName}</span>
                                </h1>
                                <span>
                                    <Trans i18nKey='ReservationSuccess_2'>
                                        Gratulujemy, rezerwacja przebiegła pomyślnie.
                                        Liczymy, że będzie to dla Ciebie przyjemny pobyt.
                                        Potwierdzenie rezerwacji zostało przesłane na podany adres e-mail.
                                    </Trans>
                                    <br/>
                                    <u className="fw-bold">
                                        <Trans i18nKey='ReservationSuccess_3'>
                                        Bardzo prosimy o zapoznanie się ze szczegółami.
                                        </Trans>
                                    </u>
                                </span>
                            </div>

                            <div className="pt-4 reservation mb-4">
                                <div><Trans i18nKey='ReservationSuccess_4'>Okres podróży</Trans></div>
                                <h4><Trans i18nKey='ReservationSuccess_5'>od</Trans> {formatDate(this.state.reservationStart, 'LL', this.props.t('lang'))}<> </>
                                    <Trans i18nKey='ReservationSuccess_6'>do</Trans> {formatDate(this.state.reservationEnd, 'LL', this.props.t('lang'))} ({this.state.nights} {this.state.nights === 1 ? this.props.t('noc') : this.state.nights < 5 ? this.props.t('noce') : this.props.t('nocy')})</h4>

                                <Row className="my-4">
                                    <Col className="d-flex align-items-center">
                                        <div style={{height: "100px", width: "80px"}}
                                             className="me-3 d-flex flex-column align-items-center justify-content-center text-center color-brand-secondary-bg border box-rounded-sm">
                                            <h5 className="m-0">{formatDate(this.state.reservationStart, 'MMM', this.props.t('lang'))}</h5>
                                            <h3 className="m-0">
                                                <strong>{formatDate(this.state.reservationStart, 'DD', this.props.t('lang'))}</strong>
                                            </h3>
                                        </div>
                                        <div><Trans i18nKey='checkin'>Zameldowanie</Trans> <strong>{formatDate(this.state.reservationStart, 'dddd', this.props.t('lang'))}</strong>
                                            <br/> <Trans i18nKey='ReservationSuccess_5'>od
                                                godziny</Trans> <strong>13:00</strong>
                                        </div>
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        <div style={{height: "100px", width: "80px"}}
                                             className="me-3 d-flex flex-column align-items-center justify-content-center text-center color-brand-secondary-bg border box-rounded-sm">
                                            <h5 className="m-0">{formatDate(this.state.reservationEnd, 'MMM', this.props.t('lang'))}</h5>
                                            <h3 className="m-0">
                                                <strong>{formatDate(this.state.reservationEnd, 'DD', this.props.t('lang'))}</strong>
                                            </h3>
                                        </div>
                                        <div><Trans i18nKey='checkout'>Wymeldowanie</Trans> <strong>{formatDate(this.state.reservationEnd, 'dddd', this.props.t('lang'))}</strong>
                                            <br/> <Trans i18nKey='ReservationSuccess_6'>do
                                                godziny</Trans> <strong>10:00</strong>
                                        </div>
                                    </Col>

                                </Row>

                                <Accordion flush>
                                    <Accordion.Item eventKey="0">
                                        <div>
                                            <CustomToggle eventKey="0"><Trans i18nKey='ReservationSuccess_7'>Zobacz w kalendarzu</Trans></CustomToggle>
                                        </div>
                                        <Accordion.Body>
                                            <div className="mt-4">
                                                <CalendarSuccess arrival={this.state.reservationStart}
                                                                 departure={this.state.reservationEnd}/>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>

                            <div className="reservation">
                                <h4 id="wybor"
                                    className=""><Trans i18nKey='ReservationSuccess_8'>Wybrano</Trans> {this.state.cart.length} {this.state.cart.length === 1 ? this.props.t('pokoj') : this.state.cart.length < 5 ? this.props.t('pokoje') : this.props.t('pokoi')}</h4>
                                <p>
                                    <strong>{this.state.howManyPeople}</strong> {this.state.howManyPeople === 1 ? this.props.t('osoba') : this.state.howManyPeople < 5 ? this.props.t('osoby') : this.props.t('osob')}
                                    <> • </><Trans i18nKey='ReservationSuccess_9'>Pokoje</Trans> nr <strong>{this.state.cart.map((room, i) => {
                                    return <>{(room.room.roomNumber)} {i < this.state.cart.length - 1 ? "|" : ""} </>
                                })}</strong>
                                </p>

                                <div className="position-relative align-items-center justify-content-end ">
                                    <div className="overflow-hidden">
                                        <Row>
                                            <CustomCarousel>
                                                {this.state.cart.map(room => {
                                                    return (
                                                        <Col xs={12} sm={8} md={6}
                                                             lg={this.state.cart.length === 1 ? 12 : this.state.cart.length === 2 ? 6 : 4}
                                                             className={this.state.cart.length > 2 ? "card-room-info pe-2" : "pe-2"}>
                                                            <div className="card ">
                                                                <Image className="box-rounded-sm img-cover"
                                                                       style={{height: "300px"}}
                                                                       src={"/" + room.room.imgUrl}
                                                                       alt="Bologna"/>
                                                                <div
                                                                    style={{backgroundImage: "linear-gradient(#ff000000, rgb(0 0 0 / 55%))"}}
                                                                    className="card-img-overlay box-rounded-sm text-white d-flex flex-column justify-content-end">
                                                                    <h6 className="card-title fw-bold m-0">{this.props.t(room.room.description)}</h6>
                                                                    <p className="card-text m-0">
                                                                        <span><Trans i18nKey='ReservationSuccess_10'>numer pokoju</Trans> {room.room.roomNumber} • {room.numberOfPeople} {room.numberOfPeople === 1 ? this.props.t('osoba') : room.numberOfPeople < 5 ? this.props.t('osoby') : this.props.t('osob')}</span>
                                                                    </p>
                                                                    <div className="link d-flex h4 mt-2 fw-normal">
                                                                        <strong
                                                                            className="">{room.room.pricePerPeron * room.numberOfPeople * this.state.nights} zł <small
                                                                            className="fw-normal"> / <Trans i18nKey='ReservationSuccess_11'>łącznie</Trans></small></strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })}
                                            </CustomCarousel>
                                        </Row>
                                    </div>
                                    {/*{this.state.cart.length > 3 ?*/}
                                    {/*    <div onClick={(e) => this.state.slider.current.scrollLeft += 100}*/}
                                    {/*         className="carousel-rooms"> {'>'} </div>*/}
                                    {/*    : null}*/}
                                </div>
                            </div>


                        </Col>

                        <Col>
                            <div
                                className=" border shadow-xl box-rounded-sm bg-white">
                                <div className=" p-4">
                                    <h4 className="pb-3"><Trans i18nKey='ReservationSuccess_12'>Podsumowanie płatności</Trans></h4>
                                    <p><Trans i18nKey='ReservationSuccess_13'>Całkowita kwota</Trans> <strong>{this.state.amountToPay}</strong> zł</p>
                                    <p><Trans i18nKey='ReservationSuccess_14'>Zadatek wynosi</Trans> <strong>{this.state.amountToPay / 2}</strong> zł</p>
                                    <p className="text-decoration-underline"><Trans i18nKey='ReservationSuccess_15'>Kwota do uregulowania na
                                        schronisku</Trans> <strong>{this.state.amountToPay / 2}</strong> zł</p>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
                <div className="pt-4 reservation mb-4">
                    <h4><Trans i18nKey='ReservationSuccess_16'>Ważne informacje dotyczące Państwa rezerwacji</Trans></h4>
                    <p>
                        <Trans i18nKey='ReservationSuccess_17'>
                            Dziękujemy za dokonanie rezerwacji w Schronisku Markowe Szczawiny, poniżej
                            zamieszczamy istotne warunki informacyjne.
                        </Trans>
                    </p>

                    <div className="row">

                        <div className="col-sm-12">
                            <p><strong><Trans i18nKey='ReservationSuccess_18'>Istotne informacje</Trans></strong></p>
                            <p><Trans i18nKey='ReservationSuccess_19'>Zmiana rezerwacji jest możliwa wyłącznie na okres tygodnia przed przybycie
                                do schroniska, po tym terminie zadatek ulega przepadkowi. W przypadku
                                większych kwot prosimy o kontakt telefoniczny lub mailowy.</Trans></p>
                            <p><Trans i18nKey='ReservationSuccess_20'>Zadatek wpłacony na poczet noclegu, jest kwotą bezzwrotną.
                                Nie zwracamy zadatków ze względów złej pogody itp.
                                Prosimy o przemyślane dokonywanie rezerwacji.</Trans></p>
                            <p><Trans i18nKey='ReservationSuccess_21'>Doba rozpoczyna się od godziny 13:00, przed tą godziną możemy państwu zaoferować
                                skorzystanie z przechowalni bagażu. Koniec doby natomiast jest następnego dnia o
                                godzinie 10:00.</Trans></p>
                            <p><Trans i18nKey='ReservationSuccess_22'>Recepcja działa w godzinach między 8-20. W przypadku sytuacji dotarcia do schroniska
                                po tej godzinie prosimy o wcześniejszy kontakt ze schroniskiem.</Trans></p>
                            <p><Trans i18nKey='ReservationSuccess_23'>Obowiązujące zniżki na schronisku są odliczane na miejscu po wcześniejszym okazaniu
                                odpowiedniego dokumentu. Akceptowane przez schronisko zniżki znajdą państwo w podanym
                                linku:</Trans> <a target="_blank" rel="noreferrer"
                                          href="https://markoweszczawiny.pttk.pl/oferta/pokoje">link</a>
                            </p>
                        </div>
                    </div>
                </div>


                <Modal show={this.state.isOpen} onHide={this.closeModal}>
                    <div className="p-4 text-center">
                        <h4>Gratulujemy dokonanego wyboru!</h4>
                        <p>Liczymy, ze będzie to udany pobyt, bardzo prosimy o zapoznanie się z regulaminem
                            schroniska, który został również wysłany na podany adres e-mail.</p>
                        <div className="row justify-content-around">
                            <div className="co-3 text-center">
                                <Button variant="primary" size="lg" block
                                        onClick={this.closeModal}>
                                    Zrozumiałem
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(ReservationSuccessComponent);
