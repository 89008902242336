import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Alert} from "react-bootstrap";
import useToastContext from "../../../nativeComponents/useToastContext";
import SettingsService from "../../../../service/SettingsService";
import {useMutation, useQueryClient} from "@tanstack/react-query";

interface Props {
    uuid: string;
    onClose: () => void
}

const DeleteRuleModal = ({uuid, onClose}: Props) => {

    const [show, setShow] = useState<boolean>(false);

    const addToast = useToastContext();

    const setClose = () => setShow(false);

    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: SettingsService.deleteRule,
        onSuccess: () => {
            // Handle successful deletion
            addToast({text: "Usunięto!", variant: true});
            setShow(false);
            onClose();
            queryClient.invalidateQueries(['settingsRulesAdmin']);
        },
        onError: () => {
            addToast({text: "Nie udało się wykonać operacji!", variant: false});
        }
    });

    const handleDelete = () => {
        mutate(uuid)
    };


    return (
        <>
            <Button variant="secondary" onClick={() => setShow(true)}>Usuń</Button>


            <Modal size="sm" show={show} onHide={setClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Czy chcesz usunąć wybrane pole?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pt-3">
                        <Alert variant="danger">
                            Operacja jest nieodwracalna.
                        </Alert>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={setClose} variant="secondary">Nie</Button>
                    <Button disabled={isLoading} onClick={handleDelete}>Tak</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteRuleModal;