import {
    FaBookmark,
    FaCalendar, FaClock,
    FaCreditCard,
    FaFlag,
    FaIdCard,
    FaKey,
    FaMoon, FaPhone,
    FaSun, FaTruck,
    FaUsers, FaWrench
} from "react-icons/all";


export const ICONS = {
    CalendarIcon: FaCalendar,
    BookmarkIcon: FaBookmark,
    CreditCardIcon: FaCreditCard,
    FlagIcon: FaFlag,
    IdentificationIcon: FaIdCard,
    KeyIcon: FaKey,
    MoonIcon: FaMoon,
    SunIcon: FaSun,
    UsersIcon: FaUsers,
    WrenchScrewdriverIcon: FaWrench,
    TruckIcon: FaTruck,
    PhoneIcon: FaPhone,
    ClockIcon: FaClock
};