import React, {Component} from 'react';
import AuthService from "../../service/AuthService";
import AdminPanelService from "../../service/WorkerService";
import WorkerService from "../../service/WorkerService";
import {Button, Col, Row} from "react-bootstrap";
import {FaEdit, FaTrashAlt} from "react-icons/all";
import {NavLink} from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';

class WorkerListComponent extends Component {
    state = {
        currentUser: '',
        workers: [],
        showAdminButtons: false,
    }

    componentDidMount() {
        AdminPanelService.getWorkers().then(x => {
            this.setState({
                workers: x.data
            })
            }).catch(e => {
            console.log("Brak uprawnień")

        }).catch(e => console.log(e))

        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
                showAdminButtons: user.roles.includes("ROLE_OWNER"),
            });
        }
    }


    editWorkerHandler = (id) => {
        this.props.history.push(`/admin/pracownicy/update-worker/${id}`);
    }
    deleteWorkerHandler = (id) => {
        if (window.confirm("Czy na pewno chcesz usunąć tego pracownika?")) {

            if(id === this.state.currentUser.id){
                alert("Nie mozesz usunąć sam siebie!")
            } else {
                WorkerService.deleteWorker(id, this.state.currentUser.id).then(response => console.log(response.status))
            }



            this.setState({
                workers: this.state.workers.filter(worker => worker.id !== id)
            })
        }
    }


    render() {
        const columns = [
            {
                dataField: "firstName",
                text: "Imię",
                sort: true
            },
            {
                dataField: "lastName",
                text: "Nazwisko",
                sort: true
            },
            {
                dataField: "email",
                text: "Email",
                sort: true
            },
            {
                dataField: 'action',
                text: 'Akcje',
                formatter: (cellContent, row) => (
                    this.state.showAdminButtons ?
                        (
                            <div>
                            <span className="action-icon"
                                  title="Edytuj"
                                  onClick={() => this.editWorkerHandler(row.id)}
                            >
                                <FaEdit/>
                            </span>

                                {
                                    this.state.currentUser.id === row.id ? "" :
                                        <span className="action-icon"
                                              title="Usuń"
                                              onClick={() => this.deleteWorkerHandler(row.id)}>
                                            <FaTrashAlt/>
                                        </span>
                                }

                            </div>
                        ) : null

                )
            },
        ];
        return (
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="admin-panel-title mb-4">Pracownicy</div>
                    </Col>
                </Row>
                <div className="card-admin p-4">
                    {this.state.showAdminButtons ?
                        (<Col xs={12} className="mb-3 d-flex justify-content-end">
                            <NavLink to="/admin/pracownicy/register">
                                <Button variant="primary">Dodaj pracownika</Button>
                            </NavLink>
                        </Col>) : null
                    }
                    <div className="mt-3">
                        <BootstrapTable bootstrap4 keyField='id' data={this.state.workers} columns={columns} noDataIndication={"Brak uprawnień"}/>
                    </div>
                </div>
            </div>
        );


    }

}

export default WorkerListComponent;
