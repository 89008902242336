import React from 'react';
import Amenity from "./Amenity";
import AddAmenityModal from "./AddAmenityModal";
import {AmenitiesDTO} from "../../../../interfaces/settings.interface";
import SettingsService from "../../../../service/SettingsService";
import {Stack} from "react-bootstrap";
import {useQuery} from "@tanstack/react-query";

const Amenities = () => {

    const {
        // status,
        isLoading,
        isFetching,
        // isError,
        data,
        error
    } = useQuery<AmenitiesDTO[]>(['settingsAmenitiesAdmin'], async () => {
            return await SettingsService.getAmenities();
        }
    );


    return (
        <section id="Udogodnienia">
            <div className="d-flex justify-content-between align-items-center mb-4 bg-white py-2">
                <div className="h5 fw-bold mb-1">Udogodnienia</div>
                <div className="d-flex align-items-center gap-3">
                    <AddAmenityModal/>
                </div>
            </div>

            <div>
                {
                    !data && <div>Brak dodanych pól. Kliknij na plus, aby dodać nowe pole.</div>
                }
                <Stack gap={3}>
                    {
                        data?.map((amenity) => {
                            return (
                                <Amenity key={amenity.uuid} description={amenity.description} name={amenity.name}
                                         uuid={amenity.uuid}/>
                            )
                        })
                    }
                </Stack>
            </div>
        </section>
    );
};

export default Amenities;