import React, {useState} from 'react';
import {Offcanvas} from "react-bootstrap";
import {CgMenu} from "react-icons/all";
import {Link} from "react-router-dom";
import "./MenuMobileComponent.css";
import {Trans, useTranslation} from "react-i18next";

function MenuMobileComponent() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const langs = {
        pl: {nativeName: 'polski', icon: "../../images/pl-icon.svg", alt: "lang-pl"},
        en: {nativeName: 'English', icon: "../../images/uk-icon.svg", alt: "lang-en"}
    }
    const {i18n} = useTranslation();

    return (
        <>
            <div className="cursor-pointer nav-toggler d-flex text-dark" onClick={handleShow}><CgMenu/></div>

            <Offcanvas show={show} onHide={handleClose} placement={"bottom"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="list-group list-group-flush">
                        <li className="list-item my-2">
                            <Link className="text-black"
                                  onClick={handleClose}
                                  to="/">
                                <Trans i18nKey="Navbar_1">Strona domowa</Trans>
                            </Link>
                        </li>
                        <li className="list-item">
                            <a className="text-black" target="_blank" rel="noreferrer"
                               href="https://www.facebook.com/Schronisko-G%C3%B3rskie-PTTK-Markowe-Szczawiny-223432244393822">
                                <Trans i18nKey="Navbar_2">Aktualności</Trans>
                            </a>
                        </li>

                        {
                            Object.keys(langs).map((lng) => (

                                <li key={lng} className="list-item" onClick={() => i18n.changeLanguage(lng)}>
                                    <div className="icon-header">
                                        <img
                                            alt={langs[lng].alt}
                                            src={langs[lng].icon}
                                            className="img-fluid"
                                        />
                                    </div>
                                    <p className="m-0 ps-3">{langs[lng].nativeName}</p>
                                </li>

                            ))
                        }
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}


export default MenuMobileComponent;
