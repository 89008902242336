import React, {useState} from 'react';
import AddBlockadeModal from "./AddBlockadeModal";
import BlockadesTable from "./BlockadesTable";
import Modal from "react-bootstrap/Modal";

const BlockedDatesModal = ({room}: any) => {

    const [show, setShow] = useState<boolean>(false);
    const [reFetch, setEeFetch] = useState<boolean>(false);
    const handleOpen = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            <button onClick={handleOpen} className="!rounded-lg btn btn-primary">
                Blokady
            </button>

            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>Blokada dat</Modal.Header>
                <Modal.Body>
                    <div className="mt-6">
                        <div className="d-flex gap-1 mb-2">
                            <div>{room.description}</div>
                            <span className="fw-bold">nr {room.roomNumber}</span>
                        </div>
                        <BlockadesTable setReFetch={setEeFetch} reFetch={reFetch} roomId={room.id}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClose}>Zamknij</button>
                    <AddBlockadeModal setReFetch={setEeFetch} reFetch={reFetch} roomId={room.id}/>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BlockedDatesModal;
