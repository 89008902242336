import React, {useEffect, useState} from 'react';
import {Dropdown} from "react-bootstrap";
import {FaBell, FaUser, IoMdArrowDropdown} from "react-icons/all";
import Nav from "react-bootstrap/Nav";
import {NavLink, useHistory} from "react-router-dom";
import AuthService from "../../service/AuthService";
import WorkerService from "../../service/WorkerService";
import {Trans} from "react-i18next";

const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <span
        className="cursor-pointer"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </span>
));

const ProfilePanel = (props) => {

    const [currentUser, setCurrentUser] = useState(undefined);
    const [notification, setNotification] = useState(0);

    const history = useHistory();


    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
        if (user) {
            let temp;
            WorkerService.getNotifications(user.id)
                .then(worker => {
                    setNotification(worker.data)
                    temp = worker.data
                })

                return history.listen((location) => {
                    if (location.pathname.split("/").pop() === "rezerwacje" && temp !== 0) {
                        WorkerService.clearNotifications(user.id).then(r => console.log("wyczyszczono powiadomienia"));
                        setNotification(0)
                    }
                })
        }
    }, [history])


    const logOut = () => {
        AuthService.logout();
        setCurrentUser(undefined);
        history.push("/");
    }


    return (
        <>
            {currentUser ? (
                <Nav.Item className="d-flex align-items-center">
                    <div className="p-0 mx-3">
                        <div title={notification + " nowe rezerwacje"} className="notification">
                            <span
                                className="notification-badge d-flex justify-content-center align-items-center">
                                {notification}
                            </span>
                            <FaBell className="text-black"/>
                        </div>
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components">
                            <div
                                className="profile bg-white border box-hover d-flex justify-content-between align-items-center ps-1 pe-2">
                                <div
                                    className="bg-black profile-image d-flex justify-content-center align-items-center">
                                    <FaUser/>
                                </div>
                                <p className="m-0 px-2">{currentUser.username}</p>
                                <IoMdArrowDropdown/>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="border-0 shadow">
                            <p className="px-4 pt-2 fw-bold">Witaj!</p>
                            <Dropdown.Item eventKey="1" onClick={() => history.push("/admin")}>Panel
                                rezerwacji</Dropdown.Item>
                            {currentUser.roles[0] === "ROLE_OWNER" ?
                                <>
                                    <Dropdown.Item eventKey="2"
                                                   onClick={() => history.push(`/admin/pracownicy/update-worker/${currentUser.id}`)}>Moje
                                        konto</Dropdown.Item>
                                    <Dropdown.Item eventKey="3"
                                                   onClick={() => history.push("/admin/pracownicy")}>Ustawienia</Dropdown.Item>
                                </> : ""}

                            <Dropdown.Item eventKey="4" onClick={logOut}>Wyloguj</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav.Item>
            ) : (<Nav>
                <NavLink className="profile border d-flex justify-content-center align-items-center box-hover" to="/login"><Trans i18nKey="Navbar_profile">Zaloguj
                    się</Trans></NavLink>
            </Nav>)}
        </>
    );
};

export default ProfilePanel;
