import React from 'react';
import ReservationProgressBar from "./ReservationProgressBar";
import {Route} from "react-router-dom";
import ResComp from "./ResComp";
import ReservationSuccessComponent from "../reservationSuccess/ReservationSuccessComponent";
import FooterComponent from "../footer/FooterComponent";
import ReservationPaymentPage from "../PaymentApi/ReservationPaymentPage";

const ResNav = () => {

    return (
        <div className="bg-white">

            <div className="bg-white pt-4 pb-5">
                <div className="container"><ReservationProgressBar/></div>
            </div>

            <div className="container pb-5">
                <Route exact path="/make-reservation/1" component={ResComp}/>
                <Route exact path="/make-reservation/2" component={ReservationPaymentPage}/>
                <Route exact path="/make-reservation/3" component={ReservationSuccessComponent}/>
            </div>
            <div className="color-brand-secondary-bg">
                <FooterComponent/>
            </div>
        </div>
    );
};


export default ResNav;