import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import 'moment/locale/pl';
import MomentLocaleUtils from "react-day-picker/moment";
import {HiArrowSmLeft, HiArrowSmRight} from "react-icons/all";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";

const CalendarSuccess = ({arrival, departure}) => {

    const { t } = useTranslation();

    const disabledDays = [
        new Date(arrival),
        new Date(departure),
        {
            before: new Date(departure),
            after: new Date(arrival)
        },
    ];

    const birthdayStyle = `.DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
                                color: black;
                                background-color: var(--brand-secondary);
                                border-color: #c3e6cb;
                                border-radius: 0;
                            }
                            .DayPicker {
                                display: flex;
                            }
                            .DayPicker-Day {
                                width: 50px;
                                height: 50px;
                            }
                            .DayPicker-wrapper {
                                width: 100%;
                            }
                            .DayPicker-Months {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                overflow: unset;
                                max-height: 100%;
                            }
                            .DayPicker-Month {
                                display: block;
                            }
                            .DayPicker-Day--start:not(.DayPicker-Day--outside) {
                                color: white!important;
                                background-color: black!important;
                                border-radius: 50% 0 0 50%!important;
                             }
                            .DayPicker-Day--end:not(.DayPicker-Day--outside) {
                                color: white!important;
                                background-color: black!important;
                                border-radius: 0 50% 50% 0!important;
                            }
                            .DayPicker-Caption {
                                text-align: center;
                            }
                            `;
    const modifiersStyles = {
        birthday: {
            color: 'white',
            backgroundColor: '#ffc107',
        },
        thursdays: {
            color: '#ffc107',
            backgroundColor: '#fffdee',
        },
        cellStyle: {
            height: 50,
            width: 50,
            position: 'relative',
        }
    };

    const modifiers = {
        start: new Date(arrival),
        end: new Date(departure),
    };


    function Navbar({
                        onPreviousClick,
                        onNextClick,
                    }) {
        const styleLeft = {
            float: 'left',
        };
        const styleRight = {
            float: 'right',
        };
        return (
            <div style={{left: "0", right: "0"}} className="position-absolute d-flex justify-content-between w-100 pt-2 px-4">
                <Button variant="counter" style={styleLeft} onClick={() => onPreviousClick()}>
                    <HiArrowSmLeft/>
                </Button>
                <Button variant="counter" style={styleRight} onClick={() => onNextClick()}>
                    <HiArrowSmRight/>
                </Button>
            </div>
        );
    }

    return (
        <>
            <style>{birthdayStyle}</style>
            {arrival ?
            <DayPicker navbarElement={<Navbar />}
                modifiers={modifiers} numberOfMonths={2} initialMonth={new Date(arrival)} locale={t('lang')} localeUtils={MomentLocaleUtils} disabledDays={disabledDays}
                       modifiersStyles={modifiersStyles}/>
            : ""}
        </>
    );
};

export default CalendarSuccess;
