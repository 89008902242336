import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import ReservationService from "../../service/ReservationService";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import useToastContext from "../nativeComponents/useToastContext";

const GuestList = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [guests, setGuests] = useState([]);
    const [providedDate, setProvidedDate] = useState(moment().format("YYYY-MM-DD"));

    const [checked, setChecked] = useState(false);


    const fetchData = async () => {
        setIsLoading(true);
        const {data} = await ReservationService.getCurrentGuests({providedDate: providedDate});
        return data;
    }

    useEffect(() => {
        fetchData().then(response => {
            setGuests(response);
            setChecked(response.arrived);
            setIsLoading(false)
        }).catch(e => console.log(e))
    }, [providedDate, checked])


    const addToast = useToastContext();

    const handleSwitch = (id) => {
        setChecked(!checked)
        ReservationService.arrivedStatus(id, checked).then(r => {
            console.log(r)
            addToast({text: "Pomyślnie zmieniono status rezerwacji!", variant: true})

        }).catch(e => {
            console.error(e)
            addToast({text: "Operacja zakończona niepowodzeniem!", variant: false})
        })
    }

    const sort = (a, b) => {
        return a - b
    }

    const columns = [
        {
            dataField: "arrived",
            text: "Obecność",
            formatter: (cellContent, row) => (
                <Form.Check
                    checked={row.arrived}
                    type="switch"
                    id={`custom-switch-${row.reservationId}`}
                    label={row.arrived ? "Tak" : "Nie"}
                    onChange={() => handleSwitch(row.reservationId)}
                />
            ),
            sort: true
        },
        {
            dataField: "firstName",
            text: "Imię",
            sort: true
        },
        {
            dataField: "lastName",
            text: "Nazwisko",
            sort: true
        },
        {
            dataField: "alreadyPaid",
            text: "Zadatek",
            sort: true
        },
        {
            dataField: "numberOfCompanies",
            text: "Ilość osób",
            sort: true
        },
        {
            dataField: "roomNumbers",
            text: "Pokój",
            formatter: (cellContent, row) => (
                <span>{row.roomNumbers.sort(sort).join(", ")}</span>
            ),
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return b[0] - a[0];
                }
                return a[0] - b[0];
            },
            sort: true
        },
        {
            dataField: "startDate",
            text: "Pobyt od",
            sort: true
        },
        {
            dataField: "finishDate",
            text: "Pobyt do",
            sort: true
        },
        {
            dataField: "number",
            text: "Telefon",
            sort: true
        },
    ];


    const rowStyle = (row) => {
        let style = '';
        if (row.arrived) {
            style = 'table-success';
        }
        return style;
    };

    return (
        <div className="container-fluid">
            <Row>
                <Col>
                    <div className="admin-panel-title mb-4">Goście</div>
                </Col>
            </Row>
            <div className="card-admin p-4">
                <Row>
                    <Col>
                        <Form className="d-flex float-end">
                            <input
                                type="date"
                                id="selectDateAdmin"
                                placeholder="Data"
                                className="form-control buttons-lp me-2"
                                name="providedDate"
                                defaultValue={providedDate}
                                onChange={(e) => {
                                    setProvidedDate(e.target.value);
                                }}
                            />
                        </Form>
                    </Col>
                </Row>
                <div className="mt-3">
                    <BootstrapTable bootstrap4
                                    keyField='reservationId'
                                    data={guests}
                                    columns={columns}
                                    noDataIndication={isLoading === false ?
                                        "Brak gości na schronisku w podanym terminie " + providedDate :
                                        <div className="my-5"/>}
                                    loading={isLoading}
                                    overlay={overlayFactory({
                                        spinner: true,
                                        styles: {
                                            overlay: (base) => ({
                                                ...base,
                                                background: 'rgba(30, 40, 86, 0.5)'
                                            })
                                        }
                                    })}
                                    rowClasses={rowStyle}
                                    defaultSorted={!isLoading ? [{
                                        dataField: 'roomNumbers',
                                        order: 'desc'
                                    }] : null}
                    />
                </div>
            </div>
        </div>
    );
}

export default GuestList;