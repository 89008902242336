import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from 'react';
import {format, isAfter} from "date-fns";
import Modal from "react-bootstrap/Modal";
import {Col, Form, Row} from "react-bootstrap";
import RoomService from "../../../../service/RoomService";
import useToastContext from "../../../nativeComponents/useToastContext";

interface AddBlockadeModalProps {
    roomId: number;
    reFetch: boolean;
    setReFetch: Dispatch<SetStateAction<boolean>>;
}

const AddBlockadeModal = ({roomId, reFetch, setReFetch}: AddBlockadeModalProps) => {

    const [error, setError] = useState<boolean>(true);
    const [show, setShow] = useState<boolean>(false);

    const addToast = useToastContext();

    const [dates, setDates] = useState({
        dateFrom: '',
        dateTo: '',
    });

    useEffect(() => {
        if (isAfter(new Date(dates.dateFrom), new Date(dates.dateTo))) {
            setError(true);
        } else {
            setError(false);
        }
    }, [dates.dateFrom, dates.dateTo]);


    const onChangeHandler = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        setDates({...dates, [name]: value});
    }


    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        RoomService.blockRoomForSpecificDate({roomId, ...dates})
            .then((response) => {
                addToast({text: "Pomyślnie dodano blokadę!", variant: true});
                setReFetch(!reFetch);
                setShow(false);
            })
            .catch((error) => {
                console.log(error);
                addToast({text: "Nie udało się dodać blokady!", variant: false})
            });
        
    }

    return (
        <>
            <button onClick={() => setShow(true)} className="btn btn-primary">Dodaj blokade</button>

            <Modal show={show} onHide={() => setShow(false)}>
                <form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>Dodaj blodadę</Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <label className="w-100">
                                    Początek blokady
                                    <Form.Control className="w-100" min={format(new Date(), 'yyyy-MM-dd')}
                                                  name="dateFrom"
                                                  value={dates.dateFrom} onChange={onChangeHandler}
                                                  type="date"></Form.Control>
                                </label>
                            </Col>
                            <Col>
                                <label className="w-100">
                                    Koniec blokady
                                    <Form.Control className="w-100" disabled={dates.dateFrom === ''}
                                                  min={dates.dateFrom}
                                                  name="dateTo" value={dates.dateTo} onChange={onChangeHandler}
                                                  type="date"></Form.Control>
                                </label>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <button disabled={error} type="submit" className="btn btn-primary">Dodaj</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default AddBlockadeModal;
