import React, {useEffect, useState} from 'react';
import {Container, Nav} from "react-bootstrap";
import {Trans} from "react-i18next";

const StickyTabNav = () => {

    const [pos, setPos] = useState(true)


    useEffect(() => {
        document.addEventListener("scroll", e => {
            let scrolled = document.scrollingElement.scrollTop;
            if (scrolled >= 150 || scrolled === null) {
                setPos(false)
            } else {
                setPos(true)
            }
        })
    }, [])


    const ScrollTo = (id) => {
        const yOffset = -100;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

    return (
        <>
            {pos === true ? null : <div style={{left: "0"}} className="sticky-tab-nav border-bottom sticky-top bg-white position-fixed w-100 shadow-xl">
                <Container>
                    <Nav className="py-3">
                        <Nav.Item>
                            <Nav.Link onClick={() => window.scrollTo(0, 0)}><Trans i18nKey='StickyTabNav_1'>Wybór</Trans></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={() => ScrollTo("udogodnienia")}><Trans i18nKey='StickyTabNav_2'>Udogodnienia</Trans></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={() => ScrollTo("informacje")}><Trans i18nKey='StickyTabNav_3'>Ważne informacje</Trans></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={() => ScrollTo("dodatki")}><Trans i18nKey='StickyTabNav_4'>Dodatki</Trans></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={() => ScrollTo("dane")}><Trans i18nKey='StickyTabNav_5'>Twoje dane</Trans></Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Container>
            </div>}
        </>
    );
};

export default StickyTabNav;
