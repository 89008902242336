import React, {useState} from 'react';
import DeleteRuleModal from "./DeleteRuleModal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {RulesDTO} from "../../../../interfaces/settings.interface";
import SettingsService from "../../../../service/SettingsService";
import useToastContext from "../../../nativeComponents/useToastContext";
import {ICONS} from "../../../../utils/IconsMapper";
import ChooseIconModal from "./ChooseIconModal";
import {AxiosError} from "axios";
import {useMutation, useQueryClient} from "@tanstack/react-query";


interface Props extends RulesDTO {
    children: any;
}


const EditRuleModal = ({uuid, icon, name, description, children}: Props) => {

    const [show, setShow] = useState<boolean>(false);
    const [showIconModal, setShowIconModal] = useState<boolean>(false);
    const [field, setField] = useState<RulesDTO>({uuid, icon, name, description});

    const addToast = useToastContext();

    const setClose = () => setShow(false);

    const handleFieldChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        setField({...field, [name]: value});
    }


    const queryClient = useQueryClient();

    const {mutate, isLoading} = useMutation({
        mutationFn: SettingsService.updateRule,
        onSuccess: () => {
            addToast({text: "Operacja przebiegła pomyślnie!", variant: true});
            setShow(false);
            queryClient.invalidateQueries(['settingsRulesAdmin']);
        },
        onError: (error: AxiosError) => {
            addToast({text: "Nie udało się wykonać operacji!", variant: false});
        }
    });

    const handleAddField = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        mutate(field)
    };

    const IconComponent = ICONS[field.icon as keyof typeof ICONS];

    return (
        <>
            <Button onClick={() => setShow(true)} variant="dark" className="fw-bold">
                {children}
            </Button>

            <>
                <Modal closeButton={true} show={show} onHide={setClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edycja</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleAddField}>
                        <Modal.Body>
                            <div className="d-grid gap-3">
                                <div onClick={() => setShowIconModal(true)}>
                                    <div className="font-medium mb-2">Ikona</div>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                Zmień ikonę
                                            </Tooltip>
                                        }
                                    >
                                        <div
                                            className="rounded-3 bg-light shadow-sm d-flex justify-content-center align-items-center cursor-pointer"
                                            style={{height: "45px", width: "45px"}}
                                        >
                                            <IconComponent size={16}/>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <Form.Group>
                                    <Form.Label className="mb-2">Opis</Form.Label>
                                    <Form.Control as="textarea" value={field.description} name="description" onChange={handleFieldChange}
                                              rows={6}
                                              placeholder="Tu wpisz opis..."/>
                                </Form.Group>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                                <DeleteRuleModal onClose={setClose} uuid={uuid as string}/>
                                <Button disabled={isLoading || field.description.length < 1}
                                        type="submit">Zmień</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
                <ChooseIconModal currentIcon={field.icon} show={showIconModal} setClose={() => setShowIconModal(false)}
                                 onChange={handleFieldChange}/>
            </>

        </>
    );
};

export default EditRuleModal;