import React, {useEffect, useState} from 'react';
import ReservationService from "../../service/ReservationService";
import Button from "react-bootstrap/Button";

const EditReservationComponent = (props) => {


    const [parameters, setParameters] = useState({
        id: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        extraInformation: '',
    });


    useEffect(() => {
        ReservationService.getReservationById(props.match.params.id)
            .then((response) => {
                let reservation = response.data
                setParameters({
                    id: reservation.id,
                    firstName: reservation.firstName,
                    lastName: reservation.lastName,
                    email: reservation.email,
                    phoneNumber: reservation.phoneNumber,
                    extraInformation: reservation.extraInformation,
                })
                console.log(reservation)
            })
    },[])

    const updateReservationHandler = (e) => {
        e.preventDefault();

        const fetchData = async () => {
            const result = await ReservationService.updateReservation(parameters, parameters.id)
            console.log(result.status)

            props.history.push({
                pathname: '/admin/rezerwacje',
                state: { userEdit: true }
            })
        }

        fetchData();
    }

    const changeTypingValueHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setParameters({...parameters,[name]: value})
        console.log(parameters)
    }


    return (
        <>
            <div className="container card-admin p-4">
                <p className="title-card">Edycja rezerwacji <span className="text-primary">id: {parameters.id}</span></p>
                <form method="post">
                    <div className="row gy-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="firstName"> Imię </label>
                            <input type="text" id="firstName" placeholder="Wprowadź imię"
                                   className="form-control" name="firstName"
                                   value={parameters.firstName} onChange={changeTypingValueHandler}/>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="lastName"> Nazwisko </label>
                            <input type="text" id="lastName" placeholder="Wprowadź nazwisko"
                                   name="lastName" onChange={changeTypingValueHandler}
                                   value={parameters.lastName} className="form-control"/>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="phone"> Numer telefonu </label>
                            <input type="tel" id="phone" placeholder="Wprowadź numer" maxLength="9"
                                   className="form-control" name="phoneNumber"
                                   value={parameters.phoneNumber} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                   onChange={changeTypingValueHandler}/>
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="email"> Adres e-mail </label>
                            <input type="email" id="email" placeholder="Wprowadź e-mail" className="form-control"
                                   value={parameters.email} name="email" onChange={changeTypingValueHandler}/>

                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="extraInfo"> Dodatkowe info (opcjonalne) </label>
                            <input type="text" id="extraInfo"
                                   placeholder="Dodatkowe informacje (opcjonalnie)" className="form-control"
                                   value={parameters.extraInformation} name="extraInformation"
                                   onChange={changeTypingValueHandler}/>
                        </div>

                        <div className="form-group">
                            <Button onClick={updateReservationHandler}
                                    variant="primary"
                                    type="submit"
                                    className="me-2">Aktualizuj
                            </Button>
                            <Button onClick={props.history.goBack} variant="secondary">Wróć</Button>
                        </div>
                    </div>

                </form>
            </div>
        </>
    );
}

export default EditReservationComponent;
