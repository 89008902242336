import React from 'react';
import {Link} from "react-router-dom";
import {Navbar} from "react-bootstrap";
import {Trans} from "react-i18next";

const Menu = () => {
    return (
        <Navbar>
            <div className="justify-content-start d-flex">
                <Link
                    to="/"
                >
                    <div className="nav-link"><Trans i18nKey="Navbar_1">Strona domowa</Trans></div>
                </Link>
                <a target="_blank" rel="noreferrer"
                   href="https://www.facebook.com/Schronisko-G%C3%B3rskie-PTTK-Markowe-Szczawiny-223432244393822">
                    <div className="nav-link"><Trans i18nKey="Navbar_2">Aktualności</Trans></div>
                </a>
            </div>
        </Navbar>
    );
};

export default Menu;
