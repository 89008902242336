import React from 'react';
import {useQuery} from "@tanstack/react-query";
import ReservationService from "../../../../service/ReservationService";
import {Col, Row} from "react-bootstrap";

interface Props {
    month: string
    year: string
}

const PeopleByMonth = ({month, year}: Props) => {

    const {
        // status,
        isLoading,
        isFetching,
        data,
        // isError,
        error
    } = useQuery(['getPeopleByMonth', month, year], async () => {
            return await ReservationService.getNumberOfPeopleInExactMonth({
                month,
                year,
            })
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    return (
        <Col>
            <div className="overview-body">
                <p>{data}</p>
                <p>osób<br/>w tym miesiącu</p>
            </div>
        </Col>
    );
};

export default PeopleByMonth;