import React, {Component} from 'react';
import SockJsClient from 'react-stomp';
import './MessageStyle.css';
import AuthService from "../../service/AuthService";
import MessageService from "../../service/MessageService";
import {Button, Form, FormControl, InputGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FaChevronCircleDown, FaChevronCircleUp, FaCircle, FaPaperPlane} from "react-icons/all";

import moment from "moment";

class ChatComponent extends Component {
    chatContainer = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            messagesFromDataBase: [],
            content: "",
            firstName: "",
            lastName: "",
            localTime: "",
            localDate: "",
            currentUser: "",
            isOwnerAccount: false,
            showChat: false,
            likes: 0,
            likesId: null,
            titleNotification: null,
            showChatNotification: true
        }
    }


    scrollToBottom = () => {
        if (this.state.messagesFromDataBase.length !== 0)
            this.messagesEnd.scrollIntoView({block: "end", inline: "nearest"});
    }

    componentDidMount() {
        document.previousTitle = document.title;

        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isOwnerAccount: user.roles.includes("ROLE_OWNER"),
                showChat: JSON.parse(localStorage.getItem("msShowChat")),
            });
        }
        if (user) {
            MessageService.getMessages().then(res => {
                if (res.data.length !== 0) {
                    let index = res.data.length - 1;
                    this.setState({
                        messagesFromDataBase: res.data,
                        likes: res.data[index].likes,
                        likesId: res.data[index].userId,
                    })

                    console.log(res.data[index].likes)
                }

            })
        }
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }


    sendMessage = (e) => {
        e.preventDefault();
        if (this.state.content.length < 1) return;

        //TO SOCKET CONTROLLER
        this.clientRef.sendMessage('/app/user-all', JSON.stringify({
            firstName: this.state.currentUser.firstName,
            lastName: this.state.currentUser.lastName,
            userId: this.state.currentUser.id,
            content: this.state.content,
            likes: this.state.likes,
            localDate: moment().format('YYYY-MM-DD'),
            localTime: moment().format('HH:mm:ss'),
        }));

        // TO MESSAGE CONTROLLER -> DATABASE


        let message = {
            content: this.state.content,
            likes: this.state.likes,
            user: {
                id: this.state.currentUser.id
            }
        }
        MessageService.sendMessage(message)
            .then(res => {
                    console.log(res.status)
                }
            )
        this.setState({
            content: ''
        })

    };

    handleShow = () => {
        this.setState({showChat: !this.state.showChat})
        localStorage.setItem("msShowChat", JSON.stringify(!this.state.showChat))
    }

    onMessageReceived = (msg) => {
        console.log('New Message Received!!');
        this.setState({
            messagesFromDataBase: this.state.messagesFromDataBase.concat(msg),
            likesId: msg.userId,
            likes: msg.likes,
            titleNotification: this.state.titleNotification + 1,
            showChatNotification: true
        })


        if (this.state.likesId !== this.state.currentUser.id) {
            document.title = "(" + this.state.titleNotification + ") Nowa wiadomość"
        } else {
            document.title = document.previousTitle;
            this.setState({
                titleNotification: null,
            })
        }
    }


    displayMessages = () => {
        return <>
            {this.state.messagesFromDataBase.map((msg, key) => {
                return (
                    <div className={`row${this.state.currentUser.id === msg.userId ? " msg-mine" : " msg-other"} ${
                        (this.state.messagesFromDataBase[key - 1] !== undefined && this.state.messagesFromDataBase[key + 1] !== undefined) ?
                            (this.state.messagesFromDataBase[key + 1].userId === msg.userId) && (this.state.messagesFromDataBase[key - 1].userId !== msg.userId) ?
                                " first" : (this.state.messagesFromDataBase[key + 1].userId !== msg.userId) && (this.state.messagesFromDataBase[key - 1].userId === msg.userId) ?
                                    " last" : (this.state.messagesFromDataBase[key + 1].userId === msg.userId) && (this.state.messagesFromDataBase[key - 1].userId === msg.userId) ?
                                        " middle" : "" : this.state.messagesFromDataBase[key - 1] !== undefined && this.state.messagesFromDataBase[key - 1].userId === msg.userId ? " last" :
                                this.state.messagesFromDataBase[key + 1] !== undefined && this.state.messagesFromDataBase[key + 1].userId === msg.userId ? " first" : ""
                    }`} key={key}>
                        <>
                            {(this.state.messagesFromDataBase[key - 1] !== undefined && this.state.messagesFromDataBase[key - 1].userId !== msg.userId) ||
                            (this.state.messagesFromDataBase[key - 1] === undefined) ||
                            ((this.state.messagesFromDataBase[key - 1] !== undefined)
                                && moment(msg.localTime, "HH") - moment(this.state.messagesFromDataBase[key - 1].localTime, "HH") !== 0) ?
                                <span
                                    className="text-center mt-2">
                                    <small>
                                            {moment(`${msg.localTime} ${msg.localDate}`, "HH:mm:ss YYYY-MM-DD").calendar()}
                                        </small>
                                    </span> : null}

                            <div
                                className={this.state.currentUser.id === msg.userId ? "ms-auto break-word" : "break-word"}>
                                {this.state.messagesFromDataBase[key - 1] !== undefined && this.state.messagesFromDataBase[key - 1].userId !== msg.userId ?
                                    this.state.currentUser.id === msg.userId ?
                                        <div className="title1"><small>Ty</small></div> :
                                        <div className="title2"><small>{msg.firstName + " " + msg.lastName}</small>
                                        </div>
                                    : null}
                                <OverlayTrigger
                                    container={this.chatContainer}
                                    delay={{show: 300, hide: 0}}
                                    placement={msg.userId === this.state.currentUser.id ? "left" : "right"}
                                    overlay={
                                        <Tooltip className="chatTooltip"
                                                 id={`tooltip-${msg.userId === this.state.currentUser.id ? "left" : "right"}`}>
                                            <small
                                                className="mx-1">{moment(msg.localTime, "HH:mm:ss").format('LT')}</small>
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        className={`py-2 px-3 mb-1 msg-all msg-content`}>
                                        {msg.content.includes("https://") ?
                                            <a rel="noreferrer" target="_blank" href={msg.content}>{msg.content}</a> : msg.content}
                                        {/*{msg.content}*/}
                                    </div>

                                </OverlayTrigger>
                            </div>
                        </>
                    </div>)
            })}
        </>
    };

    render() {
        return (
            <div className="chat-admin">

                <div className="shadow-lg chat-header">

                    <div className="p-3 d-flex justify-content-between border chat-header bg-light">
                        <span className="me-3">Chat</span>
                        {this.state.likesId !== this.state.currentUser.id && this.state.messagesFromDataBase.length !== 0 && this.state.showChatNotification ?
                            <span className="me-3 px-2 text-success mb-0"><span
                                className="pulse"><FaCircle/></span>
                                {this.state.showChat ? " wiadomość " : null}
                                {this.state.titleNotification ? <>({this.state.titleNotification})</> : null}
                            </span> : null}
                        <span onClick={this.handleShow} className="action-icon cursor-pointer">
                            {this.state.showChat ? <FaChevronCircleDown/> : <FaChevronCircleUp/>}
                        </span>
                    </div>


                    <div className={this.state.showChat ? "" : "d-none"}>
                        <div className="chat-body bg-white">
                            <div ref={this.chatContainer} className="chat-log overflow-auto p-4 position-relative">
                                {this.state.messagesFromDataBase.length !== 0 ?
                                    <>
                                        {this.displayMessages()}
                                        <div className="mt-4" ref={(el) => {
                                            this.messagesEnd = el;
                                        }}>
                                        </div>
                                    </>
                                    : "brak wiadomości"}
                            </div>

                        </div>
                        <div className="p-3 bg-light chat-form border">
                            <Form>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        placeholder="Wiadomość..."
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        value={this.state.content}
                                        onChange={(event) => {
                                            this.setState({content: event.target.value});
                                        }}
                                        onFocus={() => {
                                            this.setState({
                                                showChatNotification: false
                                            })
                                        }}
                                    />
                                    <Button type="submit" variant="light"
                                            className="rounded-pill d-flex align-items-center justify-content-center ms-2"
                                            id="button-addon2"
                                            onClick={e => {
                                                this.sendMessage(e);
                                            }}
                                    >
                                        <span className="h5 m-0 text-info"><FaPaperPlane/></span>
                                    </Button>
                                </InputGroup>
                            </Form>
                        </div>
                    </div>


                </div>
                <SockJsClient url='https://markowe-szczawiny-api.pl:8443/websocket-chat/'
                              topics={['/topic/user']}
                              onConnect={() => {
                                  console.log("connected");
                              }}
                              onDisconnect={() => {
                                  console.log("Disconnected");
                              }}
                              onMessage={(msg) => {
                                  this.onMessageReceived(msg)
                              }}
                              ref={(client) => {
                                  this.clientRef = client
                              }}
                />
            </div>
        )
    }
}

export default ChatComponent;
