import React from 'react';
import {useQuery} from "@tanstack/react-query";
import ReservationService from "../../../../service/ReservationService";
import {Col} from "react-bootstrap";

interface Props {
    month: string
    year: string
}

const ReservationsByMonth = ({month, year}: Props) => {

    const {
        // status,
        isLoading,
        isFetching,
        data,
        // isError,
        error
    } = useQuery(['getNumberOfReservationsWithMinimumNights', month, year], async () => {
            return await ReservationService.getNumberOfReservationsInExactMonth({
                month,
                year
            })
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    return (
        <Col>
            <div className="overview-body">
                <p>{data}</p>
                <p>rezerwacji<br/>w tym miesiącu</p>
            </div>
        </Col>
    );
};

export default ReservationsByMonth;