import React, {useState} from 'react';
import RoomService from "../../../../service/RoomService";
import useToastContext from "../../../nativeComponents/useToastContext";
import {Spinner} from "react-bootstrap";
import {BsFillTrashFill} from "react-icons/all";

const DeleteBlockade = ({setReFetch, reFetch, blockadeId}) => {

    const [deleteLoading, setDeleteLoading] = useState(false);

    const addToast = useToastContext();

    const deleteBlockade = (e, id) => {
        e.preventDefault();
        setDeleteLoading(true);
        RoomService.deleteRoomBlockade(id)
            .then((r) => {
                addToast({text: "Pomyślnie usunięto blokadę!", variant: true});
                setDeleteLoading(false);
                setReFetch(!reFetch);
            }).catch((error => {
                addToast({text: "Nie udało się usunąć blokady!", variant: false});
                setDeleteLoading(false);
                console.log(error);
            }
        ))
    }

    return (
        <button disabled={deleteLoading} onClick={(e) => deleteBlockade(e, blockadeId)}
                className="btn btn-secondary btn-sm d-flex align-items-center gap-1">
            {
                deleteLoading ?
                    <Spinner animation="border" role="status" size="sm"></Spinner> :
                    <BsFillTrashFill className="text-white h-4"/>
            }
            Usuń
        </button>
    );
};

export default DeleteBlockade;