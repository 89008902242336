import React from 'react';
import {Carousel} from "react-bootstrap";
import "./welcomewindow.scss"
import SearchBar from "./SearchBar";
import {Trans} from "react-i18next";


class WelcomeWindow extends React.Component {


    render() {
        return (
            <header className="welcome-window position-relative">
                <Carousel className="carousel-welcome-window position-absolute" fade controls={false}
                          indicators={false}>

                    <Carousel.Item>
                        <img
                            height="100%"
                            width="100%"
                            className="d-block w-100"
                            src="images/slider/img2.jpg"
                            alt="First slide - header background"
                        />
                    </Carousel.Item>
                </Carousel>
                <section className="position-absolute d-flex flex-column justify-content-center align-items-center w-100">
                    <form className="col-12 col-md-10 col-lg-9 col-xl-7 my-4">
                        <SearchBar/>
                    </form>
                    <article className="text-white text-center d-flex flex-column align-items-center py-5">
                        <h1 className="head-title order-last"><Trans i18nKey="WelcomeWindow_h1">Schronisko PTTK Markowe Szczawiny</Trans></h1>
                        <h2 className="head-subtitle fw-light mt-5 order-first"><Trans i18nKey="WelcomeWindow_h2">Twoje miejsce w Beskidach</Trans></h2>
                    </article>
                </section>


            </header>
        )
    }


}

export default (WelcomeWindow)
