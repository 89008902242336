import React from 'react';
import EditAmenityModal from "./EditAmenityModal";
import {AmenitiesDTO} from "../../../../interfaces/settings.interface";
import {Col, Row} from "react-bootstrap";

const Amenity = ({name, description, uuid}: AmenitiesDTO) => {

    return (
        <>
            <Row className="">
                <Col xs={12} md={2}>
                    <div className="fw-bold">{name}</div>
                </Col>
                <Col xs={9} md={8}>
                    <p>{description}</p>
                </Col>
                <Col xs={3} md={2} className="text-end">
                    <EditAmenityModal name={name} description={description} uuid={uuid}>
                        Edytuj
                    </EditAmenityModal>
                </Col>
            </Row>
        </>
    );
};

export default Amenity;
