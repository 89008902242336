import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {BiHome, BiUserCircle} from "react-icons/all";
import AuthService from "../../service/AuthService";
import {Trans} from "react-i18next";

const MobileBottomMenu = (props) => {

    const [currentUser, setCurrentUser] = useState(undefined);


    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
    },[])

    return (
        <div style={{bottom: "0", right: "0", left: "0", zIndex: "1021"}}
             className="d-md-none d-flex justify-content-around align-items-center position-fixed py-1 bg-white border-top">

            <div className="d-block">
                <NavLink exact activeClassName="text-primary" className="text-black" to="/">
                    <div className="h3 m-0 p-0 text-center"><BiHome/></div>

                    <div style={{marginTop: "-5px"}}><small>Home</small></div>
                </NavLink>
            </div>

            {props.component !== null ? props.component : null}

            <div className="d-block">
                <NavLink exact activeClassName="text-primary" className={"text-black"} to={currentUser ? "/admin" : "/login"}>
                    <div className="h3 m-0 p-0 text-center"><BiUserCircle/></div>

                    <div style={{marginTop: "-5px"}}><small>{currentUser ? currentUser.firstName : <Trans i18nKey='Navbar_profile'>Zaloguj</Trans>}</small></div>
                </NavLink>
            </div>
        </div>
    );
};

export default MobileBottomMenu;
