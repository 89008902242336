import React from 'react';
import NavbarComponent from "./compoments/navbar/NavbarComponent";
import './App.scss';
import RoomListComponent from "./compoments/roomList/RoomListComponent";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import LoginComponent from "./compoments/loginComponent/LoginComponent";
import FooterComponent from "./compoments/footer/FooterComponent";
import BackgroundVideo from "./compoments/welcomeWindowComponent/WelcomeWindow";
import ConfirmPaymentComponent from "./compoments/confirmPaymentComponent/ConfirmPaymentComponent";
import BodyAdmin from "./compoments/admin-panel/BodyAdmin";
import ResNav from "./compoments/reservationPage/ResNav";
import NotFoundPage from "./compoments/nativeComponents/NotFoundPage";
import ProtectedRoute from "./compoments/nativeComponents/ProtectedRoute";
import ScrollToTop from "./compoments/nativeComponents/ScrollToTop";
import SearchBar from "./compoments/welcomeWindowComponent/SearchBar";
import MobileBottomMenu from "./compoments/navbar/MobileBottomMenu";
import Facebook from "./compoments/nativeComponents/Facebook";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import CookiesModal from "./compoments/cookiesPrivacyModal/CookiesModal";

import { useTranslation } from "react-i18next";
function App() {

    // important
    const { t, i18n } = useTranslation();

    return (
        <Router>
            <ScrollToTop/>
            <div className="App">
                <CookiesModal/>
                <Switch>
                    <Route exact path="/">
                        <NavbarComponent/>
                        <BackgroundVideo/>
                        <FooterComponent/>
                        <MobileBottomMenu/>
                        <Facebook/>
                    </Route>

                    <Route path="/rooms" exact>
                        <NavbarComponent/>
                        <SearchBar/>
                        <ErrorBoundary>
                        <Route path="/rooms" exact component={RoomListComponent}/>
                        </ErrorBoundary>
                        <FooterComponent/>
                    </Route>

                    <Route path="/login" exact>
                        <NavbarComponent/>
                        <LoginComponent/>
                        <MobileBottomMenu/>
                    </Route>
                    <Route path="/confirm-payment/:id" exact component={ConfirmPaymentComponent}/>



                    <Route path="/make-reservation/">
                        <div style={{zIndex: "1"}} className="shadow-xl position-relative">
                            <NavbarComponent/>
                        </div>
                        <ResNav/>
                    </Route>


                    <ProtectedRoute path="/admin" component={BodyAdmin}/>


                    <Route path="*" component={NotFoundPage}/>

                </Switch>
            </div>
        </Router>
    );
}

export default App;
