import React from 'react';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import NavbarComponent from "../navbar/NavbarComponent";
import FooterComponent from "../footer/FooterComponent";

const NotFoundPage = (props) => {
    return (
        <>
            <NavbarComponent/>
            <div className="bg-white text-center py-5">
                <Container className="my-5">
                    <h1 style={{fontSize: "90px"}} className="fw-bold color-brand-txt">404</h1>
                    <p>Przepraszamy, strona o podanym adresie nie istnieje. <br/>
                        Jeśli chcesz, możesz wrócić do strony głównej.</p>
                    <Button onClick={() => props.history.push("/")} variant="brand">
                        Wróć do strony głównej
                    </Button>
                </Container>
            </div>
            <FooterComponent/>
        </>
    );
};

export default NotFoundPage;