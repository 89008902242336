import React from 'react';
import EditRuleModal from "./EditRuleModal";
import {RulesDTO} from "../../../../interfaces/settings.interface";
import {Col, Row} from "react-bootstrap";
import {ICONS} from "../../../../utils/IconsMapper";

const Rule = ({icon, name, description, uuid}: RulesDTO) => {
    const IconComponent = ICONS[icon as keyof typeof ICONS] || ICONS["BookmarkIcon"]; // Użyj BookmarkIcon jako domyślnej

    return (
        <>
            <Row>
                <Col xs={1} className="text-center">
                    <IconComponent size={18}/>
                </Col>
                <Col xs={8} md={9}>
                    <p>{description}</p>
                </Col>
                <Col xs={3} md={2} className="text-end">
                    <EditRuleModal name={name} icon={icon} description={description} uuid={uuid}>
                        Edytuj
                    </EditRuleModal>
                </Col>
            </Row>
        </>
    );
};

export default Rule;
