import React, {Component} from 'react';
import "./admin-panel.scss"
import {Nav} from "react-bootstrap";
import ProfilePanel from "../navbar/ProfilePanel";


class NavbarAdmin extends Component {

    render() {
        return (
            <Nav className="navbar-admin container-fluid d-flex justify-content-between align-items-center w-100 pe-5">
                <Nav.Item className="d-flex nav-admin-items-left sm-2">
                    {/*<Form inline>*/}
                    {/*    <FormControl type="text" placeholder="Search" className="ms-3"/>*/}
                    {/*</Form>*/}
                </Nav.Item>

                <ProfilePanel/>

            </Nav>
        )
    }
}

export default NavbarAdmin;