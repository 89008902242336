import React, {useEffect, useState} from 'react';
import AdminPanelService from "../../service/RoomService";
import BootstrapTable from "react-bootstrap-table-next";
import {Col, Modal, Row, Form, Button} from "react-bootstrap";
import {AiOutlineDollar} from "react-icons/all";
import RoomService from "../../service/RoomService";
import BlockedDatesModal from "./rooms-page/blocked-dates/BlockedDatesModal";

const RoomList = () => {

    const [rooms, setRooms] = useState([]);
    const [priceModal, setPriceModal] = useState(false);
    const [price, setPrice] = useState(null)
    const [formValidated, setFormValidated] = useState(false);
    const [roomId, setRoomId] = useState(null);

    useEffect(() => {
        AdminPanelService.getAllRooms().then(response => {
            setRooms(response.data.sort((a, b) => (a.roomNumber > b.roomNumber) ? 1 : -1));
        }).catch(e => console.log(e))
    }, []);

    const columns = [
        {
            dataField: "roomNumber",
            text: "Numer",
            sort: true
        },
        {
            dataField: "description",
            text: "Nazwa pokoju",
            sort: true
        },
        {
            dataField: "forHowManyPeople",
            text: "Ilość miejsc",
            sort: true
        },
        {
            dataField: "pricePerPeron",
            text: "Cena za osobę (zł)",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    <span>{row.pricePerPeron}</span>
                    <Button onClick={() => openModal(row.id)} className="btn-sm ms-2 btn-primary"> <AiOutlineDollar/> Zmień cene
                        pokoju</Button>
                </>
            )

        },
        {
            dataField: "blockedDates",
            text: "",
            sort: false,
            formatter: (cellContent, row) => (
                <>
                    <BlockedDatesModal room={row}/>
                </>
            )
        }
    ];
    const openModal = (id) => {
        setRoomId(id)
        setPriceModal(true)
    }

    const closeModal = () => {
        setRoomId(null)
        setPrice(null)
        setPriceModal(false)
    }

    const validateInput = (e) => {
        const value = e.target.value;
        const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

        let numberSplit = value.split('');

        numberSplit = numberSplit.filter(x => {
            for (let i = 0; i < digits.length; i++) {
                if (x == digits[i]) {
                    return true
                }
            }
            return false;
        })

        if (numberSplit.length > 0 && numberSplit.length < 5 && !(value.includes(" "))) {
            setFormValidated(true)
            setPrice(value)
        } else {
            setFormValidated(false)
        }
    }


    const setNewPrice = () => {

        if (!formValidated) {
            alert("Źle ustawiona cena")
        } else {
            RoomService.setRoomPrice(roomId, price).then(res => res.status)
            closeModal();
        }

    }


    return (
        <div className="container-fluid">
            <Row>
                <Col>
                    <div className="admin-panel-title mb-4">Pokoje</div>
                </Col>
            </Row>
            <div className="card-admin p-4">
                <div className="row mt-3">
                    <BootstrapTable bootstrap4 keyField='id' data={rooms} columns={columns} noDataIndication="Brak pokoi"/>
                </div>
            </div>

            <Modal show={priceModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Zmiana ceny pokoju</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Czy chcesz zmienić cenę pokoju? Cena musi wynosić od 1zł do 1000zł. <p>Zmiana możliwa jedynie w
                    godzinach nocnych tj. od 3 do 4 nad ranem</p>

                    <Form.Control onBlur={validateInput} className="mt-2" type="text" disabled={new Date().getHours() !== 3}
                                  placeholder="Wprowadź cene" maxLength="4"/>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Anuluj
                    </Button>
                    <Button variant="primary" onClick={setNewPrice} disabled={new Date().getHours() !== 21}>
                        Ustaw nowa cene
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default RoomList;