import React from 'react';
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router-dom";

const ReservationPaymentPage = (props) => {

    const history = useHistory();

    return (
        <div className="mb-4">
            <div className="reservation mt-2 mb-4">
                <h1 className="fw-bold">Płatność</h1>
                <h2 className="text-primary mt-5">
                    <strong>
                        Error code {props.location.state.code}
                    </strong>
                </h2>
                <p>{props.location.state.error}</p>
            </div>

            <Button size="xxl"
                onClick={() => {
                history.push("/")
            }}>
                Powrót do strony głównej
            </Button>
        </div>
    );
};

export default ReservationPaymentPage;