import React, {useState} from 'react';
import {FaEye} from "react-icons/all";
import {Col, Offcanvas, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Trans, useTranslation} from "react-i18next";

const RoomDetailsOffcanvas = ({room, addRoom, addPlaces, isInCart}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { t } = useTranslation();

    return (
        <>
            <div
                onClick={handleShow}
                className="col-xl-4 col-12"
            >
                <div className="card-image h-100 cursor-pointer">
                    <div className="card-eye"><FaEye/></div>
                    <img style={{borderRadius: "20px"}} alt={room.description}
                         className="card-img-top h-100"
                         src={room.imgUrl}/>
                </div>
            </div>


            <Offcanvas placement="bottom" show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><strong><Trans i18nKey='RoomDetails_1'>Szczegóły</Trans></strong></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col xs={12} lg={6}>
                            <img style={{borderRadius: "20px"}} alt={room.description}
                                 className="img-fluid w-100"
                                 src={room.imgUrl}/>
                        </Col>
                        <Col className="d-flex flex-column justify-content-between m-lg-3 m-xl-4 my-4">
                            <div className="">
                                <h2><strong>{t(room.description)}</strong> · nr {room.roomNumber}</h2>
                            </div>

                            <div className="text-center my-4">

                                <h2><Trans i18nKey='RoomDetails_2'>dostępność miejsc</Trans></h2>
                                <h2><strong>{room.placesLeft} / {room.forHowManyPeople}</strong></h2>

                            </div>
                            <Row>
                                <div className="d-flex justify-content-around">
                                    <h2>
                                        <strong>{room.pricePerPeron} zł</strong> <small>/ <Trans i18nKey='RoomDetails_3'>os.</Trans></small>
                                    </h2>
                                    <h2>
                                        <strong>{room.pricePerPeron * room.forHowManyPeople} zł</strong> <small>/ <Trans i18nKey='RoomDetails_4'>łącznie</Trans></small>
                                    </h2>
                                </div>
                                <div
                                    className={isInCart ? "d-none" : "mb-2 col"}>
                                    <Button variant="grad"
                                            className="col-12 text-nowrap"
                                            size="lg"
                                            disabled={(room.forHowManyPeople === 3) || (room.forHowManyPeople === 2) || isInCart}
                                            onClick={addPlaces}
                                    >
                                        <Trans i18nKey='RoomDetails_6'>Wynajmij miejsca</Trans>
                                    </Button>
                                </div>
                                <div className="col">
                                    <Button variant="grad"
                                            className="col-12 mb-2 text-nowrap"
                                            size="lg"
                                            disabled={(room.forHowManyPeople !== room.placesLeft) || isInCart}
                                            onClick={addRoom}
                                    >
                                        {isInCart ? <Trans i18nKey='RoomDetails_8'>W koszyku</Trans> : <Trans i18nKey='RoomDetails_5'>Wynajmij pokój</Trans>}

                                    </Button>
                                </div>
                                <div onClick={handleClose} className="text-decoration-underline cursor-pointer mt-3 ">
                                    <strong><Trans i18nKey='RoomDetails_7'>Zobacz koszyk</Trans></strong>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default RoomDetailsOffcanvas;
