import React, {useEffect, useState} from 'react';
import {BsCheck, IoIosArrowForward} from "react-icons/all";
import "./reservation-progress.css";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ReservationProgressBar = () => {

    const { t, i18n } = useTranslation();

    const history = useHistory();
    const [step, setStep] = useState(+history.location.pathname.split("/").pop())

    useEffect(() => {
        return history.listen((location) => {
            setStep(+location.pathname.split("/").pop())
        })
    }, [history])

    const progressArr = [
        {
            step: 1,
            name: t('ProgressBar_1')
        },
        {
            step: 2,
            name: t('ProgressBar_2')
        },
        {
            step: 3,
            name: t('ProgressBar_3')
        },
        {
            step: 4,
            name: t('ProgressBar_4')
        }
    ]

    return (
        <div className="reservation-progress">
            <div className="row">
                <div className="d-flex justify-content-around justify-content-lg-start">
                    {progressArr.map((arr, index) => {
                        return (
                            <div key={index} className="d-flex align-items-center justify-content-center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div
                                        className={step + 1 === arr.step || step >= arr.step ?
                                            "circle-checkout text-white bg-primary" :
                                            "circle-checkout text-black bg-light"}
                                    >
                                        <span>{step >= arr.step ? <BsCheck/> : arr.step}</span>
                                    </div>
                                    <p className="text-black d-none d-sm-flex mb-0 ms-2">
                                        {step + 1 === arr.step ? <strong>{arr.name}</strong> : arr.name}
                                    </p>
                                </div>
                                {index === progressArr.length - 1 ? null :
                                    <div className="mx-3 mobile-hide"><IoIosArrowForward/></div>}
                            </div>
                        )
                    })}

                </div>
            </div>
            <h4 className="d-block d-sm-none text-center text-black mt-4">
                <small title="poprzedni krok">{progressArr[step - 1].name}</small>
                <span className="mx-3">{progressArr[step].name}</span>
                <small title="następny krok">{progressArr[step + 1] !== undefined ? progressArr[step + 1].name : null}</small>
            </h4>
        </div>
    );
};

export default ReservationProgressBar;
